<template>
  <div
    class="result-item d-flex"
    :id="r.id"
    :class="{ active: active }"
    @mouseover="$emit('itemHovered')"
  >
    <div class="icons">
      <div class="contact-icon">
        <img
          v-if="mainImageError === 0"
          :src="
            (r.contactType === 'c' ? r.product_icons_v2 : r.image_url_v2) ||
            '/no_img.jpg'
          "
          @error="mainImageError = mainImageError + 1"
          @load="
            (e) => {
              $emit('imageLoad', e);
              mainImageError = 0;
            }
          "
        />
        <img
          v-else-if="mainImageError === 1"
          :src="
            (r.contactType === 'c' ? r.product_icons : r.image_url) ||
            '/no_img.jpg'
          "
          @error="mainImageError = mainImageError + 1"
          @load="
            (e) => {
              $emit('imageLoad', e);
              mainImageError = 1;
            }
          "
        />
        <div v-else class="header-like biz-icon">
          {{
            (r.contactType === "c" ? r.desc : r.list_name)
              .replace(/[^0-9a-z]/gi, "")
              .substring(0, 1)
          }}
        </div>
      </div>
      <div class="sub-image">
        <img
          v-if="subImageError === 0"
          :src="
            (r.contactType === 'c' ? r.image_url_v2 : r.product_icons_v2) ||
            '/no_img.jpg'
          "
          @error="subImageError = subImageError + 1"
          @load="subImageError = 0"
        />
        <img
          v-else-if="subImageError === 1"
          :src="
            (r.contactType === 'c' ? r.image_url : r.product_icons) ||
            '/no_img.jpg'
          "
          @error="subImageError = subImageError + 1"
          @load="subImageError = 1"
        />
        <div v-else class="header-like person-icon">
          {{
            (r.contactType === "c" ? r.list_name : r.desc)
              .replace(/[^0-9a-z]/gi, "")
              .substring(0, 1)
          }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-around text-column">
      <div class="name">{{ r.contactType === "c" ? r.desc : r.list_name }}</div>
      <div class="sub-name d-flex">
        <div class="sub-name-text">
          {{ r.contactType === "c" ? r.list_name : r.desc }} - {{ r.city }},
          {{ r.state }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactSearchItem",
  components: {},
  props: {
    r: Object,
    active: Boolean,
  },
  watch: {
    r() {
      this.mainImageError = 0;
      this.subImageError = 0;
    },
  },
  data() {
    return {
      mainImageError: 0,
      subImageError: 0,
      subImageLoading: true,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
.result-item {
  padding: 12px 0;
  width: 100%;
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  overflow-x: hidden;
}

.result-item.active {
  background-color: #00af6c13;
  transition: background-color 0.1s ease-out;
}

.biz-icon {
  background-color: var(--mx-dark);
  color: var(--mx-white);
}

.person-icon {
  background-color: var(--mx-blue);
  color: var(--mx-dark);
}

.icons {
  position: relative;
}

.contact-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin: 0 20px 0 0;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #a6a6a630;
}

.contact-icon div,
.sub-image div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.sub-image div {
  font-size: 15px;
  line-height: 30px;
}

.contact-icon img,
.sub-image img {
  height: 100%;
}

.sub-image {
  position: absolute;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 15px;
  margin: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #a6a6a630;
  right: 0;
  top: 40px;
}

.text-column {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sub-name {
  max-width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  width: calc(100% + 36px);
  overflow-x: hidden;
}

.sub-name-text {
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
