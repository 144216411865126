<template>
  <div
    :id="id"
    :class="[
      'frame',
      'w-100',
      'd-flex align-items-center justify-content-center',
      {
        'skeleton-box': loading || skeleton,
        'bg-light': !img,
      },
    ]"
  >
    <div v-if="!img && !skeleton" class="bg-light no-image p-2 text-center">
      <small class="text-muted">Image Coming Soon</small>
    </div>
    <div v-else-if="sized && img" class="">
      <img
        v-show="!loading"
        :src="key"
        @load="handleImageLoad"
        :style="{
          width: imgWidth && `${imgWidth}px`,
          height: imgHeight && `${imgHeight}px`,
        }"
      />
    </div>
  </div>
</template>

<script>
import shortUUID from "short-uuid";
import config from "@/config/awsconfig";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";

//let test = "hello";

export default {
  name: "ResultImage",
  components: {},
  data() {
    return {
      id: shortUUID.generate(),
      frame: undefined,
      loading: true,
      sized: false,
      frameWidth: undefined,
      frameHeight: undefined,
      imgWidth: undefined,
      imgHeight: undefined,
      key: undefined,
    };
  },
  props: {
    ratio: {
      type: Number,
      default: () => 1.7778,
    },
    img: Object,
    skeleton: Boolean,
  },
  async mounted() {
    this.size();

    window.addEventListener("resize", this.size);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.size);
  },
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    size() {
      this.frame = document.getElementById(this.id);

      this.frameWidth = this.frame?.getBoundingClientRect()?.width || 0;

      this.frameHeight = Math.floor(this.frameWidth / this.ratio);

      this.frame.style.height = `${this.frameHeight}px`;

      this.sized = true;

      this.getKey();

      if (!this.img) this.loading = false;
    },
    handleImageLoad(i) {
      const w = i.target.naturalWidth;
      const h = i.target.naturalHeight;

      const imgRatio = w / h;

      if (imgRatio >= this.ratio) {
        this.imgHeight = this.frameHeight;
      } else {
        this.imgWidth = this.frameWidth;
      }

      this.loading = false;
    },
    getKey() {
      let key = [config.newCloudFrontPrefix, this.img?.key].join("/");

      const preferredImgWidth = this.img?.width?.findLast(
        (w) => w >= this.frameWidth
      );

      if (preferredImgWidth) {
        const keyArray = key.split("/");
        keyArray.pop();

        key = [...keyArray, `${preferredImgWidth}.jpg`].join("/");
      }

      this.key = key;
    },

    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.frame {
  overflow: hidden;
}

.no-image {
  font-size: 0.7em;
}
</style>
