<template>
  <div id="invite">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex text-success justify-content-between">
        <div class="d-flex">
          <div class="header-like">Invite and Earn</div>
        </div>
        <div class="close-btn text-light">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>

      <div v-if="!mode" class="fw-light text mt-0 pt-0">
        <div class="mt-4">
          <div>Grow Moxey, Get Rewarded.</div>
          <div>
            <small class="fw-light">
              Invite someone to join Moxey and you'll both get a FlashCash
              reward.
            </small>
          </div>
        </div>

        <div class="divider my-4" />

        <div
          v-if="
            !appConfig.globalInviteUsersLeft || appConfig.globalInvitesLeft < 0
          "
        >
          <em>
            The Moxey invitations program has been so overwhelmingly successful
            that we're taking a quick break to catch up. We will reopen invites
            soon. Be sure to check back!
          </em>
        </div>
        <div v-else-if="invitesLeft > 0">
          <div class="text-center">
            <small>You have {{ invitesLeft }} invites left.</small>
          </div>
          <div class="text-center">
            <mx-button
              btnClass="btn btn-outline-light btn-outline-light-custom"
              @click="mode = 'text'"
              :height="60"
              :width="120"
              overrideWidth="300px"
              :padX="15"
              :yPad="10"
              ><i class="fas fa-message"></i> Invite by Text
            </mx-button>
          </div>
          <div class="text-center">
            <mx-button
              btnClass="btn btn-outline-light btn-outline-light-custom"
              @click="mode = 'mail'"
              :height="60"
              :padX="15"
              overrideWidth="300px"
              :yPad="10"
              ><i class="fas fa-envelope"></i> Invite by Email
            </mx-button>
          </div>
        </div>
        <small v-else-if="pendingInvites.length > 0">
          You have no invites left. You will earn additional invites once your
          current pending invites join Moxey.
        </small>
        <small v-else>
          You have no invites. You can request invites by contacting your
          exchange office.
        </small>
      </div>
      <div v-if="mode" class="text mt-0 pt-0">
        <div class="text-center fs-4 fw-bold my-4">
          Invite by {{ mode === "mail" ? "Email" : "Text" }}
        </div>
        <div class="my-4">
          <small class="fw-lighter" v-if="mode === 'text'">
            Enter the name and phone number of the person you'd like to invite
            to join Moxey and we'll text them a link so they can get started.
          </small>
          <small class="fw-lighter" v-if="mode === 'mail'">
            Enter the name and email address of the person you'd like to invite
            to join Moxey and we'll mail them a link so they can get started.
          </small>
        </div>
        <input
          id="first"
          name="first"
          class="form-control"
          :class="{ 'is-invalid': invalidFields.first }"
          v-model="first"
          placeholder="First Name"
          :disabled="sending"
          @focus="delete invalidFields.first"
        />
        <input
          id="last"
          name="last"
          class="form-control"
          :class="{ 'is-invalid': invalidFields.last }"
          v-model="last"
          placeholder="Last Name"
          :disabled="sending"
          @focus="delete invalidFields.last"
        />
        <input
          v-if="mode === 'mail'"
          id="email"
          name="email"
          class="form-control"
          :class="{ 'is-invalid': invalidFields.email }"
          v-model="email"
          placeholder="Email Address"
          :disabled="sending"
          autocapitalize="none"
          autocomplete="off"
          @focus="delete invalidFields.last"
        />
        <input
          v-if="mode === 'text'"
          id="phone"
          name="phone"
          type="tel"
          class="form-control"
          :class="{ 'is-invalid': invalidFields.phone }"
          v-model="phone"
          placeholder="Mobile Phone"
          :disabled="sending"
          autocomplete="off"
          @focus="delete invalidFields.phone"
          @blur="() => validatePhone()"
        />

        <div v-if="Object.keys(invalidFields).length > 0" class="mt-3">
          <small>
            <ul
              v-for="(k, i) in Object.keys(invalidFields).map(
                (k) => invalidFields[k]
              )"
              :key="i"
              class="text-danger"
            >
              <li>
                <em>{{ k }}</em>
              </li>
            </ul>
          </small>
        </div>

        <div class="mb-5 text p-0">
          <mx-button
            v-if="!sending"
            btnClass="btn btn-outline-success"
            @click="validateForm"
            :height="60"
            :padX="15"
            :fullWidth="true"
            :yPad="10"
            :disabled="sending"
            ><span v-if="mode === 'text'"
              ><i class="fas fa-message"></i> Send Invite Text</span
            ><span v-if="mode === 'mail'"
              ><i class="fas fa-envelope"></i> Send Invite Email</span
            >
          </mx-button>
          <mx-button
            v-else
            btnClass="btn btn-outline-success"
            @click="validateForm"
            :height="60"
            :padX="15"
            :fullWidth="true"
            :yPad="10"
            :disabled="true"
            >Sending Invite ...
          </mx-button>
          <mx-button
            btnClass="btn btn-outline-secondary"
            @click="reset"
            :height="60"
            :padX="15"
            :fullWidth="true"
            :yPad="10"
            >Cancel
          </mx-button>
        </div>
      </div>

      <div class="text text-center">
        <div>
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'Account')"
          >
            Back to Account Summary
          </span>
        </div>

        <div
          v-if="
            appConfig.globalInviteUsersLeft &&
            appConfig.globalInviteUsersLeft > 0 &&
            pendingInvites.length > 0 &&
            !mode
          "
          class="text-start"
        >
          <div class="divider my-4 mx-0 px-0" />
          <div>Your Invitations</div>
          <div>
            <small class="fw-light">
              These are the people you've invited to join Moxey. Once they
              complete their signup, you'll receive the FlashCash award listed.
            </small>
          </div>
          <div class="mt-3 text-success">
            <small> Be sure to remind them to complete their signup! </small>
          </div>
          <div
            v-for="(pi, i) in pendingInvites"
            :key="i"
            class="mini p-3 boder-radius-25 border-1 border-light"
          >
            <div>
              <small v-if="!pi.completeDate">
                You invited {{ pi.first }} {{ pi.last }} on {{ pi.createDate }}
              </small>
              <small v-else>
                {{ pi.first }} {{ pi.last }} on {{ pi.completeDate }} using your
                invite.
              </small>
            </div>
            <div class="mt-0">
              <small class="fw-light" v-if="!pi.completeDate">
                When they join, you get
                <moxey-value :value="pi.referrerFlashCash" /> and they get
                <moxey-value :value="pi.flashCash" /> FlashCash.
              </small>

              <small class="fw-light" v-else>
                You got
                <moxey-value :value="pi.referrerFlashCash" /> and they got
                <moxey-value :value="pi.flashCash" /> FlashCash!
              </small>
            </div>
            <div class="mt-1 d-flex justify-content-between">
              <div class="m-0">Status:</div>
              <div class="m-0">
                <span v-if="pi.completeDate" class="text-success">Joined!</span>
                <span v-else-if="pi.startDate" class="text-warning"
                  >Pending Completion</span
                >
                <span v-else class="text-danger">Not Claimed Yet</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";
import { api } from "../../../utils/utils";
import MxButton from "../../Shared/MxButton.vue";
import MoxeyValue from "../../Shared/moxey/MoxeyValue.vue";
export default {
  name: "Invite",
  components: {
    MxButton,
    MoxeyValue,
  },

  data() {
    return {
      mode: undefined,
      first: undefined,
      last: undefined,
      phone: undefined,
      email: undefined,
      sending: false,
      invalidFields: {},
    };
  },
  props: {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      appConfig: (state) => state.app.all.appConfig,
      invitesLeft() {
        return this.user?.invitesLeft;
      },
      pendingInvites() {
        const invites = this.user?.pendingInvites || {};

        const pending = Object.keys(invites)
          .reduce((c, p) => {
            const pi = {
              ...invites[p],
            };

            pi.createDate =
              pi.createdAt && dayjs(pi.createdAt).format("M/D/YYYY");
            pi.startDate =
              pi.startedAt && dayjs(pi.startedAt).format("M/D/YYYY");
            pi.completeDate =
              pi.completed && dayjs(pi.completed).format("M/D/YYYY");

            c.push(pi);
            return c;
          }, [])
          .sort((a, b) => b.createdAt - a.createdAt);

        console.log(pending, this.appConfig);

        return pending || [];
      },
      completedInvites() {
        return this.user?.completedInvites || [];
      },
    }),
  },
  mounted() {
    console.log("pending", this.pendingInvites);
  },
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    validatePhone() {
      if (this.phone) {
        this.phone = this.phone.replace(/[^0-9]/g, "");

        console.log(this.phone);

        this.phone = `(${this.phone.substring(0, 3)}) ${this.phone.substring(
          3,
          6
        )}-${this.phone.substring(6, 10)}`;

        if (
          !/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(this.phone) ||
          this.phone.length === 0
        )
          this.invalidFields.phone =
            "Invalid phone number. Mobile phone number must be of the format (555) 555-5555.";
      } else if (
        this.mode === "text" &&
        (!this.phone || this.phone.length === 0)
      ) {
        this.invalidFields.phone = "You must enter a phone number.";
      }
    },

    validateEmail() {
      this.invalidFields = {};

      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        ) ||
        this.email.length === 0
      )
        this.invalidFields.email = "Invalid email address.";
    },
    validateForm() {
      if (this.mode === "text") this.validatePhone();
      if (this.mode === "mail") this.validateEmail();

      if (!this.first || this.first === "")
        this.invalidFields.first = "You must enter a first name.";
      if (!this.last || this.last === "")
        this.invalidFields.last = "You must enter a last name.";

      if (Object.keys(this.invalidFields).length === 0) {
        this.sendInvite();
      }
    },
    reset() {
      this.mode = undefined;
      this.first = undefined;
      this.last = undefined;
      this.phone = undefined;
      this.email = undefined;
      this.invalidFields = {};
      this.sending = false;
    },

    async sendInvite() {
      try {
        this.sending = true;
        const res = await api("post", "invites", `/create`, {
          body: {
            first: this.first,
            last: this.last,
            phone: this.phone && `+1${this.phone?.replace(/[^0-9]/g, "")}`,
            email: this.email,
          },
        });
        console.log(res);

        this.updateAppStore({
          user: {
            ...this.user,
            invitesLeft: res.pending.invitesLeft,
            pendingInvites: res.pending.pendingInvites,
          },
        });
        this.reset();
      } catch (e) {
        console.log(e);
        this.invalidFields.sendError = e.error;
        console.log(this.invalidFields);
      } finally {
        this.sending = false;
      }
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
#invite {
  width: 100%;
  min-height: 100%;
  color: var(--mx-white);
  font-size: 18px;
  line-height: 24px;
  background-color: var(--mx-black);
  background-image: url("../../../assets/xo.svg");
  background-position: 7px 5px;
  background-size: 40px;
}

.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.text div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.divider {
  background-color: var(--mx-white);
  height: 1px;
  margin: 10px 15px;
}

input {
  background-color: transparent;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
  width: 100%;
}

.forgot {
  margin: 15px auto 15px;
  border-bottom: 1px dashed var(--mx-white);
  cursor: pointer;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  color: var(--mx-white);
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: transparent;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.mini {
  font-size: 0.9rem;
  border: 1px solid;
  border-radius: 15px;
}
</style>
