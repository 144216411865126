import { sleep } from "@/api/test";

// initial state
const state = () => ({
  all: {},
});

// getters
const getters = {};

// actions
const actions = {
  async login({ commit }, creds) {
    //Fake login actions
    try {
      const needsVerify = creds.email === "verify@email.com";

      const user = await sleep(
        {
          ...creds,
          needsVerify: needsVerify,
          loggedIn: true,
        },
        500
      );
      commit("setUser", user);
    } catch (e) {
      //Build something to handle errors
      commit("errors/setError", e, { root: true });
    }
  },
  async forgot({ commit }, creds) {
    //Fake login actions
    try {
      const needsVerify = creds.email === "verify@email.com";

      const user = await sleep(
        {
          ...creds,
          needsVerify: needsVerify,
          loggedIn: true,
        },
        500
      );
      commit("setUser", user);
    } catch (e) {
      //Build something to handle errors
      commit("errors/setError", e, { root: true });
    }
  },
  async confirm({ commit }, creds) {
    //Fake login actions
    try {
      const needsVerify = creds.email === "verify@email.com";

      const user = await sleep(
        {
          ...creds,
          needsVerify: needsVerify,
          loggedIn: true,
        },
        500
      );
      commit("setUser", user);
    } catch (e) {
      //Build something to handle errors
      commit("errors/setError", e, { root: true });
    }
  },
  async logout({ commit }) {
    const user = await sleep({}, 500);
    commit("setUser", user);
  },
};

// mutations
const mutations = {
  setUser(state, user) {
    state.all = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
