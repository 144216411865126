<template>
  <div :class="[' d-flex', { 'w-100': desktop, 'result-row': !desktop }]">
    <div class="d-flex flex-fill">
      <div
        v-for="(feature, f) in externalFeatured || fixedFeatured"
        :key="f"
        :class="[
          { 'me-2': f < features.length - 1, 'ms-2': f > 0, 'w-100': desktop },
          'tile',
        ]"
      >
        <div class="d-flex flex-column align-items-center">
          <div
            :class="[
              {
                'rounded-circle align-items-center justify-content-center':
                  !square,

                'rounded-1 align-items-center justify-content-start': square,
              },
              'bg-success fs-1 d-flex cursor-pointer feature-box',
            ]"
            :style="
              !desktop && {
                width: `${size || 60}px`,
                height: `${size || 60}px`,
              }
            "
            @click="
              () => {
                selectedFeature = feature;
                if (!location) {
                  $emit('needgeo', getFeatured);
                } else {
                  getFeatured(location);
                }
              }
            "
          >
            <div class="text-white" v-if="!innerText">
              <i :class="`fa-fw ${feature.icon}`" />
            </div>
            <div v-else class="text-white w-100 p-2">
              <svg viewBox="0 0 100 100">
                <switch>
                  <foreignObject x="0" y="0" width="100" height="100">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      class="d-flex align-items-end h-100"
                    >
                      <div class="fw-semibold mb-3 inner-text text-proper-case">
                        {{ feature.title }}
                      </div>
                    </div>
                  </foreignObject>

                  <text x="20" y="20">
                    {{ feature.title }}
                  </text>
                </switch>
              </svg>
            </div>
          </div>
          <div v-if="!innerText" class="text-center feature-title text-dark">
            {{ feature.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="cursor-pointer mt-3 text-success fw-bold text-center"
    @click="
      () => {
        selectedFeature = 'all';
        if (!location) {
          $emit('needgeo', getFeatured);
        } else {
          getFeatured(location);
        }
      }
    "
  >
    View All Categories
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import { searchStorefronts } from "./storefrontGraphql";

//let test = "hello";

export default {
  name: "FetureRow",
  components: {},
  data() {
    return {
      row: undefined,
      fixedFeatured: [
        {
          title: "Restaurants",
          sectors: ["Restaurants"],
          type: "sectors",
          icon: "fad fa-fork-knife",
        },
        {
          title: "Auto Repair",
          categories: ["Auto Mechanic"],
          type: "categories",
          icon: "fad fa-car-wrench",
        },
        {
          title: "IT",
          sectors: ["Information Technology"],
          type: "sectors",
          icon: "fad fa-computer",
        },
        {
          title: "Pets",
          sectors: ["Pet Products & Services"],
          type: "sectors",
          icon: "fad fa-dog",
        },
        {
          title: "Travel",
          sectors: ["Travel & Accommodations"],
          type: "sectors",
          icon: "fad fa-plane-engines",
        },
        {
          title: "Stores",
          sectors: ["Retail"],
          type: "sectors",
          icon: "fad fa-bag-shopping",
        },
        {
          title: "Dental",
          categories: ["Dental"],
          icon: "fad fa-tooth",
        },
      ]
        .filter((f) => f)
        .map((f) => {
          return {
            predefinedSearchName: "searchStorefrontsByTags",
            index: "storefronts",
            fn: searchStorefronts,
            ...f,
          };
        }),
      features: [],
      selectedFeature: undefined,
    };
  },
  props: {
    location: Object,
    externalFeatured: Array,
    square: Boolean,
    size: Number,
    innerText: Boolean,
    desktop: Boolean,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    getFeatured(location) {
      console.log(this.selectedFeature);

      if (this.selectedFeature === "all") {
        this.$router.push({
          path: encodeURI(
            `/directory/explore/${location.city}/${location.geo.lat}/${
              location.geo.lon
            }/${Date.now()}/`
          ),
        });
      } else {
        this.$router.push({
          path: encodeURI(
            `/directory/search/${this.selectedFeature.index}/${
              this.selectedFeature.predefinedSearchName
            }/${location.city}/${location.geo.lat}/${
              location.geo.lon
            }/${Date.now()}/0/`
          ),
          query: {
            search: this.selectedFeature.search,
            categories: this.selectedFeature.categories,
            sectors: this.selectedFeature.sectors,
            title: this.selectedFeature.title,
          },
        });
      }
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {
    //this.features = this.externalFeatured || this.fixedFeatured;
  },
};
</script>

<style scoped>
.feature-title {
  font-size: 0.8em;
}

.inner-text {
  font-size: 0.575em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 768px) {
  .feature-box {
    height: 80px;
    width: 80px;
  }
  .feature-title {
    padding: 0.5em 0 0 0;
    font-size: 1em;
    font-weight: 500;
  }
}

@media (min-width: 992px) {
  .feature-box {
    height: 100px;
    width: 100px;
  }

  .inner-text {
    font-size: 0.4em;
  }
}
</style>
