<template>
  <span>
    <i class="fas fa-dollar-sign"></i>
  </span>
</template>

<script>
//let test = "hello";

export default {
  name: "UsdIcon",
  props: {
    fat: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
