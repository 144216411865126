import { API, Auth, graphqlOperation } from "aws-amplify";
import ls from "local-storage";

const storefrontStructure = `
storefrontId
        systemId
        exchangeId
        regionId
        memberId
        name
        short
        description
        location {
          add1
          add2
          city
          state
          zip
          geo {
            lat
            lon
            geolocationError
          }
        }
        standby
        waitlist
        weblinks {
          Website
          Facebook
          Instagram
          LinkedIn
        }
        weblinkNote
        phoneNumbers {
          Primary
          Mobile
          Fax
        }
        phoneNote
        emails {
          Primary
        }
        emailNote
        hidden
        tags
        categories
        sectors
        createdAt
        updatedAt
        hours {
          Sunday {
            open
            close
            closed
            note
          }
          Monday {
            open
            close
            closed
            note
          }
          Tuesday {
            open
            close
            closed
            note
          }
          Wednesday {
            open
            close
            closed
            note
          }
          Thursday {
            open
            close
            closed
            note
          }
          Friday {
            open
            close
            closed
            note
          }
          Saturday {
            open
            close
            closed
            note
          }
          disabled
        }
        hoursNote
        price
        aka {
          systemId
          userId
          name
        }
        member {
          systemId
          exchangeId
          regionId
          memberId
          name
        }
        hero {
          name
          height
          width
          size
          description
          bucket
          key
          contentType
          lastUpdated
        }
        logo {
          name
          height
          width
          size
          description
          bucket
          key
          contentType
          lastUpdated
        }
`;

function generateQuery({
  storefrontId,
  memberId,
  sort = "alphaAsc",
  size = 25,
  page = 0,
}) {
  const query = `
    storefronts: getStorefronts(
      ${storefrontId ? `storefrontId: "${storefrontId}"` : ""}
      ${memberId ? `memberId: "${memberId}"` : ""}
      page: ${page}
      size: ${size}
      sort: "${sort}"
    ) {
      storefronts {
        ${storefrontStructure}
      }
    }

`;

  return query;
}

async function storefrontListQuery(req) {
  if (!Array.isArray(req)) req = [req];

  const query = req
    .map((r) => {
      return generateQuery(r);
    })
    .join("\n");

  const res = await API.graphql({
    ...graphqlOperation(`query { ${query} }`),
    authMode: token && "AWS_LAMBDA",
    authToken: token && `Bearer ${token}`,
  });

  return res;
}

//Dead code, remove later
export async function getStorefronts({ ...args }) {
  const { data } = await storefrontListQuery({ ...args });

  return {
    storefronts: data?.storefronts?.storefronts,
    count: data?.storefronts?.count,
  };
}

export async function searchStorefronts({
  predefinedSearchName,
  search,
  tags,
  categories,
  sectors,
  geo,
  bounds,
  exchangeId,
  memberId,
  seed,
  size,
  page,
  storefrontId,
}) {
  const token = ls.get("accessToken");

  const q = `query search (
      $predefinedSearchName: String,
      $search: String,
      $storefrontId: String
      $seed: Long,
      $size: Int,
      $page: Int,
      $tags: [String],
      $categories: [String],
      $sectors: [String],
      $memberId: String,
      $exchangeId: String,
      $geo: AWSJSON,
      $bounds: AWSJSON
    ){
      search(
        index: "storefronts",
        predefinedSearchName: $predefinedSearchName,
        search: $search,
        storefrontId: $storefrontId,
        seed: $seed,
        size: $size,
        page: $page,
        tags: $tags,
        categories: $categories,
        sectors: $sectors,
        memberId: $memberId,
        exchangeId: $exchangeId,
        geo: $geo,
        bounds: $bounds

      ) { 
        storefronts {
          name
          standby
          waitlist
          noGiftCards
          hero {
            name
            height
            width
            size
            description
            bucket
            key
            contentType
            lastUpdated
          }
          logo {
            name
            height
            width
            size
            description
            bucket
            key
            contentType
            lastUpdated
          }
          location {
            add1
            add2
            city
            state
            zip
            geo {
              lat
              lon
              distance
            }
          }
          memberId
          exchangeId
          storefrontId
          hidden
          short
          search
          description
          ${
            token
              ? `aka {
            first
            last
            userId
            memberAccess {
              isMainContact
            }
          }`
              : ""
          }
          searchByPersonResult {
            first
            last
            userId
          }
          score
          sectors
          categories
          tags
          isNew
          directoryOnly
          ${token ? "rewardPoints" : ""}
          phoneNumbers {
            Primary
            Mobile
            Fax
          }
          emails {
            Primary
          }
          weblinks {
            Website
            Facebook
            Instagram
            LinkedIn
          }
        }
        count
        seed
        countLimited
        featuredCategories {
          name
          count
          score
          type
        }
        featuredSectors {
          name
          count
          score
          type
        }
        bounds
      }
    }
  `;

  /*
  ${
          predefinedSearchName
            ? `predefinedSearchName: "${predefinedSearchName}",`
            : ""
        }
        ${search ? `search: "${search}",` : ""}
        ${storefrontId ? `storefrontId: "${storefrontId}",` : ""}
        ${seed ? `seed: ${seed},` : ""}
        ${size ? `size: ${size},` : ""}
        ${page ? `page: ${page},` : ""}
        ${tags ? `tags: ${JSON.stringify(tags)},` : ""}
        ${categories ? `categories: ${JSON.stringify(categories)},` : ""}
        ${sectors ? `sectors: ${JSON.stringify(sectors)},` : ""}
        ${memberId ? `memberId: "${memberId}",` : ""}
        ${exchangeId ? `exchangeId: "${exchangeId}",` : ""}
        ${geo ? `geo: ${JSON.stringify(JSON.stringify(geo))},` : ""}
        ${bounds ? `bounds: ${JSON.stringify(JSON.stringify(bounds))},` : ""}
        */

  const res = await API.graphql({
    ...graphqlOperation(q, {
      predefinedSearchName,
      search,
      tags,
      categories,
      sectors,
      geo: geo && JSON.stringify(geo),
      bounds: bounds && JSON.stringify(bounds),
      exchangeId,
      memberId,
      seed,
      size: size || 20,
      page: page || 0,
      storefrontId,
    }),
    authMode: token && "AWS_LAMBDA",
    authToken: token && `Bearer ${token}`,
  });

  if (res?.data?.search?.bounds)
    res.data.search.bounds = JSON.parse(res?.data?.search?.bounds);

  return {
    ...res.data.search,
  };
}

export async function getMapData({ updatedSince }) {
  const token = ls.get("accessToken");

  const q = `query getMapData($updatedSince: Int){
    getMapData(
      updatedSince: $updatedSince,
    ) { 
      storefronts {
        name
        standby
        hero {
          name
          height
          width
          size
          description
          bucket
          key
          contentType
          lastUpdated
        }
        location {
          add1
          add2
          city
          state
          zip
          geo {
            lat
            lon
            distance
          }
        }
        memberId
        exchangeId
        storefrontId
        short
        search
        sectors
        categories
        tags
        
      }
      deleteList
    }
  }
`;

  const res = await API.graphql({
    ...graphqlOperation(q, {
      updatedSince,
    }),
    authMode: token && "AWS_LAMBDA",
    authToken: token && `Bearer ${token}`,
  });

  return {
    ...res.data.getMapData,
  };
}
