<template>
  <span class="rw">
    {{ v
    }}<span class="icon">
      <reward-icon />
    </span>
  </span>
</template>

<script>
import currency from "currency.js";
import RewardIcon from "./RewardIcon.vue";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

//let test = "hello";

export default {
  name: "RewardValue",
  components: { RewardIcon },
  data() {
    return {};
  },
  async mounted() {},
  beforeUnmount() {},
  props: {
    value: Number,
  },
  computed: {
    v() {
      return currency(this.value, {
        symbol: "",
        precision: 0,
      }).format();
    },
  },
};
</script>

<style scoped>
.rw {
  font-size: 1em;
  line-height: 1em;
}

.icon {
  font-size: 0.8em;
  line-height: 1em;
  margin-left: 0.1em;
}
</style>
