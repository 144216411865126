<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Community Rewards <reward-icon /></div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="text">
        <div>
          Every time you choose to save cash by purchasing from a member using
          Moxey, you helping strengthen your local business community. We think
          that deserves something special!
        </div>
        <div>
          For each eligible purchase, you will earn Moxey Community Rewards
          which are redeemable for Moxey FlashCash, and, coming soon,
          one-of-a-kind products and experiences.
        </div>
        <div>
          Additionally, you'll earn bonus Community Rewards for purchasing from
          new members and members who need to earn Moxey. Simply check the
          directory to see which members are offering bonus Rewards.
        </div>
        <div>
          To manage or redeem your awards,
          <a class="mx-info-link" href="/rewards" @click="$emit('cancel')"
            >click here</a
          >.
        </div>
        <div>
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'Account')"
          >
            Back to Account Summary
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RewardIcon from "../Shared/rewards/RewardIcon.vue";
export default {
  name: "CRInfo",
  components: { RewardIcon },
  data() {
    return {};
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.text div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

a {
  color: var(--bs-dark);
  text-decoration: none;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}
</style>
