<template>
  <div id="send-mx">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="page-icon">
            <span class="fa-layers fa-fw">
              <i
                class="fak fa-mx-sign-fat"
                data-fa-transform="shrink-5 up-1 right-2"
              ></i>
              <i
                class="fa-solid fa-hand-holding"
                data-fa-transform="shrink-5 down-3 left-1"
              ></i>
            </span>
          </div>
          <div class="header-like flex-fill">&nbsp;Request Moxey</div>
        </div>
        <div class="close-btn">
          <div @click="closePane">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <form
        @submit.prevent
        @keypress.enter.prevent
        class="sendmx-form"
        v-if="user"
        id="ccForm"
        v-show="!receipt"
      >
        <div class="card bg-warning p-3">
          <p class>
            If you have the customer's card number, you should process the
            transaction using the
            <span class="inline-link" @click="goToCharge"
              >Charge a Card page here</span
            >.
          </p>
          <p class="mb-0">
            The Request Moxey feature sends a request to the recipient via the
            app and email. They then have the choice to send Moxey to your
            accout. It does not guarantee payment.
          </p>
        </div>
        <div class="section-title">
          Request From
          <span class="text-muted"></span>
        </div>

        <div id="contact-search" class="input-group">
          <ContactSearch
            :showQR="true"
            :disabled="processing"
            @selected-contact="handleSelectedContact"
            :invalid="invalidFields.contact"
            @didFocus="
              invalidFields.contact = undefined;
              doSearchFocus();
            "
            :clearSearch="clearContactSearch"
          />
        </div>
        <div class="card-info text-danger" v-show="invalidFields.contact">
          <small>{{ invalidFields.contact }}</small>
        </div>

        <div v-if="user.products && user.products.length > 0">
          <div class="section-title">
            Service / Product Sold<span class="text-muted"></span>
          </div>

          <select
            id="productId"
            name="productId"
            v-model="productId"
            class="form-select"
            @focus="clearError('productId')"
            :class="{ 'is-invalid': invalidFields.productId }"
            placeholder="Product Sold"
            :disabled="processing"
          >
            <option :value="undefined">None</option>
            <option v-for="(p, i) in user.products" :key="i" :value="p.id">
              {{ p.description }}
            </option>
          </select>
        </div>

        <div class="section-title">
          Moxey Amount<span class="text-muted"></span>
        </div>
        <div class="input-group">
          <span class="input-group-text"
            ><i class="fak fa-mx-sign-fat fa-fw"></i
          ></span>
          <input
            type="number"
            class="form-control"
            inputmode="decimal"
            :class="{
              'is-invalid': invalidFields.amount || invalidFields.mxBalance,
            }"
            id="amount"
            min="0"
            step="0.01"
            name="amount"
            v-model="amount"
            placeholder="Amount"
            @scroll="
              (e) => {
                e.preventDefault();
              }
            "
            @keydown="
              (e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                  e.preventDefault();
              }
            "
            @focus="
              () => {
                amount = undefined;
                clearError('amount');
              }
            "
            @blur="sanitizeAmount"
            aria-label="Amount"
            :disabled="processing"
          />
        </div>
        <div class="text-danger" v-show="invalidFields.amount">
          <small>{{ invalidFields.amount }}</small>
        </div>

        <div class="section-title">Note<span class="text-muted"></span></div>
        <textarea
          class="form-control"
          id="note"
          name="note"
          v-model="note"
          rows="3"
          :class="{
            'is-invalid': invalidFields.note,
          }"
          :disabled="processing"
        ></textarea>
        <div class="text-danger" v-show="invalidFields.note">
          <small>A note is required to request Moxey.</small>
        </div>

        <div>
          <div class="text-danger mt-2" v-show="transactionError">
            <small v-if="transactionError === 'LOW_CRA'">
              The amount in member's Cash Reserve Account is not enough to cover
              this transaction.
              <router-link to="/banking/loadcra"
                >Click here to reload your Cash Reserve Account.</router-link
              >
            </small>
            <small v-else
              >Unable to process this transaction at this time. Reason:
              {{ transactionError }}</small
            >
          </div>

          <button
            v-show="!processing"
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="doValidate"
          >
            Request Moxey
          </button>
          <button v-show="processing" class="btn btn-success" disabled>
            <i class="fas fa-sync fa-spin"></i>
            Requesting Moxey
          </button>
          <button
            class="btn btn-secondary"
            type="button"
            :disabled="processing"
            @click="backToAccount"
          >
            <!-- -->
            Cancel
          </button>
        </div>
      </form>
      <div v-if="receipt" class="receipt">
        <div class="section-title">
          You requested Moxey from {{ receipt.chargeMember }}.
        </div>
        <div class="section-text">Request details:</div>
        <div class="transaction-details">
          <div class="section-text" v-if="receipt.sold">
            Service / Product Sold: {{ receipt.sold }}
          </div>
          <div class="section-text">
            Amount:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ receipt.amount }}
          </div>
          <div class="section-text">Date: {{ receipt.date }}</div>
          <div class="section-text">Note: {{ receipt.note }}</div>
        </div>

        <div class="section-text">
          {{ receipt.chargeMember }} has been notified of this request and will
          have opportunity to approve or decline it.
        </div>
        <button class="btn btn-success close-btn" @click="clearReceipt">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { log, api, gaEvent, gaError } from "@/utils/utils";
import ContactSearch from "@/components/CashApp/Shared/ContactSearch.vue";
import currency from "currency.js";
import dayjs from "dayjs";

export default {
  name: "RequestMx",
  components: {
    ContactSearch,
  },
  data() {
    return {
      requestFrom: undefined,
      amount: undefined,
      note: "",
      invalidFields: {},
      processing: false,
      transactionError: undefined,
      receipt: undefined,
      clearContactSearch: 0,
      contactValue: undefined,
      productId: undefined,
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      userIcon: (state) => state.app.all.userIcon,
      memberIcon: (state) => state.app.all.memberIcon,
      testMode: (state) => state.app.all.testMode,
    }),
  },
  watch: {},
  methods: {
    clearReceipt() {
      this.receipt = undefined;
    },
    handleSelectedContact(c) {
      this.requestFrom = c;
    },
    clearForm() {
      this.requestFrom = undefined;
      this.amount = undefined;
      this.note = "";
      this.invalidFields = {};
      this.transactionError = false;
      this.clearContactSearch = this.clearContactSearch + 1;
      this.productId =
        this.user.products &&
        this.user.products.length > 0 &&
        this.user.products[0].id;
      this.contactValue = undefined;
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    doValidate() {
      this.transactionError = undefined;
      this.invalidFields = {};

      if (!this.requestFrom)
        this.invalidFields.contact =
          "You must select a member to request Moxey from.";

      if (!this.amount || this.amount === "")
        this.invalidFields.amount = "You must specify an amount to request.";
      else if (this.amount < 0)
        this.invalidFields.amount = "Moxey amount must be greater than zero.";

      if (!this.note || this.note === "") this.invalidFields.note = true;

      if (Object.keys(this.invalidFields).length === 0) {
        this.requestMoxey();
      }
    },
    sanitizeAmount() {
      if (this.amount < 0) {
        this.invalidFields.amount = "Moxey amount must be greater than zero.";
        this.amount = "";
      } else if (!this.amount || this.amount === "") {
        this.amount = currency(0);
      } else if (this.amount && !/^\d*\.?\d*$/.test(this.amount)) {
        this.invalidFields.amount = "Invalid amount.";
        this.amount = currency(0);
      } else {
        this.amount = currency(this.amount);
      }
    },
    clearError(k, l) {
      this.invalidFields[k] = undefined;
      this.invalidFields[l] = undefined;
    },
    async requestMoxey() {
      this.processing = true;

      const sellerInfo = {
        sellerMainName: [this.user.contact.fName, this.user.contact.lname].join(
          " "
        ),
        sellerSubName: this.user.member.public.listName,
        sellerMainIcon: this.userIcon,
        sellerSubIcon: this.memberIcon,
      };

      const buyerInfo = {
        buyerType:
          this.requestFrom.id.substring(0, 1) === "c" ? "contact" : "member",
        buyerUserName:
          this.requestFrom.id.substring(0, 1) === "c"
            ? this.requestFrom.list_name
            : this.requestFrom.desc,
        buyerMemberName:
          this.requestFrom.id.substring(0, 1) === "c"
            ? this.requestFrom.desc
            : this.requestFrom.list_name,
        buyerUserIcon:
          this.requestFrom.id.substring(0, 1) === "c"
            ? this.contactIcon
            : this.subIcon,
        buyerMemberIcon:
          this.requestFrom.id.substring(0, 1) === "c"
            ? this.subIcon
            : this.contactIcon,
      };

      try {
        if (this.testMode)
          throw { error: "This is a test account and will not process Moxey" };
        await api("post", "banking", `/v2/moxey/request/`, {
          body: {
            requestFrom: this.requestFrom,
            amount: this.amount,
            note: this.note,
            productId: this.productId,
            receiptInfo: {
              ...buyerInfo,
              ...sellerInfo,
              product:
                this.user.products &&
                this.user.products.length > 0 &&
                this.user.products.find((p) => p.id === this.productId) &&
                this.user.products.find((p) => p.id === this.productId)
                  .description,
            },
          },
        });

        this.receipt = {
          sold:
            this.user.products &&
            this.user.products.length > 0 &&
            this.user.products.find((p) => p.id === this.productId) &&
            this.user.products.find((p) => p.id === this.productId).description,
          chargeMember: this.requestFrom.list_name,
          amount: currency(this.amount, { symbol: "" }).format(),
          tip: currency(this.tip, { symbol: "" }).format(),
          date: dayjs(Date.now()).format("M/D/YYYY h:mm A"),
          note: this.note,
        };

        this.updateAppStore({
          user: { ...this.user },
        });

        gaEvent(
          "request_moxey",
          "banking",
          `${buyerInfo.buyerUserName} (${buyerInfo.buyerMemberName}) => ${sellerInfo.sellerSubName} (${sellerInfo.sellerMainName}) amt:${this.amout}`
        );

        log({
          m: "Requested Moxey.",
          amt: this.amount,
          pid: this.requestFrom.id,
          mn:
            this.requestFrom.id.substring(0, 1) === "m"
              ? this.requestFrom.list_name
              : this.requestFrom.desc,
          cn:
            this.requestFrom.id.substring(0, 1) === "m"
              ? this.requestFrom.desc
              : this.requestFrom.list_name,
          partition: "banking",
        });

        this.clearForm();
      } catch (e) {
        gaError(`request_moxey: ${e.error}`);
        this.transactionError = e.error || e;
        log({
          m: "Error requesting Moxey.",
          amt: this.amount,
          pid: this.requestFrom.id,
          mn:
            this.chargeMember.id.substring(0, 1) === "m"
              ? this.requestFrom.list_name
              : this.requestFrom.desc,
          cn:
            this.chargeMember.id.substring(0, 1) === "m"
              ? this.requestFrom.desc
              : this.requestFrom.list_name,
          e: e.message || e.status,
          partition: "banking",
        });
      } finally {
        this.processing = false;
      }
    },
    closePane() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("cancel");
    },
    goToCharge() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("clickedShortcut", "ChargeCard");
    },
    backToAccount() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("clickedShortcut", "Account");
    },
    doSearchFocus() {
      const top = document
        .getElementById("contact-search")
        .getBoundingClientRect().y;
      document.getElementById("left-bar").scroll({
        top: top - 55,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  beforeMount() {},
  mounted() {
    this.clearReceipt();
    this.clearForm();
    this.$nextTick(() => {
      this.productId =
        this.user.products &&
        this.user.products.length > 0 &&
        this.user.products[0].id;
    });
  },
};
</script>

<style scoped>
#send-mx {
  background-color: white;
  color: var(--ms-dark);
  font-size: 18px;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.section {
  margin: 60px 0 0;
  padding: 30px 30px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.section:last-child {
  margin: 60px 0;
}

.section-title {
  font-size: 20px;
  line-height: 24px;
}

.section-title span {
  font-size: 16px;
  margin: 0 0 0 10px;
  font-style: italic;
}

.section-title {
  margin: 30px 0 0;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-check-input {
  padding: 12px;
  height: 24px;
  width: 24px;
  margin: 0;
}

.form-check-label {
  margin: 0 0 0 20px;
}

.check-control {
  margin: 15px 0;
  display: flex;
}

select {
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  font-style: italic;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}

.expiration:not(:last-of-type) {
  margin: 15px 20px 15px 0;
}

.btn-success {
  margin: 35px 0;
  border-color: var(--mx-green);
  background-color: var(--mx-green);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 0 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.section-text {
  margin: 15px 0;
}

.receipt-print {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

#receipt-print,
#gift-receipt-print {
  display: none;
}

.receipt {
  width: 100%;
  padding: 60px 30px 0;
}

.transaction-details {
  padding: 15px 15px;
}

.gc-info {
  margin: 15px 0 0;
  line-height: 28px;
}

.clear-contact {
  font-size: 24px;
  cursor: pointer;
  margin: 0 0 0 15px;
}

.card-info:not(:first-of-type) {
  margin: 15px 0 0 0;
}

.inline-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--bs-gray);
  display: inline-block;
}
</style>
