<template>
  <div class="qr d-flex justify-content-center">
    <div class="qr-body d-flex flex-column align-items-center">
      <div
        class="controls d-flex justify-content-around"
        v-if="cameraFound && (hasFlash || cameras.length > 1)"
      >
        <div v-if="hasFlash">
          <div v-if="flashOn" @click="toggleFlash">
            <i class="fa-solid fa-bolt"></i>
          </div>
          <div v-else @click="toggleFlash">
            <i class="fa-solid fa-bolt-slash"></i>
          </div>
        </div>
        <div v-if="cameras.length > 1" @click="nextCamera">
          <i class="fa-solid fa-camera-rotate"></i>
        </div>
      </div>

      <div class="qr-camera" v-show="cameraFound">
        <div class="camera-img">
          <video id="scanner" width="300" height="300"></video>
        </div>
      </div>

      <div class="qr-camera" v-show="initializing">
        <div
          class="d-flex justify-content-center align-items-center h-100 w-100 bg-light text-dark"
        >
          Initializing ...
        </div>
      </div>

      <div class="" v-show="!cameraFound">
        Please grant the app access to use your camera.
      </div>

      <div class="d-flex justify-content-around text-danger">
        <div @click="$emit('cancel')" class="control bg-danger">
          <i class="fa-solid fa-times"></i>
        </div>
      </div>

      <div class="text-danger" v-if="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import QrScanner from "qr-scanner";
import QrScannerWorkerPath from "!!file-loader!../../../../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default {
  name: "QRScanner",
  components: {},
  data() {
    return {
      qrScanner: undefined,
      initializing: true,
      cameraFound: false,
      error: undefined,
      cameras: [],
      cameraIndex: 0,
      hasFlash: false,
      flashOn: false,
    };
  },
  props: {
    cardType: String,
  },
  watch: {},
  computed: {},
  async mounted() {
    const scanner = document.getElementById("scanner");

    try {
      this.qrScanner = new QrScanner(scanner, (result) => {
        this.$emit("scannedCard", result);
      });

      await this.qrScanner.start();
      this.initializing = false;
      this.cameraFound = true;

      this.cameras = await QrScanner.listCameras(true);

      console.log(this.cameras[0]);

      if (this.cameras.length > 1) {
        await this.qrScanner.setCamera(
          (
            this.cameras.find(
              (c) => c.label && c.label.toLowerCase().includes("back")
            ) || this.cameras[0]
          ).id
        );
      } else {
        await this.qrScanner.setCamera(this.cameras[0].id);
      }
      this.hasFlash = await this.qrScanner.hasFlash();
      this.flashOn = await this.qrScanner.isFlashOn();
    } catch (e) {
      this.initializing = false;
      if (e === "Camera not found.") {
        this.cameraFound = false;
        this.error =
          "Access to camera is blocked. Please allow access to enable card QR scanning.";
      } else {
        this.error = e;
      }
    }
  },
  async beforeUnmount() {
    await this.qrScanner.stop();
    await this.qrScanner.destroy();
    this.qrScanner = null;
  },
  methods: {
    async nextCamera() {
      try {
        if (this.cameraIndex === this.cameras.length - 1) {
          this.cameraIndex = 0;
        } else {
          this.cameraIndex = this.cameraIndex + 1;
        }

        await this.qrScanner.setCamera(this.cameras[this.cameraIndex].id);
      } catch (e) {
        this.error === e;
      }
    },
    async toggleFlash() {
      try {
        await this.qrScanner.toggleFlash();
        this.flashOn = await this.qrScanner.isFlashOn();
      } catch (e) {
        this.error === e;
      }
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
.qr {
  position: fixed;
  background-color: rgba(198, 228, 234, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-bottom: calc(100% - 100vh);
  z-index: 150;
  backdrop-filter: blur(2px);
}

.qr-body {
  width: 100%;
  max-width: 300px;
  margin-top: calc(25vh);
}

.control {
  width: 40px;
  height: 40px;
  color: var(--mx-white);
  border-radius: 100%;
  font-size: 24px;
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-camera {
  width: 300px;
  max-width: 300px;
  height: 300px;
  min-height: 300px;
  width: 300px;
  overflow: hidden;
  border-radius: 100%;
  margin: 0 20px;
  border: 4px solid #a6a6a630;
}

.camera-img {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 50%;
  border-radius: 100%;
}

.camera-img video {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border: none;
  border-radius: 100%;
  border: 4px solid #a6a6a630;
}
</style>
