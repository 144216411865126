<template>
  <div
    v-if="establishing"
    class="location-setter d-flex justify-content-center"
  >
    <div>
      <div class="skeleton-box">
        <i class="fas fa-sync-alt fa-spin"> </i> SPOOKEY ...
      </div>
    </div>
  </div>
  <div v-else class="location-setter d-flex justify-content-center">
    <div>
      <span
        class="inline-link"
        v-if="!previousLocation"
        @click="showModal = true"
        ><i class="fa-solid fa-location-dot-slash"></i> No search location set.
        Click to choose one.</span
      >
      <span class="inline-link" v-else @click="showModal = true"
        ><i class="fa-solid fa-location-dot"></i> Searching around:
        <strong>{{ previousLocation?.city }}</strong
        >. Click to change.</span
      >
    </div>
  </div>

  <modal
    :showModal="showModal"
    :modalOptions="{
      showHeader: false,
      size: 'modal-lg modal-dialog-centered',
      source: 'zip modal',
    }"
    :additionalModalClasses="[{ 'modal-fullscreen-md-down': true }]"
    :modalBSOptions="{
      keyboard: false,
      backdrop: 'static',
    }"
    @modalClosed="() => {}"
  >
    <template v-slot
      ><div class="h-100 d-flex align-items-center">
        <div>
          <div class="hero-bg">
            <div class="is-overlay hero-x-behind"></div>
            <div class="is-overlay hero-x-front"></div>
          </div>
        </div>
        <div class="setter-body p-md-5 mx-auto">
          <h1>Moxey Means Local</h1>
          <p>
            Share your location with us, and we'll find Moxey members near you.
          </p>

          <div v-if="locationEnabled" class="mt-3">
            <p>Use your device's location:</p>
            <p class="text-center">
              <mx-button
                btnClass="btn btn-success"
                @click="findMe()"
                :height="38"
                :padX="10"
                :disabled="findingYou"
                ><i class="fas fa-location-crosshairs" />
                {{ findingYou ? "Finding you" : "Find me" }}
              </mx-button>
            </p>
            <hr />
          </div>
          <form @submit.prevent="() => searchLocation('zip')">
            <div class="mt-3">
              <p>Enter a zip code to search from:</p>
              <div class="d-flex mb-3">
                <div class="flex-fill">
                  <input
                    id="zip"
                    name="zip"
                    v-model="zip"
                    class="form-control rounded-1"
                    type="text"
                    aria-describedby="button-addon2"
                    autocomplete="off"
                    :placeholder="`Zip Code`"
                    :disabled="findingYou"
                  />
                </div>
                <div class="ms-3">
                  <mx-button
                    type="submit"
                    btnClass="btn btn-success"
                    :height="38"
                    :padX="10"
                    :disabled="findingYou || !zip"
                    >Go!
                  </mx-button>
                </div>
              </div>
            </div>
          </form>
          <hr />
          <form @submit.prevent="() => searchLocation(`metro`)">
            <div class="mt-3">
              <p>Select a metro area to search in:</p>
              <div class="d-flex">
                <div class="flex-fill">
                  <select
                    class="form-select"
                    :disabled="findingYou"
                    aria-label="Select Metro Area"
                    v-model="metro"
                  >
                    <option :value="{ city: 'Birmingham', state: 'AL' }">
                      Birmingham, AL
                    </option>
                    <option :value="{ city: 'Baton Rouge', state: 'LA' }">
                      Baton Rouge, LA
                    </option>
                    <option :value="{ city: 'Covington', state: 'LA' }">
                      Covington, LA
                    </option>
                    <option :value="{ city: 'Denham Springs', state: 'LA' }">
                      Denham Springs, LA
                    </option>
                    <option :value="{ city: 'Gonzales', state: 'LA' }">
                      Gonzales, LA
                    </option>
                    <option :value="{ city: 'Hammond', state: 'LA' }">
                      Hammond, LA
                    </option>
                    <option :value="{ city: 'Lafayette', state: 'LA' }">
                      Lafayette, LA
                    </option>
                    <option :value="{ city: 'Lake Charles', state: 'LA' }">
                      Lake Charles, LA
                    </option>
                    <option :value="{ city: 'Mandeville', state: 'LA' }">
                      Mandeville, LA
                    </option>
                    <option :value="{ city: 'Metairie', state: 'LA' }">
                      Metairie, LA
                    </option>
                    <option :value="{ city: 'Monroe', state: 'LA' }">
                      Monroe, LA
                    </option>
                    <option :value="{ city: 'New Orleans', state: 'LA' }">
                      New Orleans, LA
                    </option>
                    <option :value="{ city: 'Prairieville', state: 'LA' }">
                      Prairieville, LA
                    </option>
                    <option :value="{ city: 'Shreveport', state: 'LA' }">
                      Shreveport, LA
                    </option>
                    <option :value="{ city: 'Slidell', state: 'LA' }">
                      Slidell, LA
                    </option>
                    <option :value="{ city: 'Memphis', state: 'TN' }">
                      Memphis, TN
                    </option>
                  </select>
                </div>
                <div class="ms-3">
                  <mx-button
                    type="submit"
                    btnClass="btn btn-success"
                    :height="38"
                    :padX="10"
                    :disabled="findingYou || !metro"
                    >Go!
                  </mx-button>
                </div>
              </div>
            </div>
          </form>
          <div
            v-if="locationError"
            class="mt-4 alert alert-danger d-flex align-items-center"
          >
            <div class="fs-1">
              <i class="fas fa-location-dot-slash"></i>
            </div>
            <div class="ms-3">
              <em>Unable to find your location. Please try again.</em>
            </div>
          </div>
          <div
            v-if="deviceError"
            class="mt-4 alert alert-danger d-flex align-items-center"
          >
            <div class="fs-1">
              <i class="fas fa-location-dot-slash"></i>
            </div>
            <div class="ms-3">
              <em
                >Unable to find your location. It looks like you may have
                location sharing turned off on your device. Please reactivate it
                and try again, or try searching by zip code or metro area.</em
              >
            </div>
          </div>
          <hr />
          <div class="mt-5 text-center">
            <mx-button
              btnClass="btn btn-secondary"
              :disabled="findingYou"
              @click="reset"
              >Cancel</mx-button
            >
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import { getLocation } from "./locationGraphql";
import Modal from "../../components/Modal/Modal.vue";
import ls from "local-storage";
import MxButton from "../../components/Shared/MxButton.vue";

//let test = "hello";

export default {
  name: "LocationSetter",
  components: { Modal, MxButton },
  data() {
    return {
      showModal: false,
      previousLocation: undefined,
      locationEnabled: undefined,
      findingYou: false,
      metro: undefined,
      zip: undefined,
      locationError: false,
      deviceError: false,
      establishing: true,
    };
  },
  props: {
    memberZip: String,
    locationSelectCallback: Function,
    initialLocation: Object,
    trigger: {
      type: Number,
      default: () => 0,
    },
  },
  async mounted() {
    if (this.isEstablished) {
      await this.initialSet();
      this.establishing = false;
    }
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      isEstablished: (state) => state.app.all.isEstablished,
    }),
  },
  watch: {
    async isEstablished(post) {
      if (post) {
        await this.initialSet();

        this.establishing = false;
      }
    },
    async memberZip(post) {
      if (post) await this.initialSet();
    },
    trigger(post) {
      if (post) this.showModal = true;
    },
  },
  methods: {
    async initialSet() {
      if (!this.previousLocation) {
        this.previousLocation = this.initialLocation;

        if (this.previousLocation)
          ls.set("previousLocation", this.previousLocation);

        if (!this.previousLocation)
          this.previousLocation = ls.get("previousLocation");

        if (!this.previousLocation && this.memberZip) {
          this.zip = this.memberZip;
          await this.searchLocation(`zip`);
        } else {
          this.$emit("location", this.previousLocation);
        }
      }
    },

    findMe() {
      this.findingYou = true;
      this.locationError = false;
      this.deviceError = false;
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude: lat, longitude: lon } = position.coords;

          const location = await getLocation({ lat, lon });

          this.setLocation(location);
        },
        () => {
          this.deviceError = true;
          this.findingYou = false;
        },
        {
          enableHighAccuracy: true,
        }
      );
    },
    async searchLocation(type) {
      this.findingYou = true;
      this.locationError = false;
      this.deviceError = false;

      const location = await getLocation({
        city: type === "metro" && this.metro?.city,
        state: type === "metro" && this.metro?.state,
        zip: type === "zip" && this.zip,
      });

      this.setLocation(location);
    },
    setLocation(location) {
      this.zip = undefined;
      this.metro = undefined;

      if (!location?.city) this.locationError = true;
      else {
        ls.set("previousLocation", location);
        this.previousLocation = location;

        this.$emit("location", location);
      }

      if (this.locationSelectCallback) {
        this.locationSelectCallback(location);
      }

      this.findingYou = false;
      this.showModal = false;
    },
    reset() {
      this.zip = undefined;
      this.metro = undefined;
      this.showModal = false;
    },

    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {
    if ("geolocation" in navigator) {
      this.locationEnabled = true;
    }
  },
};
</script>

<style scoped>
.location-setter {
  font-size: 0.8em;
  text-align: center;
}

.inline-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--bs-gray);
}

.setter-body {
  z-index: 100;
  color: var(--mx-white);
  padding: 0 30px;
}
</style>
