<template>
  <div id="footer" class="d-none d-lg-flex">
    <div id="contact" class="col-section px-0">
      <div id="icon-holder" class="line">
        <img src="../../../assets/moxey_dark_bg.svg" />
      </div>
      <div class="line">
        <a
          href="mailto:&#105;&#110;&#102;&#111;&#064;&#109;&#111;&#120;&#101;&#121;&#117;&#115;&#097;&#046;&#099;&#111;&#109;"
          >&#105;&#110;&#102;&#111;&#064;&#109;&#111;&#120;&#101;&#121;&#117;&#115;&#097;&#046;&#099;&#111;&#109;</a
        >
      </div>
      <div class="line">&copy; 2021 Moxey</div>
      <div class="line">866-936-9590</div>
      <div class="line">
        <div>4137 S Sherwood Forest Blvd</div>
        <div>Suite 120</div>
        <div>Baton Rouge, LA 70816</div>
      </div>
    </div>
    <div class="col-section px-0">
      <div class="line header">Service Links</div>
      <div class="line">
        <router-link
          to="/"
          @click="clickedFooterLink('Clicked home footer link', '/', true)"
          >Home</router-link
        >
      </div>
      <div class="line">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.moxeyusa.com/"
          @click="
            clickedFooterLink(
              'Clicked about us footer link',
              'https://www.moxeyusa.com/'
            )
          "
          >About Us</a
        >
      </div>
      <div class="line">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.moxeyusa.com/"
          @click="
            clickedFooterLink(
              'Clicked how it works footer link',
              'https://www.moxeyusa.com/'
            )
          "
          >How It Works</a
        >
      </div>
      <div class="line">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.moxeyusa.com/blog"
          @click="
            clickedFooterLink(
              'Clicked blog works footer link',
              'https://www.moxeyusa.com/blog'
            )
          "
          >Blog</a
        >
      </div>
      <div class="line">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.moxeyusa.com/success-stories"
          @click="
            clickedFooterLink(
              'Clicked success stories footer link',
              'https://www.moxeyusa.com/success-stories'
            )
          "
          >Success Stories</a
        >
      </div>
    </div>
    <div class="col-section px-0">
      <div class="line header">Support</div>
      <div class="line">
        <router-link
          to="/"
          @click="clickedFooterLink('Clicked help footer link', '/', true)"
          >Help</router-link
        >
      </div>
      <div class="line">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.moxeyusa.com/contact"
          @click="
            clickedFooterLink(
              'Clicked contact footer link',
              'https://www.moxeyusa.com/contact'
            )
          "
          >Contact</a
        >
      </div>
      <div class="line">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.moxeyusa.com/"
          @click="
            clickedFooterLink(
              'Clicked FAQ footer link',
              'https://www.moxeyusa.com/'
            )
          "
          >FAQ</a
        >
      </div>
    </div>
    <div class="col-section px-0">
      <div class="line header">Follow Us</div>
      <div class="line social">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.facebook.com/Moxey/"
          @click="
            clickedFooterLink(
              'Clicked Facebook footer link',
              'https://www.facebook.com/Moxey/'
            )
          "
          ><img src="../../../assets/fb.svg"
        /></a>
        <a
          target="_blank"
          rel="noopener"
          href="https://www.instagram.com/moxeycommunity/"
          @click="
            clickedFooterLink(
              'Clicked Instagram footer link',
              'https://www.instagram.com/moxeycommunity/'
            )
          "
          ><img src="../../../assets/insta.svg"
        /></a>
        <a
          target="_blank"
          rel="noopener"
          href="https://www.youtube.com/channel/UCz38sMnR8Gz6QDW-x-6aFbg"
          @click="
            clickedFooterLink(
              'Clicked Youtube footer link',
              'https://www.youtube.com/channel/UCz38sMnR8Gz6QDW-x-6aFbg'
            )
          "
          ><img src="../../../assets/youtube.svg"
        /></a>
        <a
          target="_blank"
          rel="noopener"
          href="https://www.linkedin.com/company/moxey/"
          @click="
            clickedFooterLink(
              'Clicked LinkedIn footer link',
              'https://www.linkedin.com/company/moxey/'
            )
          "
          ><img src="../../../assets/linked-in.svg"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { log } from "@/utils/utils";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      activeItem: 0,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async clickedFooterLink(m, link, i) {
      log({ m: m, p: link, i: i || false });
    },
  },
};
</script>

<style scoped>
#footer {
  background-color: var(--mx-dark);
  color: var(--mx-white);
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  padding: 0 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#icon-holder {
  height: 17px;
}

img {
  height: 12px;
}

.line {
  margin-bottom: 8px;
  color: var(--mx-white);
}

.header {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.line a {
  text-decoration: none;
  color: var(--mx-white);
}

.line a:hover {
  text-decoration: none;
  color: var(--mx-white);
}

.social a {
  margin-right: 10px;
}

.social a img {
  height: 30px;
  width: 30px;
}

.col-section {
  padding: 30px 0 0;
  min-width: 50%;
}

@media (min-width: 768px) {
  .col-section {
    min-width: 0;
  }
}

@media (min-width: 930px) {
  #footer {
    padding: 30px 125px 60px;
    font-size: 14px;
    line-height: 17px;
  }

  .header {
    font-size: 18px;
    line-height: 22px;
  }

  #icon-holder {
    height: 22px;
  }

  img {
    height: 18px;
  }
}
</style>
