<template>
  <div v-if="user">
    <reward-value :value="pointsBalance" />
  </div>
</template>

<script>
import RewardValue from "./RewardValue.vue";
import { mapState } from "vuex";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

//let test = "hello";

export default {
  name: "RewardBalance",
  components: { RewardValue },
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      pointsBalance() {
        return this.user.account?.pointsBalance || 0;
      },
    }),
  },
  mounted() {},
};
</script>
