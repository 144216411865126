<template>
  <div id="login">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Communication Preferences</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>

      <div class="message fw-light">
        <small>
          These settings control how we will communicate with you about your
          Moxey account.</small
        >
      </div>

      <div class="message">
        <div>Transaction Receipts</div>
        <div class="mt-2 fw-light">
          <small>
            These notifations are sent when you pay another member, another
            member pays you, or you complete a Cash Reserve Account
            reload.</small
          >
        </div>
      </div>
      <form @submit.prevent="doValidate">
        <div class="row">
          <div class="col">
            <label for="txEmail">Email</label>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="txEmail"
                :disabled="updating"
                v-model="receiptEmail"
              />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label for="txPush">In App Push*</label>
          </div>
          <div class="col d-flex justify-content-end">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="txPush"
                :disabled="updating"
                v-model="receiptPush"
              />
            </div>
          </div>
        </div>
        <div class="button-holder text-center">
          <button
            type="submit"
            class="btn btn-success w-100"
            :disabled="updating"
            @click="updatePrefs"
          >
            Update Preferences
          </button>
        </div>
      </form>
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="updating"
        @click="
          () => {
            if (!reseting) $emit('cancel');
          }
        "
      >
        Cancel
      </button>
      <div class="message fw-light mt-0 pt-0">
        <small
          ><em>
            *In App Push notifications will only be sent if notifications are
            active for the Moxey App on your device. To activate notifications
            on Apple devices, please visit
            <strong>Settings > Moxey > Notifications</strong>. To activate
            notifications on Android devices, please visit
            <strong
              >Settings > Notifications > App Settings > Moxey
            </strong></em
          ></small
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { api } from "@/utils/utils";

export default {
  name: "CommsPreferences",
  components: {},
  data() {
    return {
      updating: true,
      receiptPush: false,
      receiptEmail: false,
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loginMessage: (state) => state.app.all.loginMessage,
      doNotVerify: (state) => state.app.all.doNotVerify,
    }),
  },
  async beforeMount() {
    await this.getPrefs();
  },
  methods: {
    async getPrefs() {
      try {
        this.updating = true;
        const prefs = await api("get", "core", `/comms?ts=${Date.now()}`);

        console.log(prefs);

        this.receiptPush = prefs.receiptPush;
        this.receiptEmail = prefs.receiptEmail;

        this.updating = false;
      } catch (e) {
        console.log(e);
      }
    },
    async updatePrefs() {
      try {
        this.updating = true;
        await api("post", "core", `/comms`, {
          body: {
            receiptPush: this.receiptPush,
            receiptEmail: this.receiptEmail,
          },
        });

        this.updating = false;
      } catch (e) {
        console.log(e);
      }
    },

    ...mapActions("app", ["updateAppStore"]),
  },
};
</script>

<style scoped>
#login {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 30px;
  line-height: 30px;
}
.sidebar-content {
  padding: 30px 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.btn-success {
  margin: 35px 0 15px;
  border-color: var(--mx-green);
  background-color: var(--mx-green);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 30px 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: calc(100% - 60px);
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
  text-align: start;
  width: calc(100% - 60px);
  margin: 0 30px;
}

.error.inner {
  margin: 0;
  width: 100%;
}

.whats-this {
  cursor: pointer;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.message {
  font-size: 20px;
  line-height: 28px;
  padding: 45px 30px 0;
}

form .message {
  padding: 45px 0 0;
}

label {
  font-size: 20px;
  line-height: 24px;
}
</style>
