import dayjs from "dayjs";

const testAccount = {
  nextNPSDate: 1654192883511,
  pointsSetup: {
    stagnantBuyerBonus91_100: 0,
    stagnantSellerBonus31_40: 2,
    stagnantBuyerBonus41_50: 0,
    stagnantSellerBonus71_80: 4,
    stagnantSellerBonus41_50: 2,
    stagnantSellerBonus91_100: 4,
    stagnantBuyerBonus51_60: 0,
    stagnantSellerBonus81_90: 4,
    baseEarningRate: 1,
    stagnantBuyerBonus1_10: 0,
    stagnantBuyerBonus11_20: 0,
    stagnantBuyerBonus81_90: 0,
    stagnantSellerBonus11_20: 1,
    newMemberEarningThreshold: 10000,
    stagnantBuyerBonus61_70: 0,
    stagnantSellerBonus51_60: 3,
    newMemberAgeThreshold: 180,
    stagnantBuyerBonus21_30: 0,
    stagnantSellerBonus21_30: 1,
    exchangeId: 8,
    stagnantBuyerBonus71_80: 0,
    exchangeEnrolled: true,
    stagnantSellerBonus61_70: 3,
    stagnantSellerBonus1_10: 0,
    stagnantBuyerBonus31_40: 0,
    newMemberBonus: 4,
  },
  spendingTarget: {
    sMemberNumber: "13056",
    purchasesThisMonth: 138.67,
    year: 2022,
    feeWithPhaseIn: 5.79,
    feeWithExchangeWaiver: 5.79,
    monthNumber: 3,
    maxFee: 100000,
    targetWithAge: 1519.831,
    lastUpdated: "2022-08-11T12:58:53.4630000",
    yearStartDate: "2021-08-01",
    maturityDate: dayjs().startOf("month").add(2, "months"),
    finalBalance: 923.34,
    maxBalance: 2278.67,
    monthStartDate: "2022-07-01",
    bucket4Fee: 0.045,
    memberId: 13189,
    finalSpendingTarget: 319.83,
    chargeFee: 0,
    minFee: 1,
    minBalance: -564.83,
    month: 8,
    salesLastMonth: 472.87,
    bucket3Fee: 0.015,
    capFactor: 20,
    avgQuarterlySales: 5254.38,
    pwa: 5254.38,
    calculatedSpendingTarget: 1519.831,
    fee4: 4.23,
    fee3: 2.9,
    avgQuarterlyPurchases: 7722.55,
    fee2: 1.56,
    calculationDate: "2022-07-31",
    feeWithMin: 5.79,
    feeWithCap: 5.79,
    cap: 34,
    initiationDate: dayjs().startOf("month").subtract(4, "months"),
    finalFee: 5.79,
    workingBalance: 1564.83,
    ageDays: 437,
    bucket2Fee: 0.005,
    yearPurchases: 30890.18,
    feeWithAgeWaiver: 5.79,
    purchasesLastMonth: 4125.33,
    bucket4: 93.98,
    bucket2: 312.89,
    sName: "Moxey Tech Test",
    bucket3: 193.58,
    calculatedFee: 8.69,
    bucket1: 964.38,
    percentStagnant: 0,
    exchangeId: 1,
    dSignupdate: "2021-05-21T00:00:00",
    yearSales: 21017.52,
    noFeeAge: 365,
  },
  tncVersion: 1634675767257,
  contact: {
    lname: "User",
    private: {
      altCardNumber: "",
      isMainContact: true,
      allowLogin: true,
      omitDropDown: 0,
      transAuth: false,
      cardIssued: true,
      userid: 17505,
      cardNumber: "9215792866995353",
      deactivated: "1900-01-01T00:00:00",
      activated: "2021-08-09T00:00:00",
      username: "moxeytestmode",
    },
    fName: "Moxey",
    public: {
      zip: "",
      phone_business: "",
      phone_cell: "(864) 555-5555",
      phone_home: "",
      phone_fax: "",
      city: "",
      state: "",
      add2: "",
      add1: "",
      emailValidated: 1,
      email: "moxey+test@moxeyusa.com",
    },
    contactNumber: 1,
    id: 27857,
    memberId: 13441,
  },
  profile: {
    webSite: "",
    authorityId: 8,
    exchangeName: "Moxey HQ",
    isPartialMember: 0,
    isFullMember: 1,
    memberId: 13441,
  },
  lastLogin: 1659447763944,
  exchangeFlags: {
    exchangeId: 8,
    showSpendingTarget: true,
    showCommunityFee: false,
  },
  memberMeta: {
    paysFees: 1,
    percentStagnant: 0,
    stagnantBuyerBonus: 0,
    pointsBalance: 10744,
    balance: -17.25,
    memberId: 13441,
  },
  member: {
    private: {
      salesApprThresh: "10000",
      salesmanId: 11,
      brokerId: 15,
      bypassDailyFilter: "2021-08-09T00:00:00",
      purchApprThresh: "10000",
      salesTotal: "486.11",
      taxNumber: "12345678",
      purcTotal: "503.36",
      percentStagnant: "",
      mailState: "SC",
      mailZip: "29403-____",
      mailAdd2: "Non-Corporate",
      balance: "-17.25",
      referredBy: "0",
      cashBalance: "39.5",
      mailCity: "Charleston",
      quickNote: " ",
      cashBalanceThresh: "50",
      mailAdd1: "56 Warren St.",
    },
    auth: [
      {
        auId: 8,
        auName: "Moxey HQ",
        region: "Universal",
        auLogo: "png",
        regionId: 14,
      },
    ],
    updateTimestamp: 1658244486281,
    products: [
      {
        default: 0,
        productId: 14741,
        videoURL: "",
        product_types: [
          {
            name: "Information Technology",
            image: "Images/Mobile/CATEG_InfoTech.png",
            id: 45,
            business_types: [
              {
                name: "Software and App Development",
                keywords: [
                  {
                    name: "software",
                    id: 1334,
                  },
                  {
                    name: "computer programming",
                    id: 1352,
                  },
                  {
                    name: "software development",
                    id: 1353,
                  },
                  {
                    name: "app development",
                    id: 1354,
                  },
                  {
                    name: "app",
                    id: 1355,
                  },
                  {
                    name: "programmer",
                    id: 1356,
                  },
                ],
                id: 240,
              },
            ],
          },
        ],
        showVideo: false,
        name: "Technology Services",
        active: 1,
        shortDesc: "",
        bannerAd: false,
        desc: "Here's a product description.",
      },
    ],
    memberNumber: "0-00030",
    public: {
      recPurchases: 3,
      standby: 0,
      waiting: 0,
      city: "CHARLESTON",
      showPhone: false,
      phone2: "",
      showAddress: false,
      enabled: 1,
      phone1: "8645555555",
      geo: [
        {
          lat: 32.788,
          long: -79.94,
        },
      ],
      joinDate: "2021-06-01",
      web: "",
      repSales: 3,
      fbURL: "",
      state: "SC",
      add2: "",
      fax: "",
      add1: "56 Warren St.",
      email: "jack@moxeyusa.com",
      zip: "29403",
      hidePay: 0,
      listed: 3,
      giftCard: 1,
      mapURL: "",
      listName: "Moxey Member",
      exempt: 1,
      age: 1,
      directoryOnly: 0,
    },
    PK1: "member",
    name: "Moxey Test",
    SK1: "memberId_13441",
    contacts: [
      {
        lname: "Heaton",
        private: {
          altCardNumber: "",
          isMainContact: true,
          allowLogin: true,
          omitDropDown: 0,
          transAuth: false,
          cardIssued: true,
          userid: 17505,
          cardNumber: "9215792866995353",
          activated: "2021-08-09",
          username: "moxeytestmode",
        },
        fName: "Jack",
        public: {
          zip: "",
          phone_business: "",
          phone_cell: "(864) 555-5555",
          phone_home: "",
          phone_fax: "",
          city: "",
          state: "",
          add2: "",
          add1: "",
          email: "jack+test@moxeyusa.com",
        },
        contactNumber: 1,
        id: 27857,
        memberId: 13441,
      },
    ],
    ts: "2022-07-19T10:26:25.657",
    memberId: 13441,
    v2Photo: true,
  },
  PK1: "user",
  products: [
    {
      description: "Technology Services",
      id: 14741,
    },
  ],
  account: {
    creditLimit: 2500,
    flashCashBalance: 187.45,
    flashCashEnroll: 1,
    flashNextExpireBalance: 137.45,
    flashNextExpireDate: "2022-08-22T15:38:11.0570000",
    tradeBalance: 1531.25,
    cashBalance: 67.5,
    availableBalance: 4031.25,
    pointsBalance: 10744,
  },
  SK1: "moxeytestmode",
  username: "moxeytestmode",
  wallet: {
    1619126360: {
      autoReloadAmount: 50,
      autoReloadTrigger: 50,
      billingId: "0",
      cardType: "visa",
      cardTypeNice: "Visa",
      ccExp: "0127",
      ccNumber: "427011******1111",
      customerVaultId: "0",
      priority: 1,
    },
  },
  firstLogin: 1644613920038,
  rights: {},
  pendingNotifications: "2",
  isAdmin: false,
  isElevated: false,
  mxScore: {
    iId: 13189,
    sName: "Moxey Tech Test",
    exchange: "PartnersOne",
    type: "positive",
    balance: 923.34,
    purchases: 30800.88,
    purchasesCount: 1208,
    sales: 21017.52,
    salesCount: 210,
    salesThisMonth: 2770,
    purchasesThisMonth: 4125.33,
    craLoads: 123,
    buyingPower: 1923.34,
    craBalance: 335.52,
    craAutoload: false,
    recurCount: 0,
    liquidity: 30800.88,
    qLiquidity: 7700.22,
    healthy0_90: 923.34,
    unhealthy91_180: 0,
    unhealthy181_270: 0,
    unhealthy271_360: 0,
    unsafe361plus: 0,
    score: 0,
    per: 100,
    grade: "healthy",
    age: 438,
    unsafePenalty: 0,
    unhealthyPenalty: 0,
    healthyBoost: 50,
    craReloadBoost: 50,
    craAutoloadBoost: 0,
    recurBoost: 0,
    transactionBoost: 50,
    salesBoost: 50,
    purchasesBoost: 50,
    shortTermWobble: 17,
    totalScore: 825,
    unhealthy: 0,
  },
  flashCash: [
    {
      id: "56",
      memberId: 13189,
      startingBalance: 137.45,
      currentBalance: 137.45,
      startDate: "2022-08-02T15:38:11.057Z",
      endDate: "2022-08-22T15:38:11.057Z",
      days: 20,
      status: true,
      evaporated: null,
      memberNum: "11794",
      contactNum: "6",
      transactionId: "1686578",
      note: "",
      daysLeft: "in 20 days",
      startingBalanceDisplay: "137.45",
      currentBalanceDisplay: "137.45",
    },
    {
      id: "55",
      memberId: 13189,
      startingBalance: 50,
      currentBalance: 50,
      startDate: "2022-08-02T15:37:23.757Z",
      endDate: "2022-09-01T15:37:23.757Z",
      days: 30,
      status: true,
      evaporated: null,
      memberNum: "11794",
      contactNum: "6",
      transactionId: "1686576",
      note: "",
      daysLeft: "in a month",
      startingBalanceDisplay: "50.00",
      currentBalanceDisplay: "50.00",
    },
  ],
  moxeyRequests: {
    result: [
      {
        id: 65,
        entityToType: "Member",
        entityToId: 2030,
        meta: {
          note: "Just a test request.",
          amount: 10,
          productId: 13711,
          requestor: {
            hits: {
              hit: [
                {
                  id: "c-1-27246-13189__dev",
                  fields: {
                    city: ["Baton Rouge"],
                    desc: ["Moxey Tech Test"],
                    state: ["LA"],
                    image_url: ["/v2/users/17177/avatars/avatar.jpg"],
                    list_name: ["Jack Heaton"],
                    update_ts: ["1644333569255"],
                    hidden_pay: ["No"],
                    product_icons: ["/products/13711/icon_13711.undefined"],
                    hidden_directory: ["No"],
                  },
                },
              ],
              found: 1,
              start: 0,
            },
            status: {
              rid: "j83Mz+0v9r8GCtQ93g==",
              timems: 3,
            },
          },
          receiptInfo: {
            product: "Fullstack development and FinTech",
            buyerType: "contact",
            buyerUserName: "Chip Davis",
            sellerSubIcon:
              "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1644333569255",
            sellerSubName: "Moxey Tech Test",
            sellerMainIcon:
              "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1636402678813",
            sellerMainName: "Jack Heaton",
            buyerMemberName: "Moxey HQ",
          },
          requestFrom: {
            id: "c-1-2884-2030__dev",
            city: "Baton Rouge",
            desc: "Moxey HQ",
            state: "LA",
            image_url:
              "https://d355xv48qy2m96.cloudfront.net/v2/users/559/avatars/avatar.jpg?ts=1644011668558",
            list_name: "Chip Davis",
            update_ts: "1644011668558",
            hidden_pay: "No",
            contactType: "c",
            searchValue: "chip",
            product_icons:
              "https://d355xv48qy2m96.cloudfront.net/products/7689/icon_7689.undefined?ts=1644011668558",
            hidden_directory: "No",
            product_icons_v2:
              "https://d355xv48qy2m96.cloudfront.net/v2/images/products/2030/icon.jpg?ts=1644011668558",
          },
        },
        moxeyAmt: "10.00",
        tipAmt: "0.00",
        status: "Pending",
        sentTS: "2022-02-08T15:28:18.000Z",
        note: "Just a test request.",
        amount: 10,
        productId: 13711,
        requestor: {
          hits: {
            hit: [
              {
                id: "c-1-27246-13189__dev",
                fields: {
                  city: ["Baton Rouge"],
                  desc: ["Moxey Tech Test"],
                  state: ["LA"],
                  image_url: ["/v2/users/17177/avatars/avatar.jpg"],
                  list_name: ["Jack Heaton"],
                  update_ts: ["1644333569255"],
                  hidden_pay: ["No"],
                  product_icons: ["/products/13711/icon_13711.undefined"],
                  hidden_directory: ["No"],
                },
              },
            ],
            found: 1,
            start: 0,
          },
          status: {
            rid: "j83Mz+0v9r8GCtQ93g==",
            timems: 3,
          },
        },
        receiptInfo: {
          product: "Fullstack development and FinTech",
          buyerType: "contact",
          buyerUserName: "Chip Davis",
          sellerSubIcon:
            "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1644333569255",
          sellerSubName: "Moxey Tech Test",
          sellerMainIcon:
            "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1636402678813",
          sellerMainName: "Jack Heaton",
          buyerMemberName: "Moxey HQ",
        },
        requestFrom: {
          id: "c-1-2884-2030__dev",
          city: "Baton Rouge",
          desc: "Moxey HQ",
          state: "LA",
          image_url:
            "https://d355xv48qy2m96.cloudfront.net/v2/users/559/avatars/avatar.jpg?ts=1644011668558",
          list_name: "Chip Davis",
          update_ts: "1644011668558",
          hidden_pay: "No",
          contactType: "c",
          searchValue: "chip",
          product_icons:
            "https://d355xv48qy2m96.cloudfront.net/products/7689/icon_7689.undefined?ts=1644011668558",
          hidden_directory: "No",
          product_icons_v2:
            "https://d355xv48qy2m96.cloudfront.net/v2/images/products/2030/icon.jpg?ts=1644011668558",
        },
      },
      {
        id: 64,
        entityToType: "Member",
        entityToId: 11934,
        meta: {
          note: "",
          amount: 1,
          productId: 13711,
          requestor: {
            hits: {
              hit: [
                {
                  id: "c-1-27246-13189__dev",
                  fields: {
                    city: ["Baton Rouge"],
                    desc: ["Moxey Tech Test"],
                    state: ["LA"],
                    image_url: ["/v2/users/17177/avatars/avatar.jpg"],
                    list_name: ["Jack Heaton"],
                    update_ts: ["1639080868597"],
                    hidden_pay: ["No"],
                    product_icons: ["/products/13711/icon_13711.undefined"],
                    hidden_directory: ["No"],
                  },
                },
              ],
              found: 1,
              start: 0,
            },
            status: {
              rid: "5fzBh9ovjLwSCtQ/Zw==",
              timems: 6,
            },
          },
          receiptInfo: {
            product: "Fullstack development and FinTech",
            buyerType: "contact",
            buyerUserName: "Aimee Supp",
            sellerSubIcon:
              "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1639080868597",
            sellerSubName: "Moxey Tech Test",
            sellerMainIcon:
              "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1636402678813",
            sellerMainName: "Jack Heaton",
            buyerMemberName: "Aimee Supp",
          },
          requestFrom: {
            id: "c-1-24237-11934__dev",
            city: "Baton Rouge",
            desc: "Aimee Supp",
            state: "LA",
            image_url:
              "https://d355xv48qy2m96.cloudfront.net/v2/users/15367/avatars/avatar.jpg?ts=1639080868589",
            list_name: "Aimee Supp",
            update_ts: "1639080868589",
            hidden_pay: "No",
            contactType: "c",
            searchValue: "aimee",
            hidden_directory: "Yes",
            product_icons_v2:
              "https://d355xv48qy2m96.cloudfront.net/v2/images/products/11934/icon.jpg?ts=1639080868589",
          },
        },
        moxeyAmt: "1.00",
        tipAmt: "0.00",
        status: "Pending",
        sentTS: "2021-12-09T20:44:15.000Z",
        note: "",
        amount: 1,
        productId: 13711,
        requestor: {
          hits: {
            hit: [
              {
                id: "c-1-27246-13189__dev",
                fields: {
                  city: ["Baton Rouge"],
                  desc: ["Moxey Tech Test"],
                  state: ["LA"],
                  image_url: ["/v2/users/17177/avatars/avatar.jpg"],
                  list_name: ["Jack Heaton"],
                  update_ts: ["1639080868597"],
                  hidden_pay: ["No"],
                  product_icons: ["/products/13711/icon_13711.undefined"],
                  hidden_directory: ["No"],
                },
              },
            ],
            found: 1,
            start: 0,
          },
          status: {
            rid: "5fzBh9ovjLwSCtQ/Zw==",
            timems: 6,
          },
        },
        receiptInfo: {
          product: "Fullstack development and FinTech",
          buyerType: "contact",
          buyerUserName: "Aimee Supp",
          sellerSubIcon:
            "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1639080868597",
          sellerSubName: "Moxey Tech Test",
          sellerMainIcon:
            "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1636402678813",
          sellerMainName: "Jack Heaton",
          buyerMemberName: "Aimee Supp",
        },
        requestFrom: {
          id: "c-1-24237-11934__dev",
          city: "Baton Rouge",
          desc: "Aimee Supp",
          state: "LA",
          image_url:
            "https://d355xv48qy2m96.cloudfront.net/v2/users/15367/avatars/avatar.jpg?ts=1639080868589",
          list_name: "Aimee Supp",
          update_ts: "1639080868589",
          hidden_pay: "No",
          contactType: "c",
          searchValue: "aimee",
          hidden_directory: "Yes",
          product_icons_v2:
            "https://d355xv48qy2m96.cloudfront.net/v2/images/products/11934/icon.jpg?ts=1639080868589",
        },
      },
    ],
    count: 2,
  },
  notifications: {
    result: [
      {
        id: 67,
        subject:
          'Sylvia Szentpetery requested <i class="fak fa-mx-sign-fat"></i>2.00 from you.',
        content: "asdasd",
        sentTS: "2022-04-04T16:08:16.000Z",
        readTS: null,
        deleteTS: null,
        userCanDelete: 0,
        attachments: [
          {
            type: "requestMx",
            id: 67,
            notificationId: 67,
            meta: {
              note: "asdasd",
              amount: 2,
              productId: 13711,
              requestor: {
                hits: {
                  hit: [
                    {
                      id: "c-20-27248-13189__dev",
                      fields: {
                        city: ["Baton Rouge"],
                        desc: ["Moxey Tech Test"],
                        state: ["LA"],
                        image_url: ["/v2/users/17179/avatars/avatar.jpg"],
                        list_name: ["Sylvia Szentpetery"],
                        update_ts: ["1647698792101"],
                        hidden_pay: ["No"],
                        product_icons: ["/products/13711/icon_13711.undefined"],
                        hidden_directory: ["Yes"],
                      },
                    },
                  ],
                  found: 1,
                  start: 0,
                },
                status: {
                  rid: "+/vVqv8v/6UZCtQxRA==",
                  timems: 3,
                },
              },
              receiptInfo: {
                product: "Fullstack development and FinTech",
                buyerType: "member",
                buyerUserName: "Jack Heaton",
                sellerSubIcon:
                  "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
                sellerSubName: "Moxey Tech Test",
                sellerMainName: "Sylvia Szentpetery",
                buyerMemberName: "Moxey Tech Test",
              },
              requestFrom: {
                id: "m-13189-13189__dev",
                city: "Baton Rouge",
                desc: "Jack Heaton",
                state: "LA",
                subIcon:
                  "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
                image_url:
                  "https://d355xv48qy2m96.cloudfront.net/products/13711/icon_13711.undefined?ts=1647698792101",
                list_name: "Moxey Tech Test",
                update_ts: "1647698792101",
                hidden_pay: "No",
                contactIcon:
                  "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
                contactType: "m",
                searchValue: "moxey tech",
                image_url_v2:
                  "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
                product_icons:
                  "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
                hidden_directory: "No",
              },
            },
            moxeyAmt: "2.00",
            completeTS: null,
            status: "Pending",
            note: "asdasd",
            amount: 2,
            productId: 13711,
            requestor: {
              hits: {
                hit: [
                  {
                    id: "c-20-27248-13189__dev",
                    fields: {
                      city: ["Baton Rouge"],
                      desc: ["Moxey Tech Test"],
                      state: ["LA"],
                      image_url: ["/v2/users/17179/avatars/avatar.jpg"],
                      list_name: ["Sylvia Szentpetery"],
                      update_ts: ["1647698792101"],
                      hidden_pay: ["No"],
                      product_icons: ["/products/13711/icon_13711.undefined"],
                      hidden_directory: ["Yes"],
                    },
                  },
                ],
                found: 1,
                start: 0,
              },
              status: {
                rid: "+/vVqv8v/6UZCtQxRA==",
                timems: 3,
              },
            },
            receiptInfo: {
              product: "Fullstack development and FinTech",
              buyerType: "member",
              buyerUserName: "Jack Heaton",
              sellerSubIcon:
                "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
              sellerSubName: "Moxey Tech Test",
              sellerMainName: "Sylvia Szentpetery",
              buyerMemberName: "Moxey Tech Test",
            },
            requestFrom: {
              id: "m-13189-13189__dev",
              city: "Baton Rouge",
              desc: "Jack Heaton",
              state: "LA",
              subIcon:
                "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
              image_url:
                "https://d355xv48qy2m96.cloudfront.net/products/13711/icon_13711.undefined?ts=1647698792101",
              list_name: "Moxey Tech Test",
              update_ts: "1647698792101",
              hidden_pay: "No",
              contactIcon:
                "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
              contactType: "m",
              searchValue: "moxey tech",
              image_url_v2:
                "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
              product_icons:
                "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
              hidden_directory: "No",
            },
            tipAmt: "0.00",
          },
        ],
      },
      {
        id: 66,
        subject:
          'Sylvia Szentpetery requested <i class="fak fa-mx-sign-fat"></i>1.00 from you.',
        content: "Test",
        sentTS: "2022-04-04T15:59:23.000Z",
        readTS: null,
        deleteTS: null,
        userCanDelete: 0,
        attachments: [
          {
            type: "requestMx",
            id: 66,
            notificationId: 66,
            meta: {
              note: "Test",
              amount: 1,
              productId: 13711,
              requestor: {
                hits: {
                  hit: [
                    {
                      id: "c-20-27248-13189__dev",
                      fields: {
                        city: ["Baton Rouge"],
                        desc: ["Moxey Tech Test"],
                        state: ["LA"],
                        image_url: ["/v2/users/17179/avatars/avatar.jpg"],
                        list_name: ["Sylvia Szentpetery"],
                        update_ts: ["1647698792101"],
                        hidden_pay: ["No"],
                        product_icons: ["/products/13711/icon_13711.undefined"],
                        hidden_directory: ["Yes"],
                      },
                    },
                  ],
                  found: 1,
                  start: 0,
                },
                status: {
                  rid: "3b61qv8v0qIZCtQxRA==",
                  timems: 6,
                },
              },
              receiptInfo: {
                product: "Fullstack development and FinTech",
                buyerType: "member",
                buyerUserName: "Jack Heaton",
                sellerSubIcon:
                  "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
                sellerSubName: "Moxey Tech Test",
                sellerMainName: "Sylvia Szentpetery",
                buyerMemberName: "Moxey Tech Test",
              },
              requestFrom: {
                id: "m-13189-13189__dev",
                city: "Baton Rouge",
                desc: "Jack Heaton",
                state: "LA",
                subIcon:
                  "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
                image_url:
                  "https://d355xv48qy2m96.cloudfront.net/products/13711/icon_13711.undefined?ts=1647698792101",
                list_name: "Moxey Tech Test",
                update_ts: "1647698792101",
                hidden_pay: "No",
                contactIcon:
                  "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
                contactType: "m",
                searchValue: "moxey tech",
                image_url_v2:
                  "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
                product_icons:
                  "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
                hidden_directory: "No",
              },
            },
            moxeyAmt: "1.00",
            completeTS: null,
            status: "Pending",
            note: "Test",
            amount: 1,
            productId: 13711,
            requestor: {
              hits: {
                hit: [
                  {
                    id: "c-20-27248-13189__dev",
                    fields: {
                      city: ["Baton Rouge"],
                      desc: ["Moxey Tech Test"],
                      state: ["LA"],
                      image_url: ["/v2/users/17179/avatars/avatar.jpg"],
                      list_name: ["Sylvia Szentpetery"],
                      update_ts: ["1647698792101"],
                      hidden_pay: ["No"],
                      product_icons: ["/products/13711/icon_13711.undefined"],
                      hidden_directory: ["Yes"],
                    },
                  },
                ],
                found: 1,
                start: 0,
              },
              status: {
                rid: "3b61qv8v0qIZCtQxRA==",
                timems: 6,
              },
            },
            receiptInfo: {
              product: "Fullstack development and FinTech",
              buyerType: "member",
              buyerUserName: "Jack Heaton",
              sellerSubIcon:
                "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
              sellerSubName: "Moxey Tech Test",
              sellerMainName: "Sylvia Szentpetery",
              buyerMemberName: "Moxey Tech Test",
            },
            requestFrom: {
              id: "m-13189-13189__dev",
              city: "Baton Rouge",
              desc: "Jack Heaton",
              state: "LA",
              subIcon:
                "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
              image_url:
                "https://d355xv48qy2m96.cloudfront.net/products/13711/icon_13711.undefined?ts=1647698792101",
              list_name: "Moxey Tech Test",
              update_ts: "1647698792101",
              hidden_pay: "No",
              contactIcon:
                "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
              contactType: "m",
              searchValue: "moxey tech",
              image_url_v2:
                "https://d355xv48qy2m96.cloudfront.net/v2/images/products/13189/icon.jpg?ts=1647698792101",
              product_icons:
                "https://d355xv48qy2m96.cloudfront.net/v2/users/17177/avatars/avatar.jpg?ts=1647698792101",
              hidden_directory: "No",
            },
            tipAmt: "0.00",
          },
        ],
      },
    ],
    count: 2,
  },
  sales: [
    {
      o: 1,
      month: "September",
      total: 1903.45,
      count: 15,
    },
    {
      o: 2,
      month: "October",
      total: 2256,
      count: 12,
    },
    {
      o: 3,
      month: "November",
      total: 1109.26,
      count: 18,
    },
    {
      o: 4,
      month: "December",
      total: 1650,
      count: 1,
    },
    {
      o: 5,
      month: "January",
      total: 1265,
      count: 3,
    },
    {
      o: 6,
      month: "February",
      total: 1284.18,
      count: 20,
    },
    {
      o: 7,
      month: "March",
      total: 2382.5,
      count: 9,
    },
    {
      o: 8,
      month: "April",
      total: 2477,
      count: 12,
    },
    {
      o: 9,
      month: "May",
      total: 3142,
      count: 4,
    },
    {
      o: 10,
      month: "June",
      total: 2035,
      count: 44,
    },
    {
      o: 11,
      month: "July",
      total: 3770,
      count: 15,
    },
    {
      o: 12,
      month: "August",
      total: 2187.45,
      count: 2,
    },
  ],
  savings: [
    {
      o: 1,
      month: "September",
      total: 1903.45,
      count: 15,
    },
    {
      o: 2,
      month: "October",
      total: 2256,
      count: 12,
    },
    {
      o: 3,
      month: "November",
      total: 1109.26,
      count: 18,
    },
    {
      o: 4,
      month: "December",
      total: 1650,
      count: 1,
    },
    {
      o: 5,
      month: "January",
      total: 1265,
      count: 3,
    },
    {
      o: 6,
      month: "February",
      total: 1284.18,
      count: 20,
    },
    {
      o: 7,
      month: "March",
      total: 2382.5,
      count: 9,
    },
    {
      o: 8,
      month: "April",
      total: 2477,
      count: 12,
    },
    {
      o: 9,
      month: "May",
      total: 3142,
      count: 4,
    },
    {
      o: 10,
      month: "June",
      total: 2035,
      count: 44,
    },
    {
      o: 11,
      month: "July",
      total: 3770,
      count: 15,
    },
    {
      o: 12,
      month: "August",
      total: 2187.45,
      count: 2,
    },
  ],
  savingsSummary: [
    {
      type: "bLast30",
      count: 43,
      total: 4260,
    },
    {
      type: "bLast365",
      count: 1227,
      total: 30907.85,
    },
    {
      type: "bLifetime",
      count: 1233,
      total: 31004.85,
    },
    {
      type: "bMTD",
      count: 1,
      total: 134.67,
    },
    {
      type: "bYTD",
      count: 1078,
      total: 28292.5,
    },
  ],
  salesSummary: [
    {
      type: "sLast30",
      count: 43,
      total: 4260,
    },
    {
      type: "sLast365",
      count: 1227,
      total: 30907.85,
    },
    {
      type: "sLifetime",
      count: 1233,
      total: 31004.85,
    },
    {
      type: "sMTD",
      count: 1,
      total: 134.67,
    },
    {
      type: "sYTD",
      count: 1078,
      total: 28292.5,
    },
  ],
  savingsAnalysis: [
    {
      time: "Lifetime",
      spendDetail:
        '[{"name":"Information Technology","spend":26151.8300,"per":85.9200,"ord":1},{"name":"Pet Products & Services","spend":2589.0000,"per":8.5000,"ord":2},{"name":"Business Products & Services","spend":1611.7700,"per":5.2900,"ord":3},{"name":"Auto - Marine - Motorsports","spend":38.0000,"per":0.1200,"ord":4},{"name":"Personal Services","spend":26.7500,"per":0.0800,"ord":5},{"name":"Travel & Accommodations","spend":18.0000,"per":0.0500,"ord":6}]',
      spendChart:
        '[{"name":"Information Technology","per":85.9200},{"name":"Pet Products & Services","per":8.5000},{"name":"Business Products & Services","per":5.2900},{"name":"Auto - Marine - Motorsports","per":0.1200},{"name":"Personal Services","per":0.0800},{"name":"Other","per":0.0500}]',
    },
    {
      time: "LastYear",
      spendDetail:
        '[{"name":"Information Technology","spend":26151.8300,"per":86.2000,"ord":1},{"name":"Pet Products & Services","spend":2589.0000,"per":8.5300,"ord":2},{"name":"Business Products & Services","spend":1514.7700,"per":4.9900,"ord":3},{"name":"Auto - Marine - Motorsports","spend":38.0000,"per":0.1200,"ord":4},{"name":"Personal Services","spend":26.7500,"per":0.0800,"ord":5},{"name":"Travel & Accommodations","spend":18.0000,"per":0.0500,"ord":6}]',
      spendChart:
        '[{"name":"Information Technology","per":86.2000},{"name":"Pet Products & Services","per":8.5300},{"name":"Business Products & Services","per":4.9900},{"name":"Auto - Marine - Motorsports","per":0.1200},{"name":"Personal Services","per":0.0800},{"name":"Other","per":0.0500}]',
    },
    {
      time: "LastMonth",
      spendDetail:
        '[{"name":"Information Technology","spend":4082.3300,"per":96.0500,"ord":1},{"name":"Business Products & Services","spend":134.6700,"per":3.1600,"ord":2},{"name":"Travel & Accommodations","spend":18.0000,"per":0.4200,"ord":3},{"name":"Auto - Marine - Motorsports","spend":15.0000,"per":0.3500,"ord":4}]',
      spendChart:
        '[{"name":"Information Technology","per":96.0500},{"name":"Business Products & Services","per":3.1600},{"name":"Travel & Accommodations","per":0.4200},{"name":"Auto - Marine - Motorsports","per":0.3500}]',
    },
  ],
  transactions: [
    {
      id: 1686558,
      date: "2022-07-15T07:12:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "EZAssemble",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Davis, Chip",
      buyer: "Jack Heaton",
      seller: "Chip Davis",
      amount: "1.00",
      fee: "0.10",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1132,
      sellerUserId: 461,
      buyerUserId: 17177,
      points: 1,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686557,
      date: "2022-07-15T07:10:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "EZAssemble",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Davis, Chip",
      buyer: "Jack Heaton",
      seller: "Chip Davis",
      amount: "2.00",
      fee: "0.20",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1132,
      sellerUserId: 461,
      buyerUserId: 17177,
      points: 2,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686556,
      date: "2022-07-14T07:16:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "EZAssemble",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Davis, Chip",
      buyer: "Jack Heaton",
      seller: "Chip Davis",
      amount: "1.00",
      fee: "0.10",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1132,
      sellerUserId: 461,
      buyerUserId: 17177,
      points: 1,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686555,
      date: "2022-07-12T15:58:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "Rocket Auto Repair - Jefferson Hwy",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Owens, Brad",
      buyer: "Jack Heaton",
      seller: "Brad Owens",
      amount: "5.00",
      fee: "0.50",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1792,
      sellerUserId: null,
      buyerUserId: 17177,
      points: 25,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686554,
      date: "2022-07-12T10:57:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "EZAssemble",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Davis, Chip",
      buyer: "Jack Heaton",
      seller: "Chip Davis",
      amount: "5.00",
      fee: "0.50",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1132,
      sellerUserId: 461,
      buyerUserId: 17177,
      points: 5,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686553,
      date: "2022-07-12T10:47:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "EZAssemble",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Davis, Chip",
      buyer: "Jack Heaton",
      seller: "Chip Davis",
      amount: "1.00",
      fee: "0.10",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1132,
      sellerUserId: 461,
      buyerUserId: 17177,
      points: 1,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686552,
      date: "2022-07-12T10:45:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "EZAssemble",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Davis, Chip",
      buyer: "Jack Heaton",
      seller: "Chip Davis",
      amount: "2.00",
      fee: "0.20",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1132,
      sellerUserId: 461,
      buyerUserId: 17177,
      points: 2,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686551,
      date: "2022-07-12T09:52:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Sale",
      member: "  PartnersOne",
      buyerLnf: "Partners, Referral",
      sellerLnf: "Heaton, Jack",
      buyer: "Referral Partners",
      seller: "Jack Heaton",
      amount: "10.00",
      fee: "0.00",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "Moxey FlashCash Deposit",
      productId: 0,
      memberId: 1794,
      sellerUserId: 17177,
      buyerUserId: null,
      points: -1000,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686550,
      date: "2022-07-12T09:49:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "A-1 Car Care",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Aymond, Scottie",
      buyer: "Jack Heaton",
      seller: "Scottie Aymond",
      amount: "10.00",
      fee: "1.00",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 7935,
      sellerUserId: 10601,
      buyerUserId: 17177,
      points: 50,
      count: "10",
      page: "1",
      total: 2586,
    },
    {
      id: 1686549,
      date: "2022-07-12T09:48:00-05:00",
      type: "Trade Transaction (C)",
      typeId: 1,
      statusId: 1,
      action: "Purchase",
      member: "EZAssemble",
      buyerLnf: "Heaton, Jack",
      sellerLnf: "Davis, Chip",
      buyer: "Jack Heaton",
      seller: "Chip Davis",
      amount: "5.00",
      fee: "0.50",
      tip: "0.00",
      status: "Approved",
      symbol: "m",
      description: "",
      productId: 0,
      memberId: 1132,
      sellerUserId: 461,
      buyerUserId: 17177,
      points: 5,
      count: "10",
      page: "1",
      total: 2586,
    },
  ],
  rewards: [
    {
      id: 29,
      exchangeId: 1,
      cost: 1000,
      reward: 10,
      daysToExpire: 3,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 3 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 3 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 1,
    },
    {
      id: 30,
      exchangeId: 1,
      cost: 2500,
      reward: 25,
      daysToExpire: 5,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 5 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 5 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 1,
    },
    {
      id: 31,
      exchangeId: 1,
      cost: 5000,
      reward: 55,
      daysToExpire: 7,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 7 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 7 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 1,
    },
    {
      id: 32,
      exchangeId: 1,
      cost: 7500,
      reward: 85,
      daysToExpire: 15,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 15 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 15 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 1,
    },
    {
      id: 33,
      exchangeId: 1,
      cost: 10000,
      reward: 120,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 1,
    },
    {
      id: 34,
      exchangeId: 1,
      cost: 20000,
      reward: 250,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 35,
      exchangeId: 1,
      cost: 30000,
      reward: 390,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 36,
      exchangeId: 1,
      cost: 40000,
      reward: 560,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 37,
      exchangeId: 1,
      cost: 50000,
      reward: 750,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 38,
      exchangeId: 1,
      cost: 60000,
      reward: 900,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 39,
      exchangeId: 1,
      cost: 70000,
      reward: 1190,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 40,
      exchangeId: 1,
      cost: 80000,
      reward: 1440,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 41,
      exchangeId: 1,
      cost: 90000,
      reward: 1710,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
    {
      id: 42,
      exchangeId: 1,
      cost: 100000,
      reward: 2000,
      daysToExpire: 30,
      type: "fc",
      title: "Moxey FlashCash",
      description: "To spend within 30 days of redemption.",
      redeemInstructions:
        "Once confirmed, this FlashCash reward will automatically be added to your Moxey balance. Remember, any remaining FlashCash balance not spent <strong>within 30 days</strong> will be evaporated and removed from your account.",
      autoRedeem: true,
      image: null,
      unlocked: 0,
    },
  ],
};

export default testAccount;
