<template>
  <div
    class="header d-flex align-items-center justify-content-end position-sticky top-0"
  >
    <div
      class="rounded-circle back-button border border-secondary d-flex justify-content-center align-items-center position-relative"
      @click="() => $emit(`closemodal`)"
    >
      <div class="" style="">
        <i class="fas fa-times"></i>
      </div>
    </div>
  </div>
  <div v-if="storefront">
    <div class="row gx-0">
      <div class="col col-lg-8 pe-0">
        <result-image
          v-if="!holdLoad"
          :img="storefront.hero"
          class="hero"
          :key="storefront"
        />
      </div>
      <div class="d-none d-lg-block col ps-0" v-if="storefront.location?.geo">
        <a class="h-100" target="_blank" :href="directionsURL">
          <img :src="getGeoURL()" class="w-100 h-100" />
        </a>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-center mb-4">
            <div v-if="storefront?.logo" class="me-3 logo">
              <result-image
                v-if="!holdLoad"
                :img="{
                  ...storefront.logo,
                  key: storefront.logo.key.replace('name=hero', 'name=type'),
                }"
                class="logo-image border rounded-circle"
                :key="storefront"
              />
            </div>
            <div>
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ storefront?.name }}</h2>
                </div>
                <div
                  class="ms-3 h-100 d-flex justify-content-center align-items-center"
                  style="margin: -0.5em 0"
                  v-if="storefront.isNew"
                >
                  <div style="color: gold; font-size: 2.5em">
                    <i class="fas fa-certificate" style="color: gold"></i>
                  </div>
                  <div
                    class="position-absolute fw-bold"
                    style="
                      font-size: 0.85rem;
                      line-height: 0;
                      transform: rotate(15deg);
                    "
                  >
                    NEW!
                  </div>
                </div>
              </div>
              <div class="mb-2">{{ storefront.short }}</div>
              <div>
                {{
                  storefront?.location?.geo?.distance
                    ? `${(
                        storefront?.location?.geo?.distance * 0.00062
                      ).toFixed(1)} miles away -`
                    : ""
                }}
                <span class="text-proper-case">{{
                  [
                    storefront.location?.city &&
                      `${storefront.location.city.toLowerCase()}`,
                    storefront.location?.state &&
                      `${storefront.location.state}`,
                  ]
                    .filter((l) => l)
                    .join(", ")
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="edit-button section text-muted"
            v-if="userCanEdit || previewAccess"
          >
            <button
              class="btn btn-sm btn-outline-secondary"
              v-if="userCanEdit"
              @click="
                $emit(`closemodal`);
                $router.push(
                  `/directory/member/memberId_${storefront.memberId}/?edit=true`
                );
              "
            >
              <i class="fa-duotone fa-pencil"></i> Edit Listing
            </button>

            <button
              class="btn btn-sm btn-outline-secondary ms-3"
              v-if="previewAccess"
              @click="
                $emit(`closemodal`);
                $router.push(
                  `/directory/member/memberId_${storefront.memberId}/?preview=true`
                );
              "
            >
              <i class="fa-solid fa-eye"></i> Preview Content
            </button>
          </div>

          <div
            v-if="storefront.noGiftCards || storefront.standby"
            class="offers alert alert-light border-warning fw-light mt-3 text-dark"
          >
            <div class="d-flex align-items-center" v-if="storefront.standby">
              <div class="fs-3"><i class="fas fa-pause fa-fw"></i></div>
              <div class="ms-3">
                <div class="fw-bold">Member is on standby</div>
                <div>
                  This member is temporarily on hold for new Moxey sales. Please
                  consider purchasing from another member or contact your
                  exchange office for details.
                </div>
              </div>
            </div>
            <div
              :class="[
                'd-flex align-items-center',
                { 'mt-3': storefront.standby },
              ]"
              v-if="true || storefront.noGiftCards"
            >
              <div class="fs-3">
                <i class="fak fa-solid-gift-card-slash fa-fw"></i>
              </div>
              <div class="ms-3">
                <div class="fw-bold">Member is not accepting gift cards</div>
                <div>
                  This member is temporarily not accepting Moxey gift cards.
                  Please consider purchasing from another member or contact your
                  exchange office for details.
                </div>
              </div>
            </div>
          </div>

          <div
            v-else-if="
              [storefront.rewardPoints, storefront.offers].filter((f) => f)
                .length
            "
            class="offers alert alert-light border-success fw-light mt-3"
          >
            <div
              class="d-flex align-items-center"
              v-if="storefront.rewardPoints"
            >
              <div class="fs-3"><reward-icon></reward-icon></div>
              <div class="ms-3">
                Earn
                <span class="fw-bold"
                  >{{ storefront.rewardPoints }} Community Reward Point{{
                    storefront.rewardPoints === 1 ? "" : "s"
                  }}</span
                >
                for every
                <span class="fw-bold"
                  ><moxey-value :value="1" :noCents="true"
                /></span>
                spent with this member.
              </div>
            </div>
            <div
              :class="[
                'd-flex align-items-center',
                { 'mt-3': storefront.rewardPoints },
              ]"
              v-if="storefront.promotions"
            >
              <div class="fs-3"><flash-cash-icon /></div>
              <div class="ms-3">
                Spend
                <span class="fw-bold"
                  ><moxey-value :value="200" :noCents="true"
                /></span>
                and get
                <span class="fw-bold"
                  ><moxey-value :value="10" :noCents="true" :flashCash="true"
                /></span>
                Moxey FlashCash back.
              </div>
            </div>
          </div>

          <div class="mt-3" v-html="storefront.description"></div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="mt-3" v-if="storefront.aka?.[0]">
            Main contact:
            <span class="fw-light">
              {{
                [storefront.aka?.[0].first, storefront.aka?.[0].last]
                  .filter((n) => n)
                  .join(" ")
              }}
            </span>
          </div>

          <div
            class="mt-3"
            v-if="
              storefront?.phoneNumbers &&
              Object.keys(storefront?.phoneNumbers)?.length
            "
          >
            <div v-if="storefront.phoneNumbers?.Primary">
              Phone:
              <a
                :href="getPhoneURI(storefront.phoneNumbers.Primary)"
                class="fw-light"
              >
                {{ formatPhoneNumber(storefront.phoneNumbers.Primary) }}
              </a>
            </div>

            <div v-if="storefront.phoneNumbers?.Mobile">
              Mobile:

              <a
                :href="getPhoneURI(storefront.phoneNumbers.Mobile)"
                class="fw-light"
              >
                {{ formatPhoneNumber(storefront.phoneNumbers?.Mobile) }}
              </a>
            </div>

            <div v-if="storefront.phoneNumbers?.Fax">
              Fax:
              <span class="fw-light">
                {{ formatPhoneNumber(storefront.phoneNumbers?.Fax) }}
              </span>
            </div>
          </div>

          <div class="mt-3" v-if="storefront?.emails?.Primary">
            Email:
            <a :href="`mailto:${storefront.emails?.Primary}`" class="fw-light">
              {{ storefront.emails?.Primary }}
            </a>
          </div>

          <div
            class="mt-3"
            v-if="
              storefront.weblinks &&
              Object.keys(storefront.weblinks).filter(
                (w) => storefront.weblinks[w]
              )?.length
            "
          >
            <div>Weblinks:</div>
            <div v-if="storefront.weblinks.Website">
              <a :href="this.formatExternalLink(storefront.weblinks.Website)"
                ><i class="fas fa-globe" /> Website</a
              >
            </div>
            <div v-if="storefront.weblinks.Facebook">
              <a :href="this.formatExternalLink(storefront.weblinks.Facebook)"
                ><i class="fa-brands fa-facebook" /> Facebook</a
              >
            </div>
            <div v-if="storefront.weblinks.Instagram">
              <a :href="this.formatExternalLink(storefront.weblinks.Instagram)"
                ><i class="fa-brands fa-instagram" /> Instagram</a
              >
            </div>
            <div v-if="storefront.weblinks.Twitter">
              <a :href="this.formatExternalLink(storefront.weblinks.Twitter)"
                ><i class="fa-brands fa-twitter" /> Twitter</a
              >
            </div>
            <div v-if="storefront.weblinks.LinkedIn">
              <a :href="this.formatExternalLink(storefront.weblinks.LinkedIn)"
                ><i class="fa-brands fa-linkedin" /> LinkedIn</a
              >
            </div>
          </div>

          <div class="mt-3" v-if="storefront.location?.add1">
            Address:
            <div class="fw-light">
              <a target="_blank" :href="directionsURL">{{
                storefront.location.add1
              }}</a>
            </div>
            <div class="fw-light" v-if="storefront.location?.add2">
              <a target="_blank" :href="directionsURL">{{
                storefront.location.add2
              }}</a>
            </div>
            <div class="fw-light text-proper-case">
              <a target="_blank" :href="directionsURL">{{
                [
                  [
                    storefront.location.city?.toLowerCase(),
                    storefront.location.state,
                  ]
                    .filter((a) => a)
                    .join(", "),
                  storefront.location.zip,
                ]
                  .filter((a) => a)
                  .join(" ")
              }}</a>
            </div>

            <div v-if="storefront.location?.geo" class="mt-3 d-lg-none">
              <a target="_blank" :href="directionsURL">
                <div>
                  <img :src="getGeoURL()" class="w-100" />
                </div>
              </a>
            </div>
          </div>

          <div v-if="desktop" class="mt-4">
            <share-button
              :storefront="storefront"
              :inline="true"
              :desktop="desktop"
              class="text-primary"
            />
          </div>
        </div>
        <div class="col-12 mt-4">
          <storefront-extra
            :desktop="desktop"
            :storefront="storefront"
            :trigger="searchTrigger"
            @selectstorefront="
              (s) => {
                $emit('selectstorefront', s);
              }
            "
            @navigateaway="
              (s) => {
                $emit('selectstorefront', undefined);
              }
            "
          />
        </div>
      </div>
    </div>
    <storefront-control-bar :storefront="storefront" class="d-lg-none" />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import ResultImage from "../search/ResultImage.vue";
import RewardIcon from "../../../components/Shared/rewards/RewardIcon.vue";
import MoxeyValue from "../../../components/Shared/moxey/MoxeyValue.vue";
import FlashCashIcon from "../../../components/Shared/moxey/FlashCashIcon.vue";
import parsePhoneNumber from "libphonenumber-js";
import StorefrontControlBar from "./StorefrontControlBar.vue";
import StorefrontExtra from "./StorefrontExtra.vue";
import ShareButton from "./ShareButton.vue";

//let test = "hello";

export default {
  name: "Member",
  components: {
    ResultImage,
    RewardIcon,
    MoxeyValue,
    FlashCashIcon,
    StorefrontControlBar,
    StorefrontExtra,
    ShareButton,
  },
  data() {
    return {
      searchTrigger: 0,
    };
  },
  props: {
    storefront: Object,
    holdLoad: Boolean,
    isModal: Boolean,
    desktop: Boolean,
  },
  async mounted() {
    console.log(this.storefront);
  },
  beforeUnmount() {},
  computed: {
    sf() {
      return this.storefront;
    },
    directionsURL() {
      return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
        [
          this.storefront?.location?.add1,
          this.storefront?.location?.add2,
          this.storefront?.location?.city,
          this.storefront?.location?.state,
          this.storefront?.location?.zip,
        ]
          .filter((a) => a)
          .join(" ")
      )}`;
    },
    shareUrl() {
      return `https://app.moxeyusa.com/directory/storefronts/${this.storefront?.name.replace(
        /[^A-z]/g,
        "-"
      )}/${this.storefront.storefrontId}`;
    },

    ...mapState({
      user: (state) => state.app.all.user,
    }),
    userCanEdit() {
      if (
        this.storefront &&
        this.user &&
        (this.user.rights["Super|||Listing"] ||
          this.user.rights[`${this.storefront?.exchangeId}|||Listing`])
      ) {
        return true;
      }
      return false;
    },
    previewAccess() {
      if (
        this.storefront &&
        this.user &&
        (this.user.rights["Super|||Admin"] ||
          this.user.rights[`${this.storefront?.exchangeId}|||Admin`])
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    storefront(post) {
      console.log(post);
    },
  },
  methods: {
    formatPhoneNumber(num) {
      try {
        const pn = parsePhoneNumber(num, "US");

        return pn.formatNational();
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    formatExternalLink(link) {
      return link.indexOf("http://") === 0 || link.indexOf("https://") === 0
        ? link
        : `https://${link}`;
    },

    getGeoURL() {
      const geo = this.storefront.location.geo;

      if (geo) {
        const { lon, lat } = geo;
        const x = 500;
        const y = 500;
        const zoom = 13;
        return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+555555(${lon},${lat})/${lon},${lat},${zoom},0/${x}x${y}?access_token=pk.eyJ1IjoiamFja2hlYXRvbiIsImEiOiJja253MDVqdnkwNnpsMnhvNnRjZjY2eHhvIn0.d-CVf2Y6PLVzoMKa0nj_-w`;
      }
      return null;
    },

    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.shareUrl);
        this.copyLinkText = "Link Copied";
      } catch {
        this.copyLinkText = "Unable to Copy Link";
      }
      setTimeout(() => {
        this.copyLinkText = "Copy Link";
      }, 2500);
    },
    getPhoneURI(num) {
      const pn = parsePhoneNumber(num, "US");

      return pn.getURI();
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  async beforeMount() {},
};
</script>

<style scoped>
.header {
  height: 0;
  overflow-y: visible;
  z-index: 20;
  padding: 0 20px;
  background-color: transparent;
  width: 100%;
}

.back-button {
  background-color: rgba(255, 255, 255, 0.5);
  height: 40px;
  width: 40px;
  font-size: 1.55em;
  line-height: 0;
}

.header > div {
  margin: 85px 0 0;
}

.hero {
  border-radius: 0 0 1em 1em;
}

.content {
  padding: 20px 20px 90px 20px;
  background-color: white;
}

.offers {
  font-size: 0.8em;
}

.logo-image {
  width: 6em;
  height: 6em;
  min-width: 6em;
  min-height: 6em;
  background-color: white;
}

@media (min-width: 768px) {
  .hero {
    border: none;
    border-radius: 0;
  }

  .content {
    padding: 20px;
    background-color: white;
  }

  .logo {
    margin-top: -2em;
  }

  .logo-image {
    width: 10em;
    height: 10em;
    min-width: 10em;
    min-height: 10em;
  }
}
</style>
