<template>
  <div class="outer">
    <div class="card-holder">
      <div
        class="inner"
        v-show="
          !cardType ||
          ![
            'visa',
            'american-express',
            'discover',
            'maestro',
            'mastercard',
            'diners-club',
            'jcb',
            'invalid',
          ].includes(cardType)
        "
      >
        <div class="blank-card" :class="{ 'text-light': light }">
          <i class="fa-duotone fa-credit-card-front"></i>
        </div>
      </div>
      <div class="inner" v-show="cardType === 'visa'">
        <img src="@/assets/payments/Visa-card-dark.png" />
      </div>
      <div class="inner" v-show="cardType === 'american-express'">
        <img src="@/assets/payments/AmericanExpress-dark.png" />
      </div>
      <div class="inner" v-show="cardType === 'discover'">
        <img src="@/assets/payments/Discover-card-dark.png" />
      </div>
      <div class="inner" v-show="cardType === 'maestro'">
        <img src="@/assets/payments/Maestro-card-dark.png" />
      </div>
      <div class="inner" v-show="cardType === 'mastercard'">
        <img src="@/assets/payments/MasterCard-dark.png" />
      </div>
      <div class="inner" v-show="cardType === 'diners-club'">
        <img src="@/assets/payments/DinersClub-dark.png" />
      </div>
      <div class="inner" v-show="cardType === 'jcb'">
        <img src="@/assets/payments/JCB-card-dark.png" />
      </div>
      <div class="inner" v-show="cardType === 'invalid'">
        <div class="blank-card invalid">
          <i class="fa-duotone fa-credit-card-front"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CCWidget",
  components: {},
  data() {
    return {};
  },
  props: {
    cardType: String,
    light: Boolean,
  },
  watch: {},
  computed: {
    cardTypeT() {
      console.log(this.cardType);
      return null;
    },
  },
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
.outer {
  overflow: hidden;
  border-radius: 8px;
}

.card-holder,
.card-holder .inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.card-holder .inner img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.card-holder .blank-card {
  transform: scaleX(1.2);
  display: inline-flex;
  height: 100%;
  width: 100%;
  color: var(--mx-dark);
  justify-content: center;
  align-items: center;
}

.card-holder .blank-card.invalid {
  color: var(--bs-danger);
}
</style>
