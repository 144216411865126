import short from "short-uuid";
import { log } from "../../utils/utils";

// initial state
const state = () => ({
  notifications: [],
});

// getters
const getters = {};

// actions
const actions = {
  async addError({ commit }, e) {
    log({
      m: "Encountered Error",
      e: e,
      partition: "error",
    });
    commit("addNotification", {
      title: "Error",
      color: "danger",
      icon: "fa-duotone fa-frown",
      body: e,
      autohide: false,
    });
  },
  addNotification({ commit }, n) {
    commit("addNotification", n);
  },
  updateNotification({ commit }, update) {
    commit("updateNotification", update);
  },
};

// mutations
const mutations = {
  addNotification(state, n) {
    if (!n.id) n.id = short.generate();
    state.notifications = [...state.notifications, n];
    console.log(state);
  },
  updateNotification(state, n) {
    state.notifications = state.notifications.map((s) => {
      if (n.id === s.id) {
        return n;
      }
      return s;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
