import { API, graphqlOperation } from "aws-amplify";

export async function getLocation({ city, state, zip, lon, lat }) {
  const res = await API.graphql({
    ...graphqlOperation(`query { 
			location: getLocation(

				${city ? `city: "${city}",` : ""}
				${state ? `state: "${state}",` : ""}
				${zip ? `zip: "${zip}",` : ""}
				${lon ? `lon: ${lon},` : ""}
				${lat ? `lat: ${lat},` : ""}
      ) {
						city
						state
						zip
						geo {
							lat
							lon
						}
					
			}

		 }`),
  });

  return res.data?.location;
}
