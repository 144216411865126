<template>
  <div class="step-container">
    <slot name="step1" v-if="step === 0" />
    <slot name="step2" v-if="step === 1" />
    <slot name="step3" v-if="step === 2" />
    <slot name="step4" v-if="step === 3" />
    <slot name="step5" v-if="step === 4" />
    <slot name="step6" v-if="step === 5" />
    <slot name="step7" v-if="step === 6" />
    <slot name="step8" v-if="step === 7" />
    <slot name="step9" v-if="step === 8" />
    <slot name="step10" v-if="step === 9" />
    <slot name="step11" v-if="step === 10" />
    <slot name="step12" v-if="step === 11" />
  </div>
  <div
    v-if="!noProgress"
    class="control-bar mt-4 d-flex align-items-center justify-content-center"
  >
    <div
      v-for="(p, i) in new Array(stepLength)"
      :key="i"
      class="d-flex align-items-center"
    >
      <div
        v-if="i > 0"
        :class="['connector', { done: i < step, disabled: i > step }]"
      ></div>
      <div
        :class="['point', { done: i < step, disabled: i > step }]"
        @click="$emit('goTo', i)"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";

//let test = "hello";

export default {
  name: "StepThrough",
  components: {},
  data() {
    return {};
  },
  props: {
    step: {
      type: Number,
      default: 0,
    },
    stepLength: {
      type: Number,
      default: 6,
    },
    noProgress: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.point {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 5px solid var(--mx-green);
  cursor: pointer;
}

.point.done {
  background-color: var(--mx-green);
}

.connector {
  height: 5px;
  width: 30px;
  background-color: var(--mx-green);
}

.disabled {
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  cursor: not-allowed;
  pointer-events: none;
}
</style>
