<template>
  <div id="contact-search">
    <div class="search-outer" v-show="!selectedContact">
      <div
        id="cancel-pane"
        v-show="searchActive && allowSearch"
        @click="handleCancelPaneClick"
      ></div>
      <QRScanner
        v-if="scannerOpen"
        @cancel="scannerOpen = false"
        @scanned-card="
          (c) => {
            scannerOpen = false;
            if (/^\d+$/.test(c)) {
              scannerOpen = false;
              searchValue = c;
              handleFieldSubmit();
            } else if (
              moxeyPayAllowed &&
              c.includes('http') &&
              c.includes('/mp/')
            ) {
              $nextTick(() => {
                navigateTo(c);
              });
            } else {
              searchValue = '';
              cardReadError = true;
            }
          }
        "
      />
      <input
        :type="allowSearch ? undefined : `text`"
        :pattern="allowSearch ? undefined : `[0-9]*`"
        :inputmode="allowSearch ? undefined : `numeric`"
        class="form-control"
        :class="{
          'is-invalid': invalid || cardReadError,
          'control-room': showControls && allowSearch,
          'dark-background': darkBackground,
        }"
        :id="contactSearchId"
        :name="contactSearchId"
        v-model="searchValue"
        :placeholder="placeholder"
        @focus="onFocus"
        aria-label="To"
        :disabled="disabled"
        @input="searchContacts"
        @blur="
          () => {
            if (/[0-9]/g.test(searchValue)) handleFieldSubmit();
          }
        "
        autocomplete="off"
      />

      <div id="close-btn">
        <div
          v-show="!searching && allowSearch && showControls"
          @click="handleCancelPaneClick"
        >
          <i class="fas fa-times icon-btn"></i>
        </div>
      </div>

      <div id="search-btn">
        <div
          v-show="!searching && allowSearch && showControls"
          @click="handleFieldSubmit"
        >
          <i class="fas fa-search icon-btn"></i>
        </div>
      </div>

      <div id="suggestion-wrapper" class="mx-0 d-flex justify-content-center">
        <transition name="drop-down">
          <div
            id="contact-suggestions"
            v-show="showSuggestions && allowSearch"
            class="card border-top-0"
          >
            <div class="flex-column">
              <!-- <div class="suggestion searching">
              <i class="fa fa-spin fa-sync"></i> Searching ...
            </div> -->
              <div
                v-for="(r, i) in searchResults"
                :key="i"
                class="suggestion"
                :class="i === searchResults.length - 1 && 'last'"
                @click="
                  (e) => {
                    selectContact(e, r);
                  }
                "
              >
                <contact-search-item
                  :r="r"
                  :active="i === activeItemIndex"
                  @item-hovered="activeItemIndex = i"
                />
              </div>
              <div
                v-show="searchResults.length === 0 && !cardReadError"
                class="no-results text-danger mt-4"
              >
                No matching members found
              </div>
              <div v-show="cardReadError" class="no-results text-danger mt-4">
                Invalid card number
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div class="text-center mt-4">
        <button
          v-if="hasCamera"
          class="btn btn-outline-success"
          type="button"
          id="button-addon1"
          @click="scannerOpen = true"
        >
          <i class="fas fa-qrcode"></i> Scan QR Code
        </button>
      </div>
    </div>
    <div v-show="cardReadError && !allowSearch" class="text-danger mt-4">
      <small>Invalid card number</small>
    </div>

    <div v-if="selectedContact" class="selected-contact">
      <div class="d-flex justify-content-between">
        <contact-search-item :r="selectedContact" />
        <div @click="handleCancelPaneClick" class="clear-contact text-danger">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { api } from "@/utils/utils";
import { unpackContactSearchResult } from "@/utils/search";
import ContactSearchItem from "./ContactSearchItem.vue";
import QRScanner from "./QRScanner.vue";
import QrScanner from "qr-scanner";

export default {
  name: "ContactSearch",
  components: {
    ContactSearchItem,
    QRScanner,
  },
  data() {
    return {
      searchValue: "",
      searchResults: [],
      searchDebounce: undefined,
      searchActive: false,
      searching: false,
      showSuggestions: false,

      selectedContact: undefined,
      cardReadError: false,
      activeItemIndex: undefined,

      showControls: false,

      hasCamera: false,
      scannerOpen: false,
    };
  },
  props: {
    contactSearchId: {
      type: String,
      default: "contactSearchId",
    },
    placeholder: {
      type: String,
      default: "Search by name or card number",
    },
    moxeyPayAllowed: Boolean,
    showQR: Boolean,
    contactCard: Number,
    disabled: Boolean,
    invalid: Boolean,
    clearSearch: Number,
    allowSearch: {
      type: Boolean,
      default: true,
    },
    gcOnly: {
      type: Boolean,
      default: false,
    },
    darkBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      swipedCard: (state) => state.app.all.swipedCard,
    }),
    exchange() {
      return (this.user && this.user.member.auth[0].auName) || 0;
    },
  },
  watch: {
    selectedContact(post) {
      const selected = { ...post };

      this.$nextTick(() => {
        const contactIcon =
          (document.getElementById(selected.id) &&
            document
              .getElementById(selected.id)
              .querySelector(".contact-icon img") &&
            document
              .getElementById(selected.id)
              .querySelector(".contact-icon img").src) ||
          undefined;

        const subIcon =
          (document.getElementById(selected.id) &&
            document
              .getElementById(selected.id)
              .querySelector(".sub-image img") &&
            document.getElementById(selected.id).querySelector(".sub-image img")
              .src) ||
          undefined;

        if (contactIcon && contactIcon.indexOf("no_img") === -1)
          selected.contactIcon = contactIcon;
        if (subIcon && subIcon.indexOf("no_img") === -1)
          selected.subIcon = subIcon;

        console.log(selected);
        this.$emit(
          "selectedContact",
          selected && selected.id
            ? {
                ...selected,
                searchValue: this.searchValue,
              }
            : undefined
        );
      });
    },
    clearSearch() {
      this.handleCancelPaneClick();
    },
    swipedCard() {
      this.handleSwipe();
    },
  },
  async mounted() {
    if (this.showQR) {
      this.hasCamera = await QrScanner.hasCamera(); // async
    }

    document
      .getElementById(this.contactSearchId)
      .addEventListener("keydown", (e) => {
        if (["ArrowDown", "ArrowUp"].includes(e.key)) {
          let doScroll = false;
          let direction = e.key === "ArrowUp" ? "up" : "down";
          if (
            direction === "down" &&
            (this.activeItemIndex || 0) < this.searchResults.length - 1
          ) {
            this.activeItemIndex =
              !this.activeItemIndex && this.activeItemIndex !== 0
                ? 0
                : this.activeItemIndex + 1;
            doScroll = true;
          }

          if (direction === "up" && (this.activeItemIndex || 0) > 0) {
            this.activeItemIndex = (this.activeItemIndex || 0) - 1;
            doScroll = true;
          }

          if (doScroll) {
            this.$nextTick(() => {
              const active = document.querySelector(".result-item.active");
              const top = active.clientHeight * this.activeItemIndex;

              if (top > active.clientHeight) {
                document.getElementById("contact-suggestions").scroll({
                  left: 0,
                  top: top - active.clientHeight - 40,
                  behavior: "smooth",
                });
              } else {
                document.getElementById("contact-suggestions").scroll({
                  left: 0,
                  top: 0,
                  behavior: "smooth",
                });
              }
            });
          }
        } else if (["Escape"].includes(e.key)) {
          this.handleCancelPaneClick();
        } else if (["Enter", "Tab"].includes(e.key)) {
          this.handleFieldSubmit();
        }
      });

    this.handleSwipe();
  },
  methods: {
    logit(e) {
      console.log("searchValue", e);
    },
    selectContact(e, r) {
      const selected = r;
      this.selectedContact = selected;
    },
    handleSwipe() {
      if (this.swipedCard) {
        this.searchValue = this.swipedCard;
        this.updateAppStore({ swipedCard: undefined });
        this.searchCard();
      }
    },
    onFocus() {
      this.cardReadError = undefined;
      this.searchValue = "";
      this.searchActive = true;
      this.$emit("didFocus");
    },
    handleCancelPaneClick() {
      this.$emit("searchActive", false);
      this.selectedContact = undefined;
      this.searchActive = false;
      this.searchValue = "";
      this.showSuggestions = false;
      this.activeItemIndex = undefined;
      this.selectedContact = undefined;
      this.showControls = false;
      document.getElementById(this.contactSearchId).blur();
    },
    clearForm() {
      this.sendToName = "";
      this.sendToCard = undefined;
      this.amount = 0;
      this.tip = 0;
      this.note = "";
      this.invalidFields = {};
      this.error = "";
    },
    searchContacts() {
      this.$emit("didUpdate", this.searchValue);
      this.showControls = false;
      this.cardReadError = false;
      this.activeItemIndex = undefined;
      this.searching = true;
      clearTimeout(this.searchDebounce);
      if (this.searchValue.length > 1) {
        if (/*!/[0-9]/g.test(this.searchValue) && */ this.allowSearch) {
          this.searchDebounce = setTimeout(async () => {
            const res = await this.doSearch();

            console.log(res.hits.hit);

            this.searchResults = unpackContactSearchResult(res);

            this.showSuggestions = true;

            this.searching = false;
          }, 250);
          // } else if (!this.allowSearch) {
          //   this.searchValue = this.searchValue.replace(/\D/g, "");
        } else {
          this.searchResults = [];
          this.showControls = true;
          this.showSuggestions = false;
          this.searching = false;
        }
      }
    },
    async handleFieldSubmit() {
      console.log(this.searchValue);
      this.$emit("didUpdate", this.searchValue);
      //Handle search by card
      if (/^(?=.*\d)[\d ]+$/.test(this.searchValue)) {
        this.searchCard();
      } else if (
        !/^(?=.*\d)[\d ]+$/.test(this.searchValue) &&
        !this.allowSearch
      ) {
        this.$emit("didError", "Invalid number");
      } else if (this.activeItemIndex || this.activeItemIndex === 0) {
        this.selectedContact = this.searchResults[this.activeItemIndex];
      }
    },
    async searchCard() {
      this.$emit("didUpdate", this.searchValue);
      this.$emit("isGiftCard", undefined);
      const checkString = [...this.searchValue.toString()].reverse().join("");
      if (!/^(?=.*\d)[\d ]+$/.test(this.searchValue) && !this.allowSearch) {
        this.$emit("didError", "Invalid number");
      } else if (
        (checkString.substring(0, 2) === "37" && !this.allowSearch) ||
        this.gcOnly
      ) {
        this.$emit("isGiftCard", this.searchValue);
      } else {
        const res = unpackContactSearchResult(await this.doSearch(true)).filter(
          (c) => c.id.substring(0, 1) === "c"
        );
        if (res.length >= 1) {
          this.selectedContact = res[0];
        } else {
          this.cardReadError = true;
          this.showSuggestions = true;
        }
      }
    },
    async doSearch(byCard) {
      return await api(
        "get",
        "search",
        `/search/contacts?query=${encodeURIComponent(
          this.searchValue
        )}&exchange=${
          this.exchange
        }&showHiddenDir=true&showHiddenPay=false&size=20${
          byCard ? "&byCard=true" : ""
        }`
      );
    },
    navigateTo(url) {
      const urlParts = url.split("/").reverse();
      this.$router.push(`/mp/${urlParts[0]}`);
      this.updateAppStore({
        showLeftBar: false,
      });
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
      logout: "app/logout",
    }),
  },
  beforeMount() {},
  activated() {},
};
</script>

<style scoped>
#contact-search {
  width: 100%;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  z-index: 52;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .form-control {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  font-style: italic;
  position: relative;
  z-index: 51 !important;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  z-index: 51;
}

.control-room {
  padding: 21px 40px;
}

.search-outer {
  position: relative;
  width: 100%;
}

#suggestion-wrapper {
  position: relative;
  z-index: 50;
  height: auto;
  transition: height 0.5s;
}

.card-body {
  padding: 20px 20px 0;
}

#contact-suggestions {
  font-size: 18px;
  line-height: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  /* position: absolute; */
  z-index: 2;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 40px 20px 0;
  top: -27px;
  max-height: 500px;
  overflow-y: scroll;
}

.suggestion {
  width: 100%;
}

.suggestion.last {
  margin-bottom: 20px;
}

.no-results {
  margin: 20px 0 40px;
}

.drop-down-enter-active,
.drop-down-leave-active {
  overflow: hidden;
  transition: max-height 0.25s ease;
}

.drop-down-enter-from,
.drop-down-leave-to {
  overflow: hidden;
  max-height: 0 !important;
}

#cancel-pane {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 25;
  backdrop-filter: blur(0.5px);
}

.clear-contact {
  font-size: 24px;
  cursor: pointer;
  margin: 0 0 0 15px;
}

#close-btn,
#search-btn {
  position: absolute;
  top: 0;
  line-height: 66px;
  z-index: 53;
  cursor: pointer;
  padding: 0 0 0 15px;
}

#close-btn {
  right: 0;
  padding: 0 15px 0 0;
}

.btn-outline-success {
  color: var(--mx-green);
  border-color: var(--mx-green);
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active,
.btn-outline-success:hover {
  color: var(--mx-white);
  background-color: var(--mx-green);
}

.dark-background {
  color: var(--mx-white);
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: transparent;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}
</style>
