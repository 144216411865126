import ls from "local-storage";
import config from "../../config/awsconfig";
import { Amplify, Auth } from 'aws-amplify';
import { log, api, gaSet, userToOneSignal } from "../../utils/utils";
import dayjs from "dayjs";
import testAccount from "../../utils/testAccount.js";

// initial state
const state = () => ({
  all: {
    isNativeClient: false,
    showTopNav: false,
    showTopHero: false,
    userLocation: false,
    viewParams: {},

    ogMeta: {},
    barsLocked: false,
    cyclingBars: false,
    showLeftBar: false,
    leftBarComp: undefined,
    showRightBar: false,
    leftBarPayload: undefined,

    loggingIn: false,
    refreshing: false,
    user: undefined,
    appConfig: undefined,
    promptReload: false,
    loginMessage: undefined,
    userIcon: undefined,
    memberIcon: undefined,

    mapIsActive: false,
    topIsHidden: false,

    swipedCard: undefined,
    lastConfig: 0,

    isEstablished: false,
    gotUserPool: false,
    refreshInterval: undefined,

    showWalkthroughModal: false,
    showNPS: false,

    moxeyPayPOSCode: undefined,
    moxeyPayContactId: undefined,
    doNotVerify: true,
    refreshLocation: undefined,
    testMode: false,
  },
});

// getters
const getters = {};

// actions
const actions = {
  async logout({ commit, state }, options) {
    //clear out locally
    commit("update", {
      user: undefined,
      userIcon: undefined,
      userLocation: undefined,
      testMode: false,
      leftBarComp: options && options.showLogin && "Login",
      loginMessage: options && options.loginMessage,
    });
    //send message to server to invalidate cookie
    try {
      await api("post", "core", `/v2/logout`, {});
      clearInterval(state.all.refreshInterval);
    } catch (e) {
      console.log(e);
    }

    ls.remove("accessToken");

    log({
      m: "User logged out.",
    });
  },
  async updateAppStore({ commit }, update) {
    commit("update", update);
  },
  async refreshUser({ commit, dispatch, state }) {
    if (state.all.testMode) return;

    commit("update", {
      refreshing: true,
    });

    try {
      const user = await api("get", "core", `/refresh`, {});
      const isAdmin =
        Object.keys(user.rights).reduce((c, k) => {
          if (k.split("|||")[1] === "Admin") {
            if (user.rights[k]) c.push(user.rights[k]);
          }
          return c;
        }, []).length > 0;

      const isElevated =
        Object.keys(user.rights).reduce((c, k) => {
          if (user.rights[k]) c.push(user.rights[k]);
          return c;
        }, []).length > 0;

      commit("update", {
        user: { ...user, isAdmin: isAdmin, isElevated: isElevated },
      });
    } catch (e) {
      if (e.statusCode === 401) {
        dispatch("logout", {
          showLogin: true,
          loginMessage: e.error,
        });
      } else {
        console.log(e);
      }
    } finally {
      commit("update", {
        refreshing: false,
      });
    }
  },
  //Get app config.
  async getConfig({ commit }) {
    try {
      commit("update", {
        lastConfig: Date.now(),
      });
      const config = await api("get", "core", `/util/config`);

      const localVersion = ls.get("localVersion");

      const serverVersion =
        config.branches &&
        config.branches[process.env.VUE_APP_BRANCH_NAME] &&
        config.branches[process.env.VUE_APP_BRANCH_NAME].buildId;

      // const moxeyPayPOSCode = ls.get("moxeyPayPOSCode");
      // const moxeyPayContactId = ls.get("moxeyPayContactId");

      commit("update", {
        appConfig: config,
        // moxeyPayPOSCode,
        // moxeyPayContactId,
      });

      if (!localVersion || !serverVersion || localVersion === serverVersion) {
        ls.set("localVersion", serverVersion);
        //ls.remove("savedArea");
      } else if (localVersion !== serverVersion) {
        ls.remove("localVersion");
        //ls.remove("savedArea");
        //commit("update", { promptReload: true });
      }
    } catch (e) {
      console.log(e);
    }
  },

  async loginUser({ commit, dispatch }) {
    const token = ls.get("accessToken");

    const doWalkthrough =
      window?.location?.pathname !== "/"
        ? true
        : ls.get("showWalkthroughModal");

    if (token && token !== "undefined") {
      commit("update", { loggingIn: true, loginMessage: undefined });

      if (token === "testmode=testmode") {
        commit("update", { testMode: true });
      }

      try {
        const login =
          token === "testmode=testmode"
            ? { user: testAccount }
            : await api("post", "core", `/v2/login`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });

        ls.set(
          "accessToken",
          token === "testmode=testmode"
            ? "testmode=testmode"
            : login.accessToken
        );

        const isAdmin =
          Object.keys(login.user.rights).reduce((c, k) => {
            if (k.split("|||")[1] === "Admin") {
              if (login.user.rights[k]) c.push(login.user.rights[k]);
            }
            return c;
          }, []).length > 0;

        const isElevated =
          Object.keys(login.user.rights).reduce((c, k) => {
            if (login.user.rights[k]) c.push(login.user.rights[k]);
            return c;
          }, []).length > 0;

        if (
          (!login.user.nextNPSDate || login.user.nextNPSDate < Date.now()) &&
          dayjs().isAfter(
            dayjs(login.user.member.public.joinDate).add(30, "day")
          )
        )
          commit("update", { showNPS: true });

        commit("update", {
          user: { ...login.user, isAdmin: isAdmin, isElevated: isElevated },
        });

        gaSet({ user_id: login.user.SK1 });

        log({
          m: "Logged in using saved session.",
        });

        userToOneSignal(login.user);
      } catch (e) {
        //If this fails, clear out the local session
        dispatch("logout", {
          showLogin: true,
          loginMessage: e.error,
        });
      } finally {
        commit("update", { loggingIn: false });
      }
    }

    if (!doWalkthrough || doWalkthrough === null) {
      commit("update", { showWalkthroughModal: true });
    }
    return;
  },

  //This runs on app start, on navigation and then once a minute to keep app and user in sync.
  async updateStateOnLoad({ dispatch, state, commit }) {
    try {
      //Do stuff on first load
      if (!state.all.isEstablished) {
        Amplify.configure(config);
        ls.remove("geoAvailable");
        ls.remove("geoBlocked");

        const cookies = document.cookie.split(" ").reduce((col, c) => {
          const ic = c.split("=");
          col[ic[0]] = decodeURI(ic[1]);
          return col;
        }, {});
        log({ m: "Started session.", co: cookies });

        await Promise.all([
          dispatch("loginUser"),
          dispatch("getConfig"),
          dispatch("search/preloadData", null, { root: true }),
        ]);

        commit("update", {
          refreshInterval: setInterval(async () => {
            if (!state.all.user) {
              await dispatch("loginUser");
            }
            dispatch("getConfig");
            dispatch("search/preloadData", null, { root: true });
            if (state.all.user) dispatch("refreshUser");
          }, 120000),
        });


        commit("update", {
          isEstablished: true,
        });
      }
    } catch (e) {
      console.log(e)
      location.reload();
    }
  },
};

// mutations
const mutations = {
  update(state, update) {
    state.all = { ...state.all, ...update };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
