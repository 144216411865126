import short from "short-uuid";
import ls from "local-storage";
import store from "../store/index";
import { API } from "aws-amplify";
import { protoSearch } from "./search";
import { distanceTo } from "geolocation-utils";
import { useGtag } from "vue-gtag-next";

const session = short.generate();

const station = ls.get("station") || short.generate();

ls.set("station", station);

export function hideAppOverflow(hidden) {
  const styles = hidden
    ? {
        overflow: "hidden",
      }
    : {
        overflow: "auto",
      };
  document.body.style.overflow = styles.overflow;
}

export function dataToGeoJSON(data, color) {
  const first = data[0].fields.location.reduce((col, l) => {
    const c = l.split(",");
    col.lat = parseFloat(c[0]);
    col.lon = parseFloat(c[1]);
    return col;
  }, {});

  const dataset = {
    id: "results",
    dataset: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: data.reduce((col, d, i) => {
          d = {
            id: d.id,
            ...d.fields,
            ...d.exprs,
          };
          if (d.location) {
            const coords = d.location.reduce((col, l) => {
              const c = l.split(",");
              col.lat = parseFloat(c[0]);
              col.lng = parseFloat(c[1]);
              return col;
            }, {});
            col.push({
              // feature for Mapbox DC
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [coords.lng, coords.lat],
              },
              properties: {
                id: d.id,
                title: d.list_name[0],
                city: d.city && d.city[0],
                state: d.state && d.state[0],
                description: d.desc && d.desc[0],
                distance: parseFloat(d.distance),
                distanceFromFirst: distanceTo(coords, first) * 0.000621,
                image: d.product_icons && d.product_icons[0],
                picture: d.image_url && d.image_url[0],
                icon: (d.fa_icon && d.fa_icon[0]) || "fa-mx-sign-fat",
                color: color,
                sortKey: i,
                scale: 0.3,
                radius: 15,
                rangeBand: d.range_band && d.range_band[0],
                ts: d.update_ts && d.update_ts[0],
                found: "No",
                coordinates: [coords.lng, coords.lat],
              },
            });
          }
          return col;
        }, []),
      },
    },
  };

  return dataset;
}

export async function log(log) {
  try {
    await api("post", "core", `/logactivity/`, {
      body: {
        station: station,
        session: session,
        user:
          (store.state.app.all.user && store.state.app.all.user.username) ||
          "ANON_GUEST",
        mId:
          (store.state.app.all.user &&
            store.state.app.all.user.member.memberId) ||
          "NON_MEMBER",
        ua: window.navigator.userAgent,
        ts: Date.now(),
        ...log,
      },
    });
  } catch (e) {
    console.log(e);
  }
}

export function unpackAddress(a) {
  try {
    return a.reduce((col, c) => {
      if (c.types.includes("neighborhood")) {
        col["neighborhood"] = c.short_name;
      } else if (c.types.includes("locality")) {
        col["city"] = c.short_name;
      } else if (c.types.includes("administrative_area_level_1")) {
        col["state"] = c.short_name;
      } else if (c.types.includes("country")) {
        col["country"] = c.short_name;
      } else if (c.types.includes("postal_code")) {
        col["postal_code"] = c.short_name;
      }
      return col;
    }, {});
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function getProductTypes() {
  const pt = (
    await protoSearch({
      query: "*",
      fields: ["business_types", "product_types", "desc", "range_band"],
      filter: `( and type:'business type' (not product_types:'PERSONAL') (not product_types:'TRADE EXCHANGE'))`,
      size: 5000,
    })
  ).hits.hit.reduce((col, p) => {
    const f = p.fields;

    col[f.product_types[0]] = {
      ...col[f.product_types[0]],
      name: f.product_types[0],
      order: parseInt(f.desc[2]),
      id: parseInt(f.desc[1]),
      business_types: [
        {
          id: parseInt(f.desc[0]),
          name: f.business_types[0],
          band: parseInt(f.range_band && f.range_band[0]),
          product_type: f.product_types[0],
          product_type_id: parseInt(f.desc[1]),
        },
        ...((col[f.product_types[0]] &&
          col[f.product_types[0]].business_types) ||
          []),
      ],
    };
    return col;
  }, {});

  const pts = Object.keys(pt)
    .reduce((col, p) => {
      col.push(pt[p]);
      return col;
    }, [])
    .sort((a, b) => a.order - b.order);

  return pts;
}

export async function getRegions() {
  const rg = (
    await protoSearch({
      query: "*",
      fields: ["auth", "region"],
      filter: `(and type:'auth' (not product_types:'PERSONAL') (not product_types:'TRADE EXCHANGE'))`,
      size: 10000,
    })
  ).hits.hit.reduce((col, a) => {
    const f = a.fields;

    col[f.region[0]] = {
      ...col[f.region[0]],
      name: f.region[0],
      exchanges: [
        {
          name: f.auth[0],
        },
        ...((col[f.region[0]] && col[f.region[0]].exchanges) || []),
      ],
    };
    return col;
  }, {});

  const regs = Object.keys(rg).reduce((col, r) => {
    col.push(rg[r]);
    return col;
  }, []);

  return regs;
}

export async function api(method, apiName, path, init) {
  try {
    return await API[method](apiName, path, init);
  } catch (e) {
    console.log(e);
    if (e.response) {
      const er = e.response.data;
      if (er.statusCode === 401) {
        console.log(er);

        store.dispatch("app/logout", {
          showLogin: true,
          loginMessage:
            er?.error ||
            "Your user session is no longer valid. Please log back in.",
        });
      }
      throw e.response.data;
    } else if (e.request) {
      throw { error: "No response from API." };
    } else {
      throw e;
    }
  }
}

export function gaEvent(action, category, label) {
  const { event } = useGtag();
  event(action, {
    event_category: category,
    event_label: label,
  });
}

export function gaTiming(name, value, category) {
  const { time } = useGtag();
  time({
    name: name,
    value: value,
    event_category: category || "User Experience",
  });
}

export function gaError(at, err, fatal) {
  const { exception } = useGtag();
  exception({
    description: { at: at, err: err },
    fatal: fatal,
  });
}

export function gaSet(setting) {
  const { set } = useGtag();
  set(setting);
}

export function gaConfig(setting) {
  const { config } = useGtag();
  config(setting);
}

export function userToOneSignal(user) {
  if (!user) return;
  try {
    const btn = document.createElement("button");
    btn.setAttribute(
      "onclick",
      `try { nativeFunctions.onesignalSetExternalUserId('${user.SK1}');this.setAttribute('native', true); } catch {}`
    );
    btn.addEventListener("click", (e) => {
      if (e.target.getAttribute("native") === "true") {
        store.commit("app/update", {
          isNativeClient: true,
        });
      } else {
        store.commit("app/update", {
          isNativeClient: false,
        });
      }
    });
    btn.click();
    btn.delete;

    const btn2 = document.createElement("button");
    btn2.setAttribute(
      "onclick",
      `try { nativeFunctions.onesignalSendTags({
        name: '${user.contact.fName} ${user.contact.lname} - ${user.member.name}',
        exchange: '${user.member.auth[0].auName}'
      }); } catch { }`
    );
    btn2.click();
    btn2.delete;
  } catch {
    console.log("Can't find native library, safe to ignore");
  }
}
