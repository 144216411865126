<template>
  <div class="position-relative">
    <form @submit.prevent="$emit(`barSubmit`, value)">
      <input
        id="search"
        name="search"
        class="form-control rounded-pill ps-3"
        type="text"
        v-model="value"
        aria-describedby="button-addon2"
        autocomplete="off"
        :placeholder="`Search Moxey for ${placeholder} ...`"
        :disabled="disabled"
        @focus="(e) => $emit('focus', e)"
        @blur="(e) => $emit('blur', e)"
      />
      <div
        class="position-absolute d-flex fs-6 align-items-center"
        :style="{ right: '15px', height: 0, top: '50%' }"
      >
        <div @click="$emit(`barSubmit`, value)">
          <i class="fas fa-search"></i>
        </div>
        <div class="ms-3" @click="value = ''">
          <i class="fas fa-xmark-large"></i>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  components: {},
  data() {
    return {
      value: this.search,
      previousLocation: undefined,
      placeholder: "anything",
      placeholders: [
        "a burger",
        "a dentist",
        "sushi",
        "auto repair",
        "lunch",
        "dinner",
        "vacations",
        "a barber",
        "anything",
        "everything",
        "gifts",
        "pest control",
        "what you need",
        "cash savings",
        "new customers",
      ],
      placeholderInterval: undefined,
      typeDebounce: undefined,
    };
  },
  computed: {},
  props: {
    disabled: Boolean,
    search: String,
    clear: Number,
  },
  mounted() {
    this.$nextTick(() => {
      window.prerenderReady = true;
    });
  },
  methods: {},
  beforeMount() {
    setInterval(() => {
      this.placeholder =
        this.placeholders[Math.floor(Math.random() * this.placeholders.length)];
    }, 2000);
  },
  beforeUnmount() {
    clearInterval(this.placeholderInterval);
  },
  watch: {
    value(post) {
      clearTimeout(this.typeDebounce);

      this.typeDebounce = setTimeout(() => {
        this.$emit("update", post);
      }, 350);
    },
    clear() {
      this.value = "";
    },
  },
  async activated() {},
};
</script>

<style scoped>
input {
  font-style: italic;
}
</style>
