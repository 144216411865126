<template>
  <div
    class="top-nav d-flex justify-content-between align-items-center"
    :class="{ thin: thin }"
  >
    <div
      class="text-light d-flex justify-content-center align-items-center back-btn"
    >
      <span
        v-if="$route.path !== '/' && backLink"
        @click="navigate"
        class="nav-icon"
      >
        <i class="fa-solid fa-chevrons-left"></i>
      </span>
      <img
        src="@/assets/moxey_dark_bg.svg"
        class="logo"
        @click="
          () => {
            $emit('clickedHome');
            $router.push('/');
          }
        "
      />
    </div>

    <div
      v-if="mobileTitle"
      class="d-block mobile-header"
      :class="[mobileTitleClass, { 'd-xl-none': !showMobileTitle }]"
    >
      <small>{{ mobileTitle }}</small>
    </div>

    <div
      v-if="hamburger"
      class="text-light d-flex align-items-center user-status-icons"
    >
      <div v-show="user && user.contact.fName">
        <div
          class="user-status-icons d-flex justify-content-end align-items-center"
        >
          <div
            class="note-icon nav-icon"
            v-if="user?.contact?.pendingNotifications > 0"
            @click="$router.push('/notifications')"
          >
            <i class="fas fa-envelope"></i>
            <div class="new-pip"></div>
          </div>
          <div class="sub-image nav-icon" @click="showLeftBar">
            <img v-show="app.userIcon" :src="app.userIcon" />
            <div v-show="!app.userIcon" class="header-like person-icon">
              {{
                user &&
                user.contact.fName.replace(/[^0-9a-z]/gi, "").substring(0, 1)
              }}
            </div>
          </div>
          <!-- <div class="user-name">
            {{ user && user.contact.fName }}
          </div> -->
        </div>
      </div>
      <div v-show="!user && loggingIn" class="user-name nav-icon">
        <i class="fas fa-sync fa-spin"></i
        ><!-- Logging in-->
      </div>
      <!-- <div v-show="!user && !loggingIn" class="user-name">Signed out</div> -->
      <div @click="showLeftBar" class="nav-icon">
        <i class="fa-solid fa-bars"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { log } from "@/utils/utils";

export default {
  name: "TopNav",
  data() {
    return {};
  },
  props: {
    backLink: Object,
    thin: Boolean,
    hamburger: {
      type: Boolean,
      default: true,
    },
    mobileTitle: String,
    mobileTitleClass: String,
    showMobileTitle: Boolean,
    clickBars: Number,
  },
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      appConfig: (state) => state.app.all.appConfig,
      loggingIn: (state) => state.app.all.loggingIn,
      app: (state) => state.app.all,
      searchBarText: (state) => state.search.all.searchBarText,
    }),
  },
  created() {
    this.routes = [...this.$router.options.routes]
      .filter((r) => r.order)
      .sort((a, b) => a.order - b.order);
  },
  methods: {
    async showLeftBar() {
      const userTNC = this.user && this.user.tncVersion;
      const appTNC = this.appConfig && this.appConfig.tncVersion;

      this.updateAppStore({
        leftBarComp:
          (this.user && !userTNC && appTNC) || userTNC < appTNC
            ? "TNC"
            : "Account",
      });

      log({
        m: "Opened account bar.",
      });
    },
    async showImNew() {
      this.updateAppStore({
        leftBarComp: "Register",
      });

      log({
        m: "Opened account bar.",
      });
    },
    ...mapActions({
      updateSearchStore: "search/updateAppStore",
      updateAppStore: "app/updateAppStore",
    }),
    navigate() {
      if (this.backLink.path === "/")
        this.updateSearchStore({
          searchBarText: "",
        });
      this.$router.push(this.backLink);
    },
  },
  watch: {
    clickBars(post) {
      if (post) {
        this.showLeftBar();
      }
    },
  },
  mounted() {
    if (this.$route.query.showMenu) {
      this.showLeftBar();
    }
    if (this.$route.query.firstLogin) {
      this.showImNew();
    }
  },
};
</script>

<style scoped>
#icon-holder {
  background-color: var(--mx-blue);
  padding: 0 0.5rem;
  height: 1.1rem;
}

img {
  height: 0.5rem;
}

.top-nav {
  width: 100%;
  z-index: 50;
  font-size: 24px;
  padding: 20px 20px;
  background-color: var(--mx-dark);
  /*
  background: linear-gradient(
    0deg,
    rgba(11, 64, 2, 0) 0%,
    rgba(11, 64, 2, 0.3) 48%,
    rgba(11, 64, 2, 0.3) 100%
  );
  */
}

.top-nav.thin {
  padding: 10px 20px;
}

.nav-icon {
  cursor: pointer;
}

.user-name {
  margin: 0 8px 0 0;
  font-size: 18px;
  line-height: 24px;
}

.back-btn {
  margin: 0 8px 0 0;
}

.sub-image div {
  line-height: 30px;
}

.person-icon {
  background-color: var(--mx-blue);
  color: var(--mx-dark);
  text-align: center;
  font-size: 12px;
  font-weight: 800px;
}

.sub-image {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #a6a6a630;
  position: relative;
}

.sub-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mobile-header {
  font-weight: 300;
  white-space: nowrap;
  max-width: 150px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note-icon {
  margin: 0 8px 0 0;
  position: relative;
}

.new-pip {
  background-color: var(--bs-danger);
  height: 10px;
  width: 10px;
  border-radius: 10px;
  position: absolute;
  top: 2px;
  right: -5px;
}

.logo {
  height: 1.1rem;
  margin-left: 5%;
}

@media (min-width: 375px) {
  .mobile-header {
    max-width: 200px;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .mobile-header {
    max-width: 400px;
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .logo {
    height: 2rem;
  }
}
</style>
