<template>
  <div class="account account-header">
    <div class="d-flex justify-content-between">
      <profile-img />

      <div class="close-btn">
        <div @click="$emit('closeClicked')">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between mt-3 quick-buttons">
    <mx-button
      btnClass="btn btn-outline-light btn-outline-light-custom"
      @click="$emit('showVC')"
      :height="24"
      :padX="20"
      ><i class="fas fa-credit-card" /> My Moxey Card
    </mx-button>

    <div class="dropdown">
      <mx-button
        btnClass="btn btn-outline-light btn-outline-light-custom"
        @click="removed = false"
        :height="26"
        :padX="20"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
      >
        <i class="fa-solid fa-message" /> Contact My Exchange
      </mx-button>

      <ul
        class="dropdown-menu dropdown-menu-end my-2 p-3"
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <div class="fw-semibold">{{ user.profile.exchangeName }}</div>
          <div class="mt-1">
            <i class="fas fa-phone fa-fw" />
            <a
              :href="`tel:${user.profile.exchangePhone?.replace(/-/g, '')}`"
              class="ms-1"
            >
              {{ formatPhoneNumber(user.profile.exchangePhone) }}
            </a>
          </div>
          <div class="mt-1">
            <i class="fas fa-envelope fa-fw" />
            <a class="ms-1" :href="`mailto:${user.profile.exchangeEmail}`">{{
              user.profile.exchangeEmail
            }}</a>
          </div>
          <div class="mt-1" v-if="user.profile.exchangeWebsite">
            <i class="fas fa-globe fa-fw" />
            <a
              class="ms-1"
              :href="formatExternalLink(user.profile.exchangeWebsite)"
              >{{ user.profile.exchangeWebsite }}</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import config from "@/config/awsconfig";
import { mapState, mapActions } from "vuex";
import ProfileImg from "@/components/CoreApp/User/Shared/ProfileImg";
import MxButton from "../../../Shared/MxButton.vue";
import { parsePhoneNumber } from "libphonenumber-js";

export default {
  name: "AccountHeader",
  components: {
    ProfileImg,
    MxButton,
  },
  props: {},
  data() {
    return {
      mainImageError: false,
      subImageError: 0,
      subImageLoading: true,
      showVC: false,
    };
  },
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loggingIn: (state) => state.app.all.loggingIn,
      refreshing: (state) => state.app.all.refreshing,
    }),
    avatar() {
      return `${config.cloudFrontPrefix}/v2/users/${this.user.contact.private.userid}/avatars/avatar.jpg?ts=${this.user.updateTimestamp}`;
    },
    icon_v2() {
      return `${config.cloudFrontPrefix}/v2/images/products/${this.user.member.memberId}/icon.jpg?ts=${this.user.member.updateTimestamp}`;
    },
    noProduct() {
      return !this.user.member.products;
    },
    icon() {
      const icons =
        this.user.member.products &&
        this.user.member.products.map(
          (p) => `/products/${p.productId}/icon_${p.productId}.${p.iconExt}`
        );
      return icons && icons[0] && `${config.cloudFrontPrefix}${icons[0]}`;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    ...mapActions("app", ["refreshUser"]),
    formatExternalLink(link) {
      if (!link) return;

      return link.indexOf("http://") === 0 || link.indexOf("https://") === 0
        ? link
        : `https://${link}`;
    },
    formatPhoneNumber(num) {
      try {
        const pn = parsePhoneNumber(num, "US");

        return pn.formatNational();
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  },
  beforeMount() {
    this.subImageError = 0;
  },
};
</script>

<style scoped>
.account {
  width: 100%;
}

.icons {
  position: relative;
}

.biz-icon {
  background-color: var(--mx-dark);
  color: var(--mx-white);
}

.person-icon {
  background-color: var(--mx-blue);
  color: var(--mx-dark);
}

.contact-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin: 0 20px 0 0;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid #a6a6a630;
}

.contact-icon div,
.sub-image div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.contact-icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sub-image div {
  font-size: 12px;
  line-height: 30px;
}

.contact-icon img,
.sub-image img {
  height: 100%;
}

.sub-image {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 15px;
  margin: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #a6a6a630;
  position: absolute;
  right: 0;
  top: 40px;
}

.name {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.sub-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 11px;
}

.close-btn div {
  cursor: pointer;
}

.divider {
  width: 100%;
  background-color: var(--mx-white);
  height: 1px;
  margin: 30px 0;
}

.buying-power {
  font-size: 24px;
  font-weight: 400;
}

.bp-amt {
  margin: 5px 0 10px;
  font-size: 42px;
  line-height: 42px;
}

.bp-amt .cents {
  font-size: 18px;
  line-height: 30px;
  padding: 0 0 0 8px;
}

.whats-this {
  font-size: 18px;
  cursor: pointer;
}

.cra-outer {
  margin: 15px 0 0 0;
}

.cra {
  font-size: 18px;
  font-weight: 400;
}

.cra-amt {
  margin: 5px 0 10px;
  font-size: 30px;
  line-height: 30px;
}

.cra-amt .cents {
  font-size: 18px;
  line-height: 22px;
  padding: 0 0 0 4px;
}

.mx-alert:not(:first-child) {
  margin: 15px 0 0;
}

.alert-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-text {
  font-size: 17px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-icon {
  font-size: 50px;
}

.mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.refresh-button {
  margin: 15px 0 0;
  text-align: center;
}

.refresh-button .btn {
  font-size: 14px;
  line-height: 17px;
  padding: 10px 20px;
  width: initial;
  border-radius: 20px;
}

.gem .letters {
  bottom: 0px;
  font-size: 0.95rem;
  color: darkgoldenrod;
}

.dropdown-menu {
  width: calc(100vw - 60px);
  max-width: 412px;
}

.quick-buttons {
  position: relative;
  z-index: 1090;
}
</style>
