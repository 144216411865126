<template>
  <div id="new-cra-load">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="page-icon">
            <span class="fa-layers fa-fw">
              <i
                class="fa-light fa-arrow-rotate-right"
                data-fa-transform="grow-2 rotate-90"
              ></i>
              <i
                class="fa-solid fa-dollar-sign"
                data-fa-transform="shrink-8"
              ></i>
            </span>
          </div>
          <div class="header-like flex-fill">&nbsp;Load Cash Reserve</div>
        </div>
        <div class="close-btn">
          <div @click="closePane">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <form
        @submit.prevent
        @keypress.enter.prevent
        class="form-floating"
        v-if="user"
        id="ccForm"
        v-show="!receipt"
      >
        <input type="text" class="d-none" autofocus="true" id="dummy" />
        <div class="section-title">
          Amount<span class="text-muted">($50 recommended)</span>
        </div>
        <div class="input-group">
          <span class="input-group-text"
            ><i class="fas fa-dollar-sign"></i
          ></span>
          <input
            type="number"
            class="form-control"
            inputmode="decimal"
            :class="{ 'is-invalid': invalidFields.amount }"
            id="amount"
            step="0.01"
            name="amount"
            v-model="amount"
            placeholder="Amount"
            @scroll="
              (e) => {
                e.preventDefault();
              }
            "
            @keydown="
              (e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                  e.preventDefault();
              }
            "
            @change="validateAmount"
            @blur="validateAmount"
            @focus="
              () => {
                amount = undefined;
                clearError('amount');
              }
            "
            aria-label="CRA Reload Amount"
            :disabled="processing"
          />
        </div>
        <div class="text-danger mt-2" v-show="invalidFields.amount">
          <small>{{ invalidFields.amount }}</small>
        </div>

        <div
          v-show="!oldCRA && wallet.length > 0"
          class="text-center select-payment-type"
        >
          <div class="btn-group" role="group" aria-label="Payment type toggle">
            <input
              type="radio"
              class="btn-check"
              name="wallet"
              id="wallet"
              autocomplete="off"
              v-model="paymentType"
              value="wallet"
            />
            <label class="btn btn-outline-dark" for="wallet">Wallet</label>

            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="cc"
              autocomplete="off"
              v-model="paymentType"
              value="cc"
            />
            <label class="btn btn-outline-dark" for="cc">Payment Card</label>
          </div>
        </div>

        <div v-show="paymentType === 'wallet'" class="payment-type">
          <div class="section-title">Select a Saved Payment Method</div>

          <wallet
            :processing="processing"
            @selectedCard="
              (e) => {
                ({ selectedWalletItem, selectedCardType, selectedCardType } =
                  e);
                savedCard = e.card;
              }
            "
            @resetPaymentType="paymentType = 'cc'"
          />

          <!-- <div
            v-for="(w, i) in wallet"
            :key="i"
            class="wallet-item"
            :class="{
              'is-invalid': w.expired,
              selected: selectedWalletItem === w.customerVaultId,
            }"
            @click="
              () => {
                if (!w.expired && !processing) {
                  selectedWalletItem = w.customerVaultId;
                  selectedCardType = w.cardType;
                  selectedCardTypeNice = w.cardTypeNice;
                }
              }
            "
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="wallet-select">
                <input
                  class="form-check-input"
                  type="radio"
                  :name="w.customerVaultId"
                  :id="w.customerVaultId"
                  :value="w.customerVaultId"
                  :disabled="w.expired || processing"
                  v-model="selectedWalletItem"
                />
              </div>
              <div
                class="flex-fill wallet-item-inner d-flex justify-content-start align-items-center"
              >
                <div>
                  <CCWidget :cardType="w.cardType" class="card-widget" />
                </div>
                <div>
                  {{ w.cardTypeNice }}
                  ending in
                  {{ [...w.ccNumber].reverse().slice(0, 4).reverse().join("") }}
                </div>
              </div>

              <div>
                <button
                  class="btn btn-danger"
                  @click.stop="deletePaymentMethod(w.customerVaultId)"
                  :disabled="processing || deletingPaymentMethod"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="text-danger wallet-warn" v-show="w.expired">
              This payment method is expired.
            </div>
            <div class="text-warning wallet-warn" v-show="w.declined">
              This payment method was previously declined.
            </div>
          </div> -->
        </div>

        <div v-show="paymentType === 'cc'" class="payment-type">
          <div class="section-title">Payment Details</div>
          <input
            type="text"
            id="first_name"
            class="form-control"
            :class="{ 'is-invalid': invalidFields.first_name }"
            v-model="first_name"
            @focus="clearError('first_name')"
            placeholder="First Name on Card"
            :disabled="processing"
          />
          <div class="text-danger" v-show="invalidFields.first_name">
            <small>First name is required</small>
          </div>
          <input
            type="text"
            id="last_name"
            class="form-control"
            :class="{ 'is-invalid': invalidFields.last_name }"
            v-model="last_name"
            @focus="clearError('last_name')"
            placeholder="Last Name on Card"
            :disabled="processing"
          />
          <div class="text-danger" v-show="invalidFields.last_name">
            <small>Last name is required</small>
          </div>
          <div class="input-group">
            <div class="input-group-text">
              <CCWidget :cardType="cardType" class="card-widget" />
            </div>
            <input
              type="text"
              id="ccnumber"
              v-model="ccnumber"
              class="form-control"
              :class="{
                'is-invalid': cardType === 'invalid' || invalidFields.ccnumber,
              }"
              @focus="clearError('ccnumber')"
              placeholder="Payment Card Number"
              @input="validateCard"
              :disabled="processing"
            />
          </div>
          <div class="text-danger" v-show="invalidFields.ccnumber">
            <small>Invalid payment card number</small>
          </div>
          <div
            class="expiration-section d-flex justify-content-start align-items-center flex-wrap"
          >
            <div>Expiration</div>
            <div
              class="expiration-block d-flex justify-conent-start align-items-center"
            >
              <div class="flex-fill">
                <select
                  id="month"
                  v-model="month"
                  class="form-select"
                  @focus="clearError('month')"
                  :class="{ 'is-invalid': invalidFields.month }"
                  placeholder="Month"
                  :disabled="processing"
                  @change="validateCard"
                >
                  <option value="01">Jan-1</option>
                  <option value="02">Feb-2</option>
                  <option value="03">Mar-3</option>
                  <option value="04">Apr-4</option>
                  <option value="05">May-5</option>
                  <option value="06">Jun-6</option>
                  <option value="07">Jul-7</option>
                  <option value="08">Aug-8</option>
                  <option value="09">Sep-9</option>
                  <option value="10">Oct-10</option>
                  <option value="11">Nov-11</option>
                  <option value="12">Dec-12</option>
                </select>
              </div>
              <div class="flex-fill">
                <select
                  id="year"
                  v-model="year"
                  class="form-select"
                  @focus="clearError('month')"
                  :class="{ 'is-invalid': invalidFields.month }"
                  placeholder="Year"
                  :disabled="processing"
                  @change="validateCard"
                >
                  <option
                    v-for="(y, i) in years"
                    :key="i"
                    :value="y.toString().slice(2, 4)"
                  >
                    {{ y.toString() }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="expiration-block d-flex justify-conent-start align-items-center"
            >
              <div>CVV</div>
              <div class="flex-fill">
                <input
                  type="text"
                  id="cvv"
                  v-model="cvv"
                  class="form-control"
                  @focus="clearError('cvv')"
                  :class="{ 'is-invalid': invalidFields.cvv }"
                  placeholder="123"
                  :disabled="processing"
                />
              </div>
            </div>
          </div>
          <div class="text-danger" v-show="invalidFields.month">
            <small>Expiration cannot be in the past</small>
          </div>
          <div class="text-danger" v-show="invalidFields.cvv">
            <small>CVV number is invalid</small>
          </div>

          <div class="section-title">Billing Address</div>

          <input
            type="text"
            id="address1"
            :class="{ 'is-invalid': invalidFields.address1 }"
            class="form-control"
            v-model="address1"
            @focus="clearError('address1')"
            placeholder="Street Address"
            :disabled="processing"
          />
          <div class="text-danger" v-show="invalidFields.address1">
            <small>Street address is required</small>
          </div>
          <input
            type="text"
            id="address2"
            class="form-control"
            v-model="address2"
            placeholder="Suite / Apartment"
            :disabled="processing"
          />
          <input
            type="text"
            id="city"
            class="form-control"
            @focus="clearError('city')"
            :class="{ 'is-invalid': invalidFields.city }"
            v-model="city"
            placeholder="City"
            :disabled="processing"
          />
          <div class="text-danger" v-show="invalidFields.city">
            <small>City is required</small>
          </div>
          <select
            id="state"
            v-model="state"
            class="form-select"
            @focus="clearError('state')"
            :class="{ 'is-invalid': invalidFields.state }"
            placeholder="State"
            :disabled="processing"
          >
            <option value="">State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
          <div class="text-danger" v-show="invalidFields.state">
            <small>State is required</small>
          </div>
          <input
            type="text"
            id="postal"
            class="form-control"
            @focus="clearError('postal')"
            :class="{ 'is-invalid': invalidFields.postal }"
            v-model="postal"
            placeholder="Zip"
            :disabled="processing"
          />
          <div class="text-danger" v-show="invalidFields.postal">
            <small>Valid zip code is required</small>
          </div>

          <div
            v-show="!oldCRA && !duplicateCard"
            class="check-control align-items-center"
          >
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="save_payement"
              v-model="savePayment"
            />
            <label class="form-check-label" for="save_payement">
              Add payment method to your wallet
            </label>
          </div>
          <div v-show="!oldCRA && duplicateCard" class="text-muted">
            Card is already in your wallet.
          </div>
        </div>

        <div>
          <div class="text-danger" v-show="processingError">
            <small>{{ processingError }}</small>
          </div>

          <button
            v-show="!processing"
            type="button"
            class="btn btn-success"
            @click="handleSubmit"
            :disabled="
              processing || (paymentType === 'wallet' && !selectedWalletItem)
            "
          >
            Load Cash Reserve
          </button>
          <button v-show="processing" class="btn btn-success" disabled>
            <i class="fas fa-sync fa-spin"></i> Loading Cash Reserve
          </button>

          <button
            class="btn btn-secondary"
            :disabled="processing"
            @click="backToAccount"
          >
            Cancel
          </button>
        </div>
      </form>

      <div v-if="!oldCRA && receipt" class="receipt">
        <div class="page-title d-flex justify-content-start">
          <div class="header-like">Thank you!</div>
        </div>

        <div class="section-title">Cash Reserve Account reload completed.</div>
        <div class="section-text">
          CRA funds are available for immediate use. Transaction details:
        </div>
        <div class="transaction-details">
          <div class="section-text">
            Amount:
            <i class="fas fa-dollar-sign" data-fa-transform="shrink-3"></i
            >{{ receipt.amount._text }}
          </div>
          <div class="section-text">Date: {{ receipt.date }}</div>
          <div class="section-text">
            <div class="d-flex align-items-center">
              <div>Payment Method:</div>
              <CCWidget :cardType="receipt.cardType" class="receipt-cc" />
              <div class="flex-fill">
                {{ receipt.cardTypeNice }}
                ending in
                {{
                  [...receipt.billing["cc-number"]._text]
                    .reverse()
                    .slice(0, 4)
                    .reverse()
                    .join("")
                }}
              </div>
            </div>
          </div>
          <div class="section-text" v-show="receipt.savedCard">
            Payment method has been saved to your wallet.
          </div>
        </div>
        <div class="section-text">
          <span class="receipt-print" @click="printDiv('receipt-print')">
            Print receipt for your records
          </span>
        </div>

        <div
          v-show="
            !craEnrollment && (receipt.savedCard || paymentType === 'wallet')
          "
        >
          <hr />
          <h5 class="text-center text-success mt-3">
            Cash Reserve Auto-Reload
          </h5>
          <p>
            <em>
              Save time and avoid declined transactions. Enroll this payment
              card in Cash Reserve Auto-Reload by following these easy steps:
            </em>
          </p>

          <div>
            <auto-reload
              :preSelectCard="savedCard"
              @enrollComplete="
                enrolled = true;
                savedCard = undefined;
              "
            />
          </div>
          <hr />
        </div>

        <div v-if="enrolled">
          <hr />
          <p class="text-success text-center fs-5">
            <i class="fa-duotone fa-square-check"></i> Payment Card Successfully
            Enrolled in Auto-Reload!
          </p>
          <hr />
        </div>

        <button class="btn btn-secondary close-btn" @click="clearReceipt">
          Close
        </button>

        <div id="receipt-print">
          <h3 class="header-like">Thank you!</h3>

          <p>Cash Reserve Account reload completed.</p>
          <p>CRA funds are available for immediate use. Transaction details:</p>
          <p>Amount: ${{ receipt.amount._text }}</p>
          <p>Date: {{ receipt.date }}</p>
          <p>
            Payment Method:
            {{ receipt.cardTypeNice }}
            ending in
            {{
              [...receipt.billing["cc-number"]._text]
                .reverse()
                .slice(0, 4)
                .reverse()
                .join("")
            }}
          </p>
          <p v-if="receipt.savedCard">
            Payment method has been saved to your wallet.
          </p>
        </div>
      </div>

      <div v-if="oldCRA && receipt" class="receipt">
        <div class="page-title d-flex justify-content-start">
          <div class="header-like">Thank you!</div>
        </div>

        <div class="section-title">Cash Reserve Account reload completed.</div>
        <div class="section-text">
          CRA funds are available for immediate use. Transaction details:
        </div>
        <div class="transaction-details">
          <div class="section-text">Amount: ${{ receipt.amount }}</div>
          <div class="section-text">Date: {{ receipt.date }}</div>
          <div class="section-text">
            <div class="d-flex align-items-center">
              <div>Payment Method:</div>
              <CCWidget :cardType="receipt.cardType" class="receipt-cc" />
              <div>
                {{ receipt.cardTypeNice }}
                ending in
                {{ receipt.ccnumber }}
              </div>
            </div>
          </div>
          <div class="section-text" v-show="receipt.savedCard">
            Payment method has been saved to your wallet.
          </div>
        </div>
        <div class="section-text">
          <span class="receipt-print" @click="printDiv('receipt-print-old')">
            Print receipt for your records
          </span>
        </div>
        <button class="btn btn-success close-btn" @click="clearReceipt">
          Close
        </button>

        <div id="receipt-print-old">
          <h3 class="header-like">Thank you!</h3>

          <p>Cash Reserve Account reload completed.</p>
          <p>CRA funds are available for immediate use. Transaction details:</p>
          <p>Amount: ${{ receipt.amount }}</p>
          <p>Date: {{ receipt.date }}</p>
          <p>
            Payment Method:
            {{ receipt.cardTypeNice }}
            ending in
            {{ receipt.ccnumber }}
          </p>
          <p v-if="savePayment && !duplicateCard">
            Payment method has been saved to your wallet.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import valid from "card-validator";
import { log, api, gaEvent, gaError } from "@/utils/utils";
import CCWidget from "@/components/CashApp/Shared/CCWidget.vue";
import Wallet from "@/components/Account/Wallet.vue";
import currency from "currency.js";
import dayjs from "dayjs";
import AutoReload from "../Account/AutoReload.vue";

export default {
  name: "LoadCRA",
  components: {
    CCWidget,
    Wallet,
    AutoReload,
  },
  data() {
    return {
      oldCRA: false,
      paymentType: undefined,

      processing: false,
      amount: currency(50),
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postal: "",
      ccnumber: "",
      cvv: "",
      formUrl: undefined,
      cardType: undefined,
      cardTypeNice: undefined,
      allowCardSubmit: false,
      cvvLength: 3,
      month: "01",
      year: new Date().getFullYear().toString().slice(2, 4),
      years: [new Date().getFullYear()].reduce((col, c) => {
        for (var j = c; j < c + 20; j++) {
          col.push(j);
        }
        return col;
      }, []),
      savePayment: true,
      saveCardSuccess: false,
      invalidFields: {},
      processingError: false,
      receipt: undefined,

      selectedWalletItem: undefined,
      selectedCardType: undefined,
      selectedCardTypeNice: undefined,

      deletingPaymentMethod: false,
      savedCard: undefined,
      enrolled: false,
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      testMode: (state) => state.app.all.testMode,
    }),
    wallet() {
      return (
        this.user &&
        Object.keys(this.user.wallet).reduce((col, k) => {
          if (this.user.wallet[k]) {
            const val = JSON.parse(JSON.stringify(this.user.wallet[k]));

            val.expired =
              parseInt(
                `${val.ccExp.substring(2, 4)}${val.ccExp.substring(0, 2)}`
              ) <
              parseInt(
                new Date()
                  .toISOString()
                  .split("T")[0]
                  .replace(/-/g, "")
                  .slice(2, 6)
              );

            col.push(val);
          }
          return col;
        }, [])
      );
    },
    craEnrollment() {
      for (const k in this.wallet) {
        if (this.wallet[k]?.autoReloadAmount) {
          return this.wallet[k];
        }
      }
      return false;
    },
    duplicateCard() {
      return !!this.wallet.find((w) => {
        return (
          w.ccNumber.length === this.ccnumber.length &&
          w.ccNumber.substring(0, 6) === this.ccnumber.substring(0, 6) &&
          w.ccNumber.substring(12, 16) === this.ccnumber.substring(12, 16) &&
          `${this.month}${this.year}` === w.ccExp
        );
      });
    },
  },
  watch: {
    wallet(post) {
      if (!this.paymentType) {
        this.paymentType = !this.oldCRA && post.length > 0 ? "wallet" : "cc";
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById("dummy").focus();
    });
  },
  methods: {
    quickLog(e) {
      console.log(e);
    },
    clearReceipt() {
      this.receipt = undefined;
    },
    clearForm() {
      this.processing = false;
      this.amount = 50;
      this.first_name = "";
      this.last_name = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.state = "";
      this.postal = "";
      this.ccnumber = "";
      this.cvv = "";
      this.formUrl = undefined;
      this.cardType = undefined;
      this.cardTypeNice = undefined;
      this.allowCardSubmit = false;
      this.cvvLength = 3;
      this.month = "01";
      this.year = new Date().getFullYear().toString().slice(2, 4);
      this.years = [new Date().getFullYear()].reduce((col, c) => {
        for (var j = c; j < c + 20; j++) {
          col.push(j);
        }
        return col;
      }, []);
      this.savePayment = true;
      this.saveCardSuccess = false;
      this.invalidFields = {};
      this.processingError = false;
      this.selectedWalletItem = undefined;
      this.selectedCardType = undefined;
      this.selectedCardTypeNice = undefined;
      this.enrolled = false;
    },
    validateAmount() {
      if (this.amount < 10) {
        this.invalidFields.amount =
          "The minimum Cash Reserve reload amount is $10.";
        this.amount = currency(10);
      } else if (!/^\d*\.?\d*$/.test(this.amount)) {
        this.invalidFields.amount = "Invalid amount.";
        this.amount = "";
      } else {
        this.amount = currency(this.amount);
      }
    },
    validateCard() {
      const v = valid.number(this.ccnumber);

      this.allowCardSubmit = false;

      if (!v.isPotentiallyValid) {
        this.cardType = "invalid";
      } else if (!v.card) {
        this.cardType = undefined;
        this.cardTypeNice = undefined;
      } else if (v.card.type) {
        this.cardType = v.card.type;
        this.cardTypeNice = v.card.niceType;
      }

      if (v.isValid && v.isPotentiallyValid) {
        this.allowCardSubmit = true;
      }

      this.cvvLength =
        v.card && v.card.code && v.card.code.size ? v.card.code.size : 3;
    },
    clearError(key) {
      this.invalidFields[key] = undefined;
    },
    handleSubmit() {
      if (this.paymentType === "cc") {
        this.doValidation();
      } else if (!this.oldCRA) {
        this.runCard();
      }
    },
    doValidation() {
      this.processing = true;
      this.invalidFields = {};
      this.formUrl = undefined;
      this.processingError = false;

      if (!this.amount || this.amount < 10) this.invalidFields.amount = true;
      if (this.first_name === "") this.invalidFields.first_name = true;
      if (this.last_name === "") this.invalidFields.last_name = true;
      if (!this.allowCardSubmit) this.invalidFields.ccnumber = true;

      if (
        parseInt(`${this.year}${this.month}`) <
        parseInt(
          new Date().toISOString().split("T")[0].replace(/-/g, "").slice(2, 6)
        )
      )
        this.invalidFields.month = true;
      if (this.cvv.length !== this.cvvLength) this.invalidFields.cvv = true;
      if (this.address1 === "") this.invalidFields.address1 = true;
      if (this.city === "") this.invalidFields.city = true;
      if (this.state === "") this.invalidFields.state = true;
      if (this.postal === "" || !this.postal || !/(^\d{5}$)/.test(this.postal))
        this.invalidFields.postal = true;

      if (Object.keys(this.invalidFields).length === 0) {
        if (!this.oldCRA) {
          this.runCard();
        } else {
          this.runOldCRA();
        }
      } else {
        this.processingError = "Please review and correct the issues above.";
        this.processing = false;
      }
    },
    async runCard() {
      this.processing = true;
      this.saveCardSuccess = false;

      try {
        if (this.testMode)
          throw { error: "This is a test account and will not process Moxey" };
        //Initiate step one of transaction to get form-url
        const s1 = await api("post", "banking", `/cc/stepone/`, {
          body: {
            first_name: this.first_name,
            last_name: this.last_name,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            postal: this.postal,
            amount: this.amount,
            description: "Moxey Cash Reserve Reload",
            vaultId: this.paymentType === "wallet" && this.selectedWalletItem,
          },
        });

        this.formUrl = s1.formUrl;

        //Submit sensitive data to form-url. Response will redirect a token-id back
        //to a handler in AWS to finish processing. AWS will send final response.
        let formData = new FormData();
        formData.append("billing-cc-number", this.ccnumber.replace(/\s/g, ""));
        formData.append("billing-cc-exp", `${this.month}${this.year}`);
        formData.append("billing-cvv", this.cvv);

        const s2 = await fetch(this.formUrl, {
          method: "POST",
          // headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            this.paymentType === "wallet" && this.selectedWalletItem
              ? undefined
              : new URLSearchParams(formData),
        });

        const res = await s2.json();

        console.log(res);
        if (res.error) throw res;
        const resultCode = res["result"]._text;
        const resultText = res["result-text"]._text;
        if (resultText === "DECLINE") {
          this.processingError =
            "Payment was declined. Please try again with an alternate payment method.";
          if (this.paymentType === "wallet") {
            const tempUser = JSON.parse(JSON.stringify(this.user));
            tempUser.wallet[this.selectedWalletItem].declined = true;
            this.updateAppStore({ user: tempUser });
          }
        } else if (resultCode !== "1") {
          this.processingError = resultText;
        } else {
          if (
            this.savePayment &&
            !this.duplicateCard &&
            this.paymentType !== "wallet"
          ) {
            //Aww, here we go again. Add payment type to vault.
            try {
              const v1 = await api("post", "banking", `/vault/stepone/`, {
                body: {
                  first_name: this.first_name,
                  last_name: this.last_name,
                  address1: this.address1,
                  address2: this.address2,
                  city: this.city,
                  state: this.state,
                  postal: this.postal,
                  cardType: this.cardType,
                  cardTypeNice: this.cardTypeNice,
                },
              });

              console.log(v1.formUrl);

              let vaultFormData = new FormData();
              vaultFormData.append(
                "billing-cc-number",
                this.ccnumber.replace(/\s/g, "")
              );
              vaultFormData.append(
                "billing-cc-exp",
                `${this.month}${this.year}`
              );
              vaultFormData.append("billing-cvv", this.cvv);

              const v2 = await fetch(v1.formUrl, {
                method: "POST",
                // headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams(formData),
              });

              const vaultRes = await v2.json();

              console.log("Add to vault response:", vaultRes);

              if (vaultRes.wallet) {
                this.updateAppStore({ user: vaultRes });
              }

              this.savedCard = this.wallet.find((w) => {
                return (
                  w.ccNumber.length === this.ccnumber.length &&
                  w.ccNumber.substring(0, 6) ===
                    this.ccnumber.substring(0, 6) &&
                  w.ccNumber.substring(12, 16) ===
                    this.ccnumber.substring(12, 16) &&
                  `${this.month}${this.year}` === w.ccExp
                );
              });

              this.saveCardSuccess = true;

              gaEvent("cra_wallet_save", "banking");

              log({
                m: "Saved card to wallet.",
                partition: "banking",
              });
            } catch (e) {
              gaError(`cra_wallet_save: ${e.error}`);
              console.log("Error in savinging to vault:", e.error || e);
              this.savePayment = false;
              log({
                m: "Error saving card to wallet.",
                e: e,
                partition: "banking",
              });
            }
          }

          if (this.paymentType === "wallet") {
            const tempUser = JSON.parse(JSON.stringify(this.user));
            tempUser.wallet[this.selectedWalletItem].declined = false;
            this.savePayment = false;
            this.updateAppStore({ user: tempUser });
          }

          if (this.user.wallet.length > 0) {
            this.paymentType = "wallet";
          }

          console.log(res);
          this.receipt = {
            ...res,
            savedCard: this.saveCardSuccess,
            cardType:
              this.paymentType === "wallet"
                ? this.selectedCardType
                : this.cardType,
            cardTypeNice:
              this.paymentType === "wallet"
                ? this.selectedCardTypeNice
                : this.cardTypeNice,
            date: dayjs(Date.now()).format("M/D/YYYY h:mm A"),
          };

          this.paymentType =
            this.saveCardSuccess || this.paymentType === "wallet"
              ? "wallet"
              : "cc";

          this.updateAppStore({
            user: {
              ...this.user,
              account: {
                ...this.user.account,
                cashBalance: res.mmsResponse.newBalance,
              },
            },
          });

          gaEvent("cra_load", "banking", `amt:${this.amount}`);

          log({
            m: "Loaded CRA.",
            ccsrc: this.paymentType,
            amt: this.amount,
            partition: "banking",
          });

          this.clearForm();

          console.log(this.receipt);
        }
      } catch (e) {
        gaError(`cra_load: ${e.error}`);
        const res = e.response || e;
        const data = res.data || res;
        const msg = data.message || data.error;
        this.processingError = msg;

        log({
          m: "Error loading CRA.",
          ccsrc: this.paymentType,
          amt: this.amount,
          partition: "banking",
          e: msg,
        });
      } finally {
        this.processing = false;
      }
    },
    printDiv(divName) {
      const printContents = document.getElementById(divName).innerHTML;
      const w = window.open();
      w.document.write(printContents);
      w.print();
      w.close();
    },
    async deletePaymentMethod(id) {
      this.deletingPaymentMethod = true;
      this.processingError = undefined;

      try {
        if (this.testMode)
          throw { error: "This is a test account and will not process Moxey" };
        await api("post", "banking", `/vault/delete/`, {
          body: {
            vaultId: id,
          },
        });

        const tempUser = JSON.parse(JSON.stringify(this.user));
        tempUser.wallet[id] = undefined;
        this.updateAppStore({ user: tempUser });

        if (this.wallet.length === 0) {
          this.paymentType = "cc";
        }

        if (id === this.selectedWalletItem) {
          this.selectedWalletItem = undefined;
          this.selectedCardType = undefined;
          this.selectedCardTypeNice = undefined;
        }
      } catch (e) {
        if (e.error) {
          this.processingError = e.error;
        } else {
          this.processingError =
            "An error occurred. Unable to delete payment method. Please try again later.";
          throw e;
        }
      } finally {
        this.deletingPaymentMethod = false;
      }
    },
    async runOldCRA() {
      this.processing = true;

      try {
        const res = await api("post", "banking", `/cc/direct/`, {
          body: {
            first_name: this.first_name,
            last_name: this.last_name,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            postal: this.postal,
            amount: this.amount,
            cardNumber: this.ccnumber,
            month: this.month,
            year: this.year,
            cvv: this.cvv,
          },
        });

        if (res.status === "Transaction Approved") {
          // const tempUser = JSON.parse(JSON.stringify(this.user));
          // tempUser.account = res.account;
          // this.updateAppStore({ user: tempUser });

          this.updateAppStore({
            user: {
              ...this.user,
              account: res.account,
            },
          });

          this.receipt = {
            amount: this.amount,
            ccnumber: [...this.ccnumber]
              .reverse()
              .slice(0, 4)
              .reverse()
              .join(""),
            cardType:
              this.paymentType === "wallet"
                ? this.selectedCardType
                : this.cardType,
            cardTypeNice:
              this.paymentType === "wallet"
                ? this.selectedCardTypeNice
                : this.cardTypeNice,
            date: dayjs(Date.now()).format("M/D/YYYY h:mm A"),
          };

          log({
            m: "Loaded CRA.",
            ccsrc: "cc",
            amt: this.amount,
            partition: "banking",
          });

          this.clearForm();
        } else {
          throw res;
        }
      } catch (e) {
        this.processingError = e.message || e.status;

        log({
          m: "Error loading CRA.",
          ccsrc: this.paymentType,
          amt: this.amount,
          partition: "banking",
          e: e.message || e.status,
        });
      } finally {
        this.processing = false;
      }
    },
    closePane() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("cancel");
    },
    backToAccount() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("clickedShortcut", "Account");
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
  activated() {},
};
</script>

<style scoped>
#new-cra-load {
  background-color: white;
  color: var(--ms-dark);
  font-size: 18px;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.section {
  margin: 60px 0 0;
  padding: 30px 30px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.section:last-child {
  margin: 60px 0;
}

.section-title {
  font-size: 20px;
  line-height: 24px;
}

.section-title span {
  font-size: 16px;
  margin: 0 0 0 10px;
  font-style: italic;
}

.section-title:not(:first-of-type) {
  margin: 30px 0 0;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 22px;
  padding: 21px 20px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-check-input {
  padding: 12px;
  height: 24px;
  width: 24px;
  margin: 0;
}

.form-check-label {
  margin: 0 0 0 20px;
}

.check-control {
  margin: 15px 0;
  display: flex;
}

select {
  font-size: 18px;
  line-height: 22px;
  padding: 21px 20px;
  font-style: italic;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
}

.expiration-section {
  margin: 30px 0 0;
  width: 100%;
}

.expiration-block {
  width: 100%;
  max-width: 500px;
}

.expiration-block div {
  margin: 0 15px 0 0;
}

.exp-holder {
  width: 600px;
}

.expiration {
  width: 100%;
  max-width: 150px;
}

.expiration:not(:last-of-type) {
  margin: 15px 20px 15px 0;
}

.btn-success {
  margin: 35px 0;
  border-color: var(--mx-green);
  background-color: var(--mx-green);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 0 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.page-icon {
  margin: 0 10px 0 0;
}

.receipt {
  padding: 30px 30px 0;
}

.receipt > .page-title {
  padding: 0;
}

.section-text {
  margin: 15px 0;
}

.receipt-cc {
  height: 36px;
  width: 70px;
  margin: 0 5px;
}

.transaction-details {
  padding: 15px 30px;
}

.receipt-print {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

#receipt-print,
#receipt-print-old {
  display: none;
}

.close-btn {
  cursor: pointer;
}

.payment-type {
  padding: 30px 0 0;
}

.card-widget {
  height: 36px;
  font-size: 36px;
  width: 50px;
}

.wallet-item {
  padding: 30px;
  background-color: #fcfcfc;
  border-radius: 20px;
  margin: 15px 0;
  cursor: pointer;
}

.wallet-item.is-invalid {
  background-color: #f8d7da;
  cursor: default;
}

.wallet-item:not(.is-invalid):hover,
.wallet-item.selected {
  background-color: #00af6c1c;
}

.wallet-item-inner > div {
  margin: 0 20px 0 0;
  line-height: 36px;
}

.wallet-select {
  margin: 0 20px 0 0;
}

.wallet-warn {
  margin: 10px 0 0 0;
}

.select-payment-type {
  margin: 30px 0 0;
}

.mx-alert div:first-child {
  font-size: 30px;
}

mx-alert:not(:first-child) {
  margin: 15px 0 0;
}

.alert-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-text {
  font-size: 17px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-icon {
  font-size: 50px;
}

.mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}
</style>
