<template>
  <div class="mx-card no-select d-flex flex-column align-items-center">
    <div class="virtual-card d-flex">
      <div
        class="vc-content d-flex flex-column justify-content-center align-items-center"
      >
        <div class="logo">
          <img src="@/assets/moxey_light_bg.svg" />
        </div>
        <div>
          <profile-img :showLast="true" />
        </div>
      </div>
      <div
        class="vc-content d-flex flex-column justify-content-center align-items-center"
      >
        <div
          class="qr-holder d-flex flex-column justify-content-center align-items-center"
        >
          <div class="vc-number mono">
            {{ [cardNumber[0], cardNumber[1]].join(" ") }}
          </div>
          <div class="qr-code">
            <canvas id="virtual-card"></canvas>
          </div>

          <div class="vc-number mono">
            {{ [cardNumber[2], cardNumber[3]].join(" ") }}
          </div>
        </div>
      </div>
      <div
        class="vc-content d-flex flex-column justify-content-center align-items-center"
      >
        <div
          class="icon-btn d-flex justify-content-center align-items-center"
          @click="$emit('hideVirtual')"
        >
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import qrcode from "qrcode";
import ProfileImg from "@/components/CoreApp/User/Shared/ProfileImg";
//let test = "hello";

export default {
  name: "VirtualCard",
  components: {
    ProfileImg,
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      const canvas = document.getElementById("virtual-card");

      qrcode.toCanvas(canvas, this.user.contact.private.cardNumber);
    });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      testMode: (state) => state.app.all.testMode,
    }),
    cardNumber() {
      const cn = this.testMode
        ? "9876543210123456"
        : this.user.contact.private.cardNumber;
      return [
        cn.substring(0, 4),
        cn.substring(4, 8),
        cn.substring(8, 12),
        cn.substring(12, 16),
      ];
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.mx-card-holder {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin: 0 0 15px;
  position: relative;
}

.mx-card-holder img {
  width: 100%;
}

.card-number {
  color: var(--mx-grey);
  position: absolute;
  bottom: 7%;
  left: 7%;
  font-size: 100%;
  line-height: 100%;
}

.btn {
  font-weight: 500;
  font-size: 16px;
  padding: 6px 22px;
  border-radius: 10px;
}

.btn-outline-success {
  color: var(--mx-green);
  border-color: var(--mx-green);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active,
.btn-outline-success:hover {
  color: var(--mx-white);
  background-color: var(--mx-green);
}

.virtual-card {
  position: absolute;
  background-color: rgb(254 255 249 / 75%);
  width: 100%;
  height: calc(100vh);
  top: 0;
  left: 0;
  z-index: 150;
  backdrop-filter: blur(10px);
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vc-content {
  margin: 15px 15px;
}

.vc-content > div {
  margin: 15px 0;
}

.logo {
  max-width: 260px;
}

.logo img {
  width: 100%;
}

.vc-content .qr-holder {
  width: 260px;
  max-width: 260px;
  height: 260px;
  min-height: 260px;
  background-color: white;
  overflow: hidden;
  border-radius: 100%;
  border: 4px solid #a6a6a630;
}

.qrcode {
  transform: scale(2);
}

.icon-btn {
  background-color: var(--bs-danger);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  color: var(--mx-white);
  font-size: 24px;
}

@media screen and (min-height: 530px) {
  .virtual-card {
    flex-direction: column;
  }
}
</style>
