<template>
  <div v-if="showWarning" class="alert alert-danger rounded-0 fs-6">
    <div class="fw-bold mb-2">
      Important announcement for {{ exchangeName }} members
    </div>
    <div class="fw-light">
      <small>
        On Tuesday, January 31st at 9PM CST, {{ exchangeName }} will be leaving
        the Moxey network. Your access the Moxey website and app will be removed
        at that time.
        <a
          target="_blank"
          href="https://www.moxeyusa.com/cctegtletter"
          class="inline-link"
          >Please read details on this announcment here.</a
        ></small
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";

//let test = "hello";

export default {
  name: "Goodbye",
  components: {},
  data() {
    return {};
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
    }),
    exchangeName() {
      return this.user?.member?.auth?.[0]?.auName;
    },
    showWarning() {
      if ([21, 13].includes(this.user?.member?.auth?.[0]?.auId)) return true;

      return false;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.alert {
  width: calc(100% + 60px);
  margin: 0 -30px 30px -30px;
  padding: 15px 30px;
}

.inline-link {
  cursor: pointer;
  border-bottom: 1px dotted #842029;
  color: #842029;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .alert {
    margin: 0 -30px -60px -30px;
    padding: 70px 30px 15px;
  }
}
</style>
