import ls from "local-storage";

const stage =
  process.env.VUE_APP_DEPLOY_CONTEXT === "production" ? "prod" : "dev";

const dev = {
  //Old directory
  userUploadBucket: "dev-moxey-user-uploads",
  directoryImgBucket: "dev-moxey-directory-images",
  cloudFrontPrefix: "https://d355xv48qy2m96.cloudfront.net",

  //New Directory
  newCloudFrontPrefix: "https://d3coztagmlm850.cloudfront.net",
  aws_appsync_graphqlEndpoint:
    "https://a4ptcov7hvhp7lmv6fmwoybjvu.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AWS_IAM",
  // aws_appsync_apiKey: "da2-dyrzs3n77fcrhnrk76kscto2za",
  Auth: {
    region: "us-east-2",
    identityPoolId: "us-east-2:c568e965-ae50-4409-8ea7-48613f1a42b2",
    userPoolId: `us-east-2_9XaEVjpPS`,
    userPoolWebClientId: `5m6d1repurp3lne54rsm366i9n`,
  },
  Storage: {
    AWSS3: {
      bucket: "dev-moxey-user-uploads", //REQUIRED -  Amazon S3 bucket name
      region: "us-east-2",
    },
  },
  geo: {
    AmazonLocationService: {
      search_indices: {
        items: ["MoxeyClientPlaceIndex"], // REQUIRED - Amazon Location Service Place Index name
        default: "MoxeyClientPlaceIndex", // REQUIRED - Amazon Location Service Place Index name to set as default
      },
      region: "us-east-2", // REQUIRED - Amazon Location Service Region
    },
  },
  API: {
    endpoints: [
      {
        name: "core",
        endpoint: "https://4jghem5fu5.execute-api.us-east-2.amazonaws.com/dev",
        custom_header: async () => {
          return {
            "x-api-key": process.env.DEV_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "search",
        endpoint: "https://6s362tu0q8.execute-api.us-east-2.amazonaws.com/dev",
        custom_header: async () => {
          return {
            "x-api-key": process.env.DEV_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "banking",
        endpoint: "https://cwgxiw4uda.execute-api.us-east-2.amazonaws.com/dev",
        custom_header: async () => {
          return {
            "x-api-key": process.env.DEV_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "admin",
        endpoint: "https://j2tt1mqbji.execute-api.us-east-2.amazonaws.com/dev",
        custom_header: async () => {
          return {
            "x-api-key": process.env.DEV_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "headless",
        endpoint: "https://0xgd5k2wrj.execute-api.us-east-2.amazonaws.com/dev",
        custom_header: async () => {
          return {
            "x-api-key": process.env.DEV_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "invites",
        endpoint: "https://8ec6we2qn2.execute-api.us-east-2.amazonaws.com/dev",
        custom_header: async () => {
          return {
            "x-api-key": process.env.DEV_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
    ],
  },
};

const prod = {
  //Old directory
  userUploadBucket: "prod-moxey-user-uploads",
  directoryImgBucket: "prod-moxey-directory-images",
  cloudFrontPrefix: "https://d18kim1ijldka3.cloudfront.net",

  //New Directory
  newCloudFrontPrefix: "https://d1d2ojkb7hipdt.cloudfront.net",
  aws_appsync_graphqlEndpoint:
    "https://i36mwbvm2rg6jf5hwyd3kjfafu.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AWS_IAM",
  Auth: {
    region: "us-east-2",
    identityPoolId: "us-east-2:97a260fc-23a9-40e4-858e-9aa62abc3e9a",
    userPoolId: `us-east-2_smsrrYYtK`,
    userPoolWebClientId: `7m25tac16e0te4umlgrjh54c`,
  },
  Storage: {
    AWSS3: {
      bucket: "prod-moxey-user-uploads", //REQUIRED -  Amazon S3 bucket name
      region: "us-east-2",
    },
  },
  geo: {
    AmazonLocationService: {
      search_indices: {
        items: ["MoxeyClientPlaceIndex"], // REQUIRED - Amazon Location Service Place Index name
        default: "MoxeyClientPlaceIndex", // REQUIRED - Amazon Location Service Place Index name to set as default
      },
      region: "us-east-2", // REQUIRED - Amazon Location Service Region
    },
  },
  API: {
    endpoints: [
      {
        name: "core",
        endpoint: "https://kh8fira1f0.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return {
            "x-api-key": process.env.PROD_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "search",
        endpoint: "https://sp87vhwrw2.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return {
            "x-api-key": process.env.PROD_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "banking",
        endpoint: "https://cr13yqvd6h.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return {
            "x-api-key": process.env.PROD_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "admin",
        endpoint: "https://cr6kxnq2a8.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return {
            "x-api-key": process.env.PROD_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "headless",
        endpoint: "https://ktpi3j9zpf.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return {
            "x-api-key": process.env.PROD_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
      {
        name: "invites",
        endpoint: "https://9d6y4ly2bk.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return {
            "x-api-key": process.env.PROD_MOXEY_AWS_API_KEY,
            Authorization:
              ls.get("accessToken") && `Bearer ${ls.get("accessToken")}`,
          };
        },
      },
    ],
  },
};

// Default to dev if not set
const config = stage === "prod" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
