<template>
  <div v-if="!submitted">
    <h4>Thank you for using Moxey!</h4>

    <p class="fw-lighter">
      <small>
        We value your input and will periodically ask you for your
        feedback.<span v-if="showMyAccount">
          This short survey is also available on your My Account page.</span
        >
      </small>
    </p>

    <p>
      How likely would you be to recommend Moxey to a friend or another business
      owner?
    </p>

    <div class="nps-buttons text-center">
      <div class="d-flex py-2 px-1 justify-content-between">
        <mx-button
          v-for="n in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reverse()"
          v-bind:key="n"
          :btnClass="`btn ${
            npsScore === n ? 'btn-success' : 'btn-outline-success'
          }`"
          :disabled="enrolling"
          :height="30"
          overrideWidth="30px"
          @click="npsScore = n"
          >{{ n }}</mx-button
        >
      </div>
    </div>

    <div class="d-flex justify-content-between mt-2">
      <div class="fw-lighter">
        <small><i class="fas fa-caret-left" /> Less Likely</small>
      </div>
      <div class="fw-lighter">
        <small>More Likely <i class="fas fa-caret-right" /></small>
      </div>
    </div>

    <div v-if="npsScore" class="mt-3">
      <div v-if="npsScore > 8" class="d-flex justify-content-between mt-2">
        That's great! What about Moxey are you most excited to share with
        others?
      </div>
      <div v-else-if="npsScore > 6" class="d-flex justify-content-between mt-2">
        Thank you for your feedback. Could you share the reason behind your
        score?
      </div>
      <div
        v-else-if="npsScore <= 6"
        class="d-flex justify-content-between mt-2"
      >
        We want you to have an amazing Moxey experience. What can we do to
        improve?
      </div>

      <textarea
        class="form-control mt-2"
        id="feedback"
        name="feedback"
        v-model="feedback"
        rows="3"
        :disabled="submitting"
      ></textarea>

      <div class="text-center mt-3">
        <mx-button
          v-if="!submitting"
          :btnClass="`btn btn-outline-success`"
          :disabled="submitting"
          :height="40"
          @click="sendFeedback"
          >Send Feedback</mx-button
        >
        <mx-button
          v-else
          :btnClass="`btn btn-outline-success`"
          :disabled="true"
          :height="40"
          >Sending Feedback ...</mx-button
        >
      </div>
    </div>
  </div>
  <div v-else>
    <h4>Thank you! Your feedback helps improve Moxey for all its members.</h4>

    <p class="fw-lighter">
      <small>
        As always, if you need assistance or have questions, don't hesitate to
        reach out to your Executive Director.
      </small>
    </p>
  </div>
</template>

<script>
import { api } from "@/utils/utils";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import MxButton from "../Shared/MxButton.vue";

//let test = "hello";

export default {
  name: "NPS",
  components: {
    MxButton,
  },
  data() {
    return {
      npsScore: undefined,
      submitting: false,
      submitted: false,
      feedback: undefined,
    };
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  props: {
    showMyAccount: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: "",
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    async sendFeedback() {
      if (this.submitting) return;
      this.submitting = true;
      const res = await api("post", "core", `/v2/feedback/`, {
        body: {
          feedback: this.feedback,
          npsScore: this.npsScore,
          source: this.source,
        },
      });
      console.log(res);
      this.submitted = true;
      setTimeout(() => {
        this.updateAppStore({ showNPS: false });
        this.submitting = false;
      }, 15000);
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
.nps-buttons {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  direction: rtl;
}
</style>
