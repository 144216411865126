<template>
  <div
    class="d-inline-block"
    :style="{
      height: !padY && `${height}px`,
      width: fullWidth ? `100%` : padX ? `fit-content` : `${height * 5.3}px`,
    }"
  >
    <button
      class="btn"
      :type="type"
      :class="btnClass"
      :style="{
        'font-size': `${height * 0.4}px`,
        'border-radius': `${pill ? 1000 : height * 0.3333}px`,
        'padding-top': padY && `${padY}px`,
        'padding-bottom': padY && `${padY}px`,
        'padding-left': padX && `${padX}px`,
        'padding-right': padX && `${padX}px`,
        width: overrideWidth,
        'min-width': overrideWidth,
      }"
      :disabled="disabled"
    >
      <slot>Push it</slot>
    </button>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";

//let test = "hello";

export default {
  name: "MxButton",
  components: {},
  data() {
    return {};
  },
  props: {
    btnClass: String,
    fullWidth: Boolean,
    overrideWidth: String,
    pill: Boolean,
    height: {
      type: Number,
      default: 30,
    },
    padY: Number,
    padX: Number,
    disabled: Boolean,
    type: {
      type: String,
      default: "button",
    },
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.btn {
  font-weight: 500;
  width: 100%;
  height: 100%;
  padding: initial;
  line-height: initial;
}

.btn-outline-success {
  color: var(--mx-green);
  border-color: var(--mx-green);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active,
.btn-outline-success:hover {
  color: var(--mx-white);
  background-color: var(--mx-green);
}

.btn-success {
  border-color: var(--mx-green) !important;
  background-color: var(--mx-green) !important;
}

.dark {
  background-color: rgba(0, 0, 0, 0.75);
}
</style>
