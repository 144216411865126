<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    aria-labelledby="universalModalLabel"
    aria-hidden="true"
  >
    <div
      :class="[
        'modal-dialog',
        'd-flex',
        'justify-content-center',
        ...(additionalModalClasses || []),
        modalOptions.dialogClass,
        {
          'modal-dialog-centered': modalOptions.centered,
          'modal-fullscreen': modalOptions.fullscreen,
        },
        modalOptions.size,
      ]"
    >
      <div class="modal-content">
        <div class="modal-header" v-if="modalOptions.showHeader">
          <h5 class="modal-title" id="universalModalLabel">
            <slot name="header">This is a Modal</slot>
          </h5>
          <button
            v-if="modalOptions.showClose"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <slot>
          <div class="modal-body">Modal Body Text</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="emit('clickedOk')"
            >
              OK
            </button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import short from "short-uuid";

export default {
  name: "Modal",
  components: {},
  data() {
    return {
      modal: undefined,
      modalId: this.externalId || short.generate(),
    };
  },
  props: {
    externalId: String,
    showModal: {
      type: Boolean,
      default: false,
    },
    modalBSOptions: {
      type: Object,
      default() {
        return {
          keyboard: false,
          backdrop: "static",
        };
      },
    },
    additionalModalClasses: {
      type: Array,
      default() {
        return [];
      },
    },
    modalOptions: {
      type: Object,
      default() {
        return {
          showClose: true,
          showFooter: true,
          showHeader: true,
          building: false,
        };
      },
    },
  },
  computed: {},
  watch: {
    showModal(post) {
      if (post) {
        this.$nextTick(() => {
          this.buildModal();
        });
      } else if (post === false) {
        this.$nextTick(() => {
          if (this.modal) {
            this.modal.hide();
          }
        });
      }
    },
  },
  methods: {
    buildModal() {
      if (!this.modal) {
        this.building = true;
        this.$nextTick(() => {
          try {
            const el = document.getElementById(this.modalId);
            this.modal = new Modal(el, this.modalBSOptions);
            const emit = this.$emit;
            document
              .getElementById(this.modalId)
              .addEventListener("hidden.bs.modal", function () {
                emit("modalClosed");
              });
            this.modal.show();
          } catch (e) {
            console.log(e);
          }
        });
      } else {
        this.modal.show();
      }
    },
  },
  mounted() {},
};
</script>
