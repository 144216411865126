<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Spending Targets</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="text">
        <div>
          Moxey is a currency that's been specifically designed to be spent.
          Every time you spend Moxey, you save cash and help strengthen your
          local Moxey community.
        </div>
        <div>
          The Spending Target is the minimum monthly Moxey expenditure you
          should make. Meeting the Spending Target ensures that you get maximum
          value from your Moxey and helps protect the health of your Moxey
          community.
        </div>
        <div>
          The Spending Target is calculated at the beginning of each month and
          takes into account your current Moxey balance, earnings history, and
          spending habits.
        </div>
        <div>
          If you need help meeting your Spending Target, don't hesitate to reach
          out to your exchange office.
        </div>
        <div>
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'Account')"
          >
            Back to Account Summary
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpendingTargetInfo",
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.text div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.divider {
  width: 100%;
  background-color: var(--mx-white);
  height: 1px;
  margin: 20px 0;
}
</style>
