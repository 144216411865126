import { api, gaEvent, gaTiming } from "./utils";
import store from "../store/index";
import { getBoundingBox } from "geolocation-utils";
import config from "@/config/awsconfig";
import dayjs from "dayjs";
import { geocode } from "./location";

export async function protoSearch(payload) {
  payload.searchFields =
    payload.searchFields && JSON.stringify(payload.searchFields);
  payload.fields = payload.fields && payload.fields.join(",");
  payload.expr = payload.expr && JSON.stringify(payload.expr);
  payload.sort = payload.sort && payload.sort.join(",");

  payload = Object.keys(payload).reduce((col, k) => {
    if (payload[k] || payload[k] === 0) col[k] = payload[k];
    return col;
  }, {});

  let searchParams = new URLSearchParams(payload);

  return await api(
    "get",
    "search",
    `/search/adhoc/?${searchParams.toString()}`
  );
}

export async function directorySearch(searchPayload) {
  searchPayload.expr = {
    demote_standby: `(1-waiting)*(1-standby)`,
  };

  searchPayload.sort = [];

  searchPayload.fields = [
    "_score",
    "city",
    "state",
    "desc",
    "image_url",
    "list_name",
    "location",
    "product_types",
    "business_types",
    "range_band",
    "product_icons",
    "product_imgs",
    "standby",
    "allows_cards",
    "phone1",
    "category_tree_json",
    "update_ts",
    "fa_icon",
  ];

  if (searchPayload.productType)
    gaEvent("search_product_type", "engagement", searchPayload.productType);

  if (searchPayload.businessType)
    gaEvent("search_business_type", "engagement", searchPayload.businessType);

  const filterArray = [
    `type:'member'`,
    `hidden_directory:'No'`,
    searchPayload.filter,
    searchPayload.productType &&
      `product_types:'${searchPayload.productType.replace(/'/g, "\\'")}'`,
    searchPayload.businessType &&
      `business_types:'${searchPayload.businessType.replace(/'/g, "\\'")}'`,
  ];

  // let rankByLocation = false;
  // let rankByRewards = false;

  searchPayload.expr.new_global = `join_date >= ${dayjs()
    .subtract(180, "day")
    .valueOf()} ? 1 : 0`;

  searchPayload.fields.push("new_global");

  if (!searchPayload.noLocation) {
    let fuzzyLocation = {};
    let searchLocation;

    if (searchPayload?.searchText?.includes(" in "))
      searchLocation = searchPayload?.searchText
        .substring(searchPayload?.searchText.lastIndexOf(" in "))
        .replace(" in ", "");
    else if (
      searchPayload?.searchText?.includes(" near ") &&
      !searchPayload?.searchText?.includes("near me")
    )
      searchLocation = searchPayload?.searchText
        .substring(searchPayload?.searchText.lastIndexOf(" near "))
        .replace(" near ", "");
    else if (searchPayload?.searchText?.includes(" around "))
      searchLocation = searchPayload?.searchText
        .substring(searchPayload?.searchText.lastIndexOf(" around "))
        .replace(" around ", "");

    if (searchLocation)
      fuzzyLocation =
        searchPayload.searchText &&
        (await geocode(searchPayload.searchText, [
          searchPayload.lng,
          searchPayload.lat,
        ]));

    searchPayload.searchLat = fuzzyLocation?.lat || searchPayload.lat;
    searchPayload.searchLng = fuzzyLocation?.lng || searchPayload.lng;

    //console.log("search location", fuzzySearch, searchPayload);

    searchPayload.expr.distance = `haversin(${searchPayload.lat},${searchPayload.lng},location.latitude,location.longitude)*0.62`;
    searchPayload.expr.distance_from_search = `haversin(${searchPayload.searchLat},${searchPayload.searchLng},location.latitude,location.longitude)*0.62`;

    searchPayload.fields.push("distance");
    searchPayload.fields.push("distance_from_search");

    if (searchPayload.distance) {
      const { bottomRight, topLeft } = getBoundingBox(
        [
          {
            lat: parseFloat(searchPayload.lat),
            lon: parseFloat(searchPayload.lng),
          },
        ],
        searchPayload.distance * 1609.34
      );

      filterArray.push(
        `location:['${topLeft.lat},${topLeft.lon}','${bottomRight.lat},${bottomRight.lon}']`
      );
    }
  }

  if (searchPayload?.includePoints?.exchangeEnrolled) {
    const {
      newMemberAgeThreshold,
      newMemberBonus,
      newMemberEarningThreshold,
      stagnantSellerBonus1_10,
      stagnantSellerBonus11_20,
      stagnantSellerBonus21_30,
      stagnantSellerBonus31_40,
      stagnantSellerBonus41_50,
      stagnantSellerBonus51_60,
      stagnantSellerBonus61_70,
      stagnantSellerBonus71_80,
      stagnantSellerBonus81_90,
      stagnantSellerBonus91_100,
    } = searchPayload.includePoints;

    const dateCutoff = dayjs()
      .startOf("day")
      .subtract(newMemberAgeThreshold, "day")
      .valueOf();

    searchPayload.expr.new_member_by_date = `join_date >= ${dateCutoff} ? 1 : 0`;
    searchPayload.fields.push("new_member_by_date");
    searchPayload.expr.new_member_by_sales = `sales < ${newMemberEarningThreshold} ? 1 : 0`;
    searchPayload.fields.push("new_member_by_sales");
    searchPayload.expr.bonus_reward = `join_date >= ${dateCutoff} && sales < ${newMemberEarningThreshold} ? ${newMemberBonus} : balance > 0 ? 0 : percent_stagnant >= 91 ? ${stagnantSellerBonus91_100} : percent_stagnant >= 81 ? ${stagnantSellerBonus81_90} : percent_stagnant >= 71 ? ${stagnantSellerBonus71_80} : percent_stagnant >= 61 ? ${stagnantSellerBonus61_70} : percent_stagnant >= 51 ? ${stagnantSellerBonus51_60} : percent_stagnant >= 41 ? ${stagnantSellerBonus41_50} : percent_stagnant >= 31 ? ${stagnantSellerBonus31_40} : percent_stagnant >= 21 ? ${stagnantSellerBonus21_30} :  percent_stagnant >= 11 ? ${stagnantSellerBonus11_20} :  percent_stagnant >= 1 ? ${stagnantSellerBonus1_10} :  0`;
    searchPayload.fields.push("bonus_reward");

    console.log(
      dayjs()
        .startOf("day")
        .subtract(newMemberAgeThreshold, "day")
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      newMemberAgeThreshold,
      newMemberBonus,
      newMemberEarningThreshold
    );
  }

  searchPayload.filter = `(and ${filterArray.join(" ")})`;

  searchPayload.query = searchPayload.query || searchPayload.searchText || "*";

  const start = Date.now();

  const presearch = await protoSearch({
    query: searchPayload.query || "*",
    filter: searchPayload.filter,
    start: searchPayload.start || 0,
    expr: searchPayload.expr,
    sort: ["_score desc"],
    searchFields: [
      "category_tree_free^1",
      "list_name^1.2",
      "list_name_alt^1.2",
    ],
    fields: ["_score"],
    size: 1,
    facets: JSON.stringify({ product_types: {}, business_types: {}, city: {} }),
  });

  const maxScore = parseFloat(
    presearch?.hits?.hit?.[0]?.fields?._score?.[0] || 0
  );

  searchPayload.expr.sort_exp = `
    waiting == 1 ? 0 :
    standby == 1 ? 0 :
    (${searchPayload.noLocation ? 0 : 1} == 0 ? 0 : 
        200 * max( 1 - (
          (
            range_band == 3 ? floor((haversin(${searchPayload.searchLat || 0},${
    searchPayload.searchLng || 0
  },location.latitude,location.longitude)*0.62)/1.5)
            : range_band == 2 ? floor((haversin(${
              searchPayload.searchLat || 0
            },${
    searchPayload.searchLng || 0
  },location.latitude,location.longitude)*0.62)/1.5)
            : floor((haversin(${searchPayload.searchLat || 0},${
    searchPayload.searchLng || 0
  },location.latitude,location.longitude)*0.62)/1.5)
        ) / 40
      ), 0)
    )
    +
    (${maxScore || 0} == 0 ? 0 : (50 * ( _score / ${maxScore})))
    +
    (20 * (join_date >= ${dayjs().subtract(180, "day").valueOf()} ? 1 : 0))
    +
    (10 * ((percent_stagnant * ( balance < 0 ? 1 : 0)) / 100))
    +
    (5 * ( balance < 0 ? 1 : 0))
  `;

  searchPayload.expr.adjscore = `
    (${maxScore || 0} == 0 ? 0 : (100 * ( _score / ${maxScore})))`;
  searchPayload.expr.distscore = `
    (${searchPayload.noLocation ? 0 : 1} == 0 ? 0 :
      200 * max( 1 - (
        (
          range_band == 3 ? floor((haversin(${searchPayload.searchLat || 0},${
    searchPayload.searchLng || 0
  },location.latitude,location.longitude)*0.62)/1.5)
          : range_band == 2 ? floor((haversin(${searchPayload.searchLat || 0},${
    searchPayload.searchLng || 0
  },location.latitude,location.longitude)*0.62)/1.5)
          : floor((haversin(${searchPayload.searchLat || 0},${
    searchPayload.searchLng || 0
  },location.latitude,location.longitude)*0.62)/1.5)
      ) / 40
    ), 0)
  )`;
  searchPayload.expr.ps = `(10 * ((percent_stagnant * ( balance < 0 ? 1 : 0)) / 100))`;
  searchPayload.expr.is_new = ` (20 * (join_date >= ${dayjs()
    .subtract(180, "day")
    .valueOf()} ? 1 : 0))`;
  searchPayload.expr.bs = `
    (5 * ( balance < 0 ? 1 : 0))`;

  searchPayload.fields.push("sort_exp");
  searchPayload.fields.push("adjscore");
  searchPayload.fields.push("distscore");
  searchPayload.fields.push("ps");
  searchPayload.fields.push("is_new");
  searchPayload.fields.push("bs");
  searchPayload.sort.push("sort_exp desc");

  const s = await protoSearch({
    query: searchPayload.query || "*",
    filter: searchPayload.filter,
    start: searchPayload.start || 0,
    expr: searchPayload.expr,
    sort: searchPayload.sort,
    searchFields: [
      "category_tree_free^1",
      "list_name^1.2",
      "list_name_alt^1.2",
    ],
    fields: searchPayload.fields,
    size: searchPayload.size || 10000,
    facets: JSON.stringify({ product_types: {}, business_types: {}, city: {} }),
  });

  console.log(s);

  if (s.status && s.status.timems) gaTiming("search", Date.now() - start);

  gaEvent(
    "search",
    "engagement",
    `query="${searchPayload.query}" filters="${searchPayload.filter}" found="${
      s.hits && s.hits.found
    }"`
  );

  if (!s.hits || !s.hits.found || s.hits.found === 0)
    gaEvent(
      "search_none_found",
      "engagement",
      `query="${searchPayload.query}" filters="${searchPayload.filter}"`
    );

  return s;
}

export function searchToResultsList(res) {
  const { hit, start, found } = res.hits;

  store.commit("search/update", {
    resultCount: found,
    searchResults: Object.freeze(hit),
    searchStats: Object.freeze({
      found: found,
      start: start,
    }),
    searching: false,
  });
}

export function unpackContactSearchResult(res) {
  const contactMemberIds = res.hits.hit.reduce((col, h) => {
    if (h.id.substring(0, 1) === "c") {
      col.push(h.id.split("__")[0].split("-").reverse()[0]);
    }
    return col;
  }, []);

  const temp = res.hits.hit.filter(
    (h) =>
      h.id.substring(0, 1) === "c" ||
      (h.id.substring(0, 1) === "m" &&
        !contactMemberIds.includes(h.id.split("__")[0].split("-").reverse()[0]))
  );

  return temp.map((r) => {
    return {
      ...Object.keys(r.fields).reduce((o, f, i) => {
        if (i === 0) {
          const v2Url = `${config.cloudFrontPrefix}/v2/images/products/${
            r.id.split("__")[0].split("-").reverse()[0]
          }/icon.jpg?ts=${(r.fields.update_ts || [0])[0]}`;
          if (r.id.substring(0, 1) === "m") {
            o.image_url_v2 = v2Url;
          } else {
            o.product_icons_v2 = v2Url;
          }
          o.id = r.id;
        }
        if (f === "image_url" || f === "product_icons") {
          o[f] = `${config.cloudFrontPrefix}${r.fields[f][0]}?ts=${
            (r.fields.update_ts || [0])[0]
          }`;
        } else {
          o[f] = r.fields[f][0];
        }
        return o;
      }, {}),
      contactType: r.id.substring(0, 1),
    };
  });
}
