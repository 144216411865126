<template>
  <div id="login">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Sign In</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div v-if="loginMessage">
        <div
          v-if="loginMessage === 'CCTE_BLOCKED_EXCHANGE'"
          class="alert alert-danger rounded-0 fs-6 mt-3"
        >
          <div class="fw-bold mb-2">
            Important announcement for Crescent City Trade Exchange members
          </div>
          <div class="fw-light">
            <small>
              On Tuesday, January 31st at 9PM CST, Crescent City Trade Exchange
              was removed from the Moxey network. As a member of that exchange,
              your access to the Moxey website and app has also been removed.
              <a
                target="_blank"
                href="https://www.moxeyusa.com/cctegtletter"
                class="inline-link"
                >Please read details on this announcment here.</a
              ></small
            >
          </div>
        </div>
        <div
          v-else-if="loginMessage === 'GT_BLOCKED_EXCHANGE'"
          class="alert alert-danger rounded-0 fs-6 mt-3"
        >
          <div class="fw-bold mb-2">
            Important announcement for Geaux Trade members
          </div>
          <div class="fw-light">
            <small>
              On Tuesday, January 31st at 9PM CST, Geaux Trade was removed from
              the Moxey network. As a member of that exchange, your access to
              the Moxey website and app has also been removed.
              <a
                target="_blank"
                href="https://www.moxeyusa.com/cctegtletter"
                class="inline-link"
                >Please read details on this announcment here.</a
              ></small
            >
          </div>
        </div>
        <div v-else class="message">
          {{ loginMessage }}
        </div>
      </div>
      <form @submit.prevent="doLogin">
        <input
          type="text"
          class="form-control"
          v-model="username"
          placeholder="Username"
          :disabled="loggingIn"
          autocorrect="off"
          autocapitalize="none"
          autocomplete="username"
        />
        <input
          type="password"
          class="form-control"
          v-model="password"
          placeholder="Password"
          :disabled="loggingIn"
          autocomplete="current-password"
        />
        <div class="check-control remember-me text-center">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="keep"
            v-model="keep"
          />
          <label class="form-check-label" for="keep"> Keep me signed in</label>
        </div>
        <div class="button-holder text-center">
          <button
            v-if="!loggingIn"
            type="submit"
            class="btn btn-success"
            :disabled="password === '' || username === '' || user"
          >
            Sign In
          </button>
          <button v-else type="button" class="btn btn-success" disabled>
            <i class="fas fa-sync fa-spin"></i> Signing In
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="loggingIn"
            @click="
              () => {
                if (!loggingIn) $emit('clickedShortcut', 'Account');
              }
            "
          >
            Cancel
          </button>
        </div>

        <div class="d-flex justify-content-center">
          <div class="forgot" @click="$emit('clickedShortcut', 'Forgot')">
            Forgot your password?
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <div class="forgot" @click="$emit('clickedShortcut', 'Register')">
            I'm a new user
          </div>
        </div>

        <div
          v-if="
            loginError &&
            !['CCTE_BLOCKED_EXCHANGE', 'GT_BLOCKED_EXCHANGE'].includes(
              loginError
            )
          "
        >
          <div v-if="loginError === 'CCTE'" class="error">
            <div>
              Thank you for your interest in this preview of the new Moxey user
              experience. This preview is not currently available to the members
              of all affiliate exchanges.
            </div>
            <div>
              To login to your account, please visit the legacy system at
              <a href="https://www.quiktrade.net/">quiktrade.net</a>.
            </div>
            <div class="mt-4">
              For additional information please contact the CCTE office at
              504-482-9000.
            </div>
          </div>
          <div v-else class="error text-danger">
            {{ loginError }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  log,
  api,
  gaEvent,
  //gaException,
  gaSet,
  userToOneSignal,
} from "@/utils/utils";
import ls from "local-storage";
import dayjs from "dayjs";
import testAccount from "@/utils/testAccount.js";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      keep: false,
      loginError: undefined,
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loggingIn: (state) => state.app.all.loggingIn,
      loginMessage: (state) => state.app.all.loginMessage,
      appConfig: (state) => state.app.all.appConfig,
      payload: (state) => state.app.all.leftBarPayload,
    }),
  },
  mounted() {},
  methods: {
    cancelLogin() {
      this.$emit("cancelLogin");
    },
    async doLogin() {
      this.loginError = undefined;

      this.updateAppStore({
        loggingIn: true,
      });

      ls.remove("accessToken");

      if (this.username === "moxeytestmode") {
        console.log("test mode", testAccount);
      }

      try {
        const login =
          this.username === "moxeytestmode"
            ? { user: testAccount }
            : await api("post", "core", `/v2/login`, {
                body: {
                  username: this.username,
                  password: this.password,
                  keep: this.keep,
                },
              });

        console.log(login);

        if (this.username === "moxeytestmode") {
          this.updateAppStore({
            testMode: true,
          });
          ls.set("accessToken", "testmode=testmode");
        } else {
          ls.set("accessToken", login.accessToken);
        }

        const isAdmin =
          Object.keys(login.user.rights).reduce((c, k) => {
            if (k.split("|||")[1] === "Admin") {
              if (login.user.rights[k]) c.push(login.user.rights[k]);
            }
            return c;
          }, []).length > 0;

        const isElevated =
          Object.keys(login.user.rights).reduce((c, k) => {
            if (login.user.rights[k]) c.push(login.user.rights[k]);
            return c;
          }, []).length > 0;
        if (
          (!login.user.nextNPSDate || login.user.nextNPSDate < Date.now()) &&
          dayjs().isAfter(
            dayjs(login.user.member.public.joinDate).add(30, "day")
          )
        )
          this.updateAppStore({ showNPS: true });

        this.updateAppStore({
          user: { ...login.user, isAdmin: isAdmin, isElevated: isElevated },
        });

        console.log(login.user);

        const userTNC = login.user.tncVersion;

        const appTNC = this.appConfig && this.appConfig.tncVersion;

        if (this.payload && this.payload.goto) {
          this.$router.push(this.payload.goto.path);
          this.$emit("cancel");
        } else if ((!userTNC && appTNC) || userTNC < appTNC) {
          this.$emit("clickedShortcut", "TNC");
        } else if (!login.user.contact.public.emailValidated) {
          this.updateAppStore({
            leftBarComp: "ConfirmInfo",
          });
        } else {
          this.$emit("showAccount");
        }

        gaSet({ user_id: this.username });

        log({
          m: "User logged in.",
        });
        gaEvent("login");

        userToOneSignal(this.user);

        this.updateStateOnLoad();
      } catch (e) {
        console.log(e);
        //Handle errors from proxy
        const msg = e.error || e.message || e;
        console.log(msg);
        if (
          msg ===
          "Invalid UpdateExpression: An expression attribute value used in expression is not defined; attribute value: :contact"
        ) {
          this.loginError =
            "Invalid username or password. Are your username and password correctly capitalized?";
        } else {
          this.loginError = msg;
        }
        log({
          m: `Login error: ${msg}`,
        });
        //gaException(`Login error: ${msg}`);
      } finally {
        this.updateAppStore({
          loggingIn: false,
        });
      }
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
      updateStateOnLoad: "app/updateStateOnLoad",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
#login {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 30px;
  line-height: 30px;
}
.sidebar-content {
  padding: 30px 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.btn-success {
  margin: 35px 0 15px;
  border-color: var(--mx-dark);
  background-color: var(--mx-dark);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 0 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
}

.whats-this {
  cursor: pointer;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.message {
  font-size: 20px;
  line-height: 28px;
  padding: 45px 30px 0;
  text-align: start;
  width: 100%;
}

.forgot {
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  border-bottom: 1px dotted;
  margin: 0 0 30px 0;
}

.remember-me {
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0 0 0;
}

.remember-me input {
  margin-top: 0;
}

.remember-me label {
  margin: 0 0 0 10px;
}
</style>
