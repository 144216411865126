<template>
  <div
    :class="{ 'my-3': itemArray.length > 5 }"
    v-if="itemArray.length || showNoResults"
  >
    <h6
      :class="[
        {
          'header-small': column,
          feature: itemArray.length <= 5 /*&& desktop*/ && !column,
          collection: isCollection /*&& desktop*/,
          news: isNews /*&& desktop*/,
        },
        'clamp-1 d-md-none pb-0 mb-0',
      ]"
    >
      {{ loading ? loadingTitle : title }}
    </h6>
    <h4
      v-if="desktop && (itemArray.length > 5 || column)"
      :class="[
        'clamp-1 d-none d-md-flex',
        {
          'mt-4': desktop && itemArray.length > 5,
          'header-small': column && !desktop,
        },
      ]"
    >
      {{ loading ? loadingTitle : title }}
    </h4>
    <div
      :class="{
        'result-row d-flex': !desktop,
        feature: itemArray.length <= 5 /*&& desktop*/ && !column,
        collection: isCollection /*&& desktop*/,
        news: isNews /*&& desktop*/,
      }"
      :id="groupId"
    >
      <div
        v-if="itemArray.length"
        :class="[
          {
            'tiles d-flex': !desktop,
            row: desktop && !column,
            'flex-column': column,
            'w-100': column,
          },
        ]"
        :style="
          (!desktop || column) && {
            'min-width': column
              ? 'unset'
              : ['square', 'circle'].includes(tileType)
              ? `${itemArray.length < 5 ? itemArray.length * 150 : `600`}px`
              : `${itemArray.length < 5 ? itemArray.length * 250 : `1000`}px`,
            'min-height':
              ['square', 'circle'].includes(tileType) &&
              `${
                itemArray.length > 5
                  ? 600 / 5 - 20
                  : Math.min(500 / itemArray.length, 130)
              }px`,
          }
        "
      >
        <div v-if="desktop && itemArray.length <= 5 && !column" class="col-2">
          <svg :viewBox="`0 0 100 100`" @load="(e) => log(e)">
            <switch>
              <foreignObject x="0" y="0" width="100" height="100">
                <div
                  class="d-flex flex-column align-items-start justify-content-center h-100"
                  xmlns="http://www.w3.org/1999/xhtml"
                >
                  <div
                    class="fw-bold text-muted mb-0 clamp-3"
                    style="font-size: 0.9em"
                    v-html="title"
                  ></div>
                </div>
              </foreignObject>

              <text x="20" y="20">
                {{ moreText2 }}
              </text>
            </switch>
          </svg>
        </div>

        <div
          v-for="(box, b) in itemArray"
          :key="`${box?.name}_${box?.storefrontId}_b`"
          :style="
            (!desktop || column) && {
              'min-width': column
                ? 'unset'
                : ['square', 'circle'].includes(tileType)
                ? `${
                    (itemArray.length > 5
                      ? 600 / 5
                      : Math.min(600 / itemArray.length, 150)) +
                    (b === itemArray.length - 1 ? 10 : 0)
                  }px`
                : `${
                    (itemArray.length > 6
                      ? 1000 / 6
                      : Math.min(1000 / itemArray.length, 250)) +
                    (b === itemArray.length - 1 ? 10 : 0)
                  }px`,
              'padding-right': b === itemArray.length - 1 && '20px',
            }
          "
          :class="[
            column ? 'flex-row flex-fill' : 'flex-column',
            {
              'mt-4': b > 5 && desktop,
              'd-flex': !desktop,
              'col-2':
                (isCollection && desktop && !column) ||
                (desktop && itemArray.length >= 4 && !column),
              'col-3':
                !isCollection && desktop && itemArray.length < 4 && !column,
            },
          ]"
          @click="
            () => {
              if (box.onClickAction) box.onClickAction();
              else if (box.type === 'showMore') {
                if (!location) {
                  $emit('needgeo', clickedMore);
                } else {
                  clickedMore(location, seed);
                }
              } else if (isCollection) {
                selectedCollection = box;
                if (!location) {
                  $emit('needgeo', clickedCollection);
                } else {
                  clickedCollection(location);
                }
              } else if (box.storefrontId) {
                $emit('selectstorefront', box);
                /*$router.push(
                encodeURI(
                  `/directory/storefronts/${box.name.replace(/[^A-z]/g, '-')}/${
                    box.storefrontId
                  }`
                )
              );*/
              }
            }
          "
        >
          <template v-if="box?.type === 'showMore'">
            <div v-if="column" class="cursor-pointer">
              <small>{{ moreText }}</small>
            </div>
            <div
              v-else
              class="border rounded h-100 text-white more d-flex p-3 cursor-pointer"
              :style="{
                'max-height': '260px',
              }"
            >
              <div class="d-flex flex-column justify-content-center w-100">
                <!-- <div class="fw-light">{{ moreText }}</div>
              <div class="fw-bold">{{ moreText2 }}</div> -->
                <!-- <svg viewBox="0 0 130 25">
                <switch>
                  <foreignObject x="0" y="0" width="100" height="200">
                    <p xmlns="http://www.w3.org/1999/xhtml" class="fw-light">
                      {{ moreText }}
                    </p>
                  </foreignObject>

                  <text x="20" y="20">
                    {{ moreText2 }}
                  </text>
                </switch>
              </svg> -->
                <svg
                  v-if="moreText2"
                  :viewBox="`0 0 100 100`"
                  @load="(e) => log(e)"
                >
                  <switch>
                    <foreignObject x="0" y="0" width="100" height="100">
                      <div
                        class="d-flex flex-column align-items-start justify-content-center h-100"
                        xmlns="http://www.w3.org/1999/xhtml"
                      >
                        <div class="fw-light">
                          <small>
                            {{ moreText }}
                          </small>
                        </div>
                        <div
                          class="fw-bold mb-0 clamp-3"
                          v-html="moreText2"
                        ></div>
                      </div>
                    </foreignObject>

                    <text x="20" y="20">
                      {{ moreText2 }}
                    </text>
                  </switch>
                </svg>
              </div>
            </div>
          </template>
          <template v-else-if="isCollection">
            <div
              :class="[
                'border rounded h-100 text-white more d-flex p-3 cursor-pointer',
                { 'rounded-circle': tileType === 'circle' },
              ]"
            >
              <div class="d-flex flex-column justify-content-center w-100">
                <!-- <div class="fw-light">{{ moreText }}</div>
              <div class="fw-bold">{{ moreText2 }}</div> -->

                <svg :viewBox="`0 0 100 100`">
                  <switch>
                    <foreignObject x="0" y="0" width="100" height="100">
                      <div
                        class="d-flex align-items-center justify-content-center h-100"
                        xmlns="http://www.w3.org/1999/xhtml"
                      >
                        <div
                          :class="[
                            'mb-0',
                            'fw-bold clamp-3',
                            { 'text-center': tileType === 'circle' },
                          ]"
                          v-html="box.title"
                        ></div>
                      </div>
                    </foreignObject>

                    <text x="20" y="20">
                      {{ box.title }}
                    </text>
                  </switch>
                </svg>
              </div>
            </div>
          </template>

          <template v-else>
            <result-item :item="box" :i="b" :row="column" :key="desktop" />
          </template>
        </div>
      </div>
      <div v-else class="no-results fs-5 text-muted fw-bold">
        No Top Picks Found
        <div class="fs-6 fw-lighter">
          See below for other potential matches.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shortUUID from "short-uuid";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import ResultItem from "./ResultItem.vue";

//let test = "hello";

export default {
  name: "ResultsGroup",
  components: { ResultItem },
  data() {
    return {
      ...this.group,
      groupId: shortUUID.generate(),
      count: this.group?.resultCount,
      hits: undefined,
      loading: true,
      searchDebounce: undefined,
      selectedCollection: undefined,
    };
  },
  props: {
    group: Object,
    trigger: Number,
    exclude: Object,
    user: Object,
    isEstablished: Boolean,
    desktop: Boolean,
    rowNum: Number,
    showNoResults: Boolean,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    exchangeId() {
      return this.user?.member.auth?.[0].auId;
    },
    itemArray() {
      if (this.items) {
        return this.items;
      }
      if (!this.hits) return new Array(this.count);

      let tempHits = this.hits[this.index];

      for (const k in this.exclude || {}) {
        tempHits = tempHits.filter((f) => f[k] !== this.exclude[k]);
      }

      if (!tempHits.length) return [];

      return [...tempHits.slice(0, this.resultCount - 1), { type: "showMore" }];
    },
    ...mapState({}),
  },
  watch: {
    async trigger(post) {
      if (post && this.searchFunction) {
        clearTimeout(this.searchDebounce);

        for (const k in this.group) {
          this[k] = this.group[k];
        }

        this.searchDebounce = setTimeout(async () => {
          this.$emit("startSearch", this.id);

          this.hits = await this.searchFunction({
            predefinedSearchName: this.predefinedSearchName,
            categories: this.categories,
            sectors: this.sectors,
            search: this.search,
            geo: this.location?.geo,
            seed: this.seed,
            exchangeId: this.exchangeId,
          });

          this.loading = false;
          this.$emit("completeSearch", this.id);

          this.count = this.hits.count;

          this.$emit("remixtags", {
            featuredCategories: this.hits.featuredCategories,
            featuredSectors: this.hits.featuredSectors,
            // relatedCategories: this.hits.relatedCategories,
            // relatedSectors: this.hits.relatedSectors,
          });

          this.$nextTick(() => {
            const el = document.getElementById(this.groupId);
            if (el) el.scrollTop = 0;
          });
        }, 500);
      }
    },
  },
  methods: {
    log(e) {
      console.log(e);
    },
    clickedMore(location) {
      //Issue new seed when searching more
      this.goToSearch({
        index: this.index,
        predefinedSearchName: this.predefinedSearchName,
        location,
        search: this.search,
        categories: this.categories,
        sectors: this.sectors,
        title: this.moreTitle || this.title,
        exchangeId: this.exchangeId,
      });
    },
    clickedCollection(location) {
      this.goToSearch({
        index: this.selectedCollection.index,
        predefinedSearchName: this.selectedCollection.predefinedSearchName,
        location,
        search: this.selectedCollection.search,
        categories: this.selectedCollection.categories,
        sectors: this.selectedCollection.sectors,
        title: this.selectedCollection.title,
        exchangeId: this.exchangeId,
      });
    },
    goToSearch({
      index,
      predefinedSearchName,
      location,
      search,
      categories,
      sectors,
      title,
      exchangeId,
    }) {
      this.$emit("navigateaway");
      this.$router.push({
        path: encodeURI(
          `/directory/search/${index}/${predefinedSearchName}/${
            location.city
          }/${location.geo.lat}/${location.geo.lon}/${Date.now()}/0/`
        ),
        query: {
          search,
          categories,
          sectors,
          title,
          exchangeId,
        },
      });
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {
    if (this.items) {
      this.count = this.items.length;
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.result-text,
.header-small {
  font-size: 0.8em;
}

.tiles > div {
  padding: 0 10px;
}

.tiles {
  margin: 0 -10px;
}

.more {
  position: relative;
  overflow: hidden;
}

.more > div {
  z-index: 1;
}

.more::after {
  z-index: 0;
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: -75%;
  background: rgb(0, 175, 108);
  background: radial-gradient(
    circle,
    rgba(0, 175, 108, 1) 0%,
    rgba(3, 33, 26, 1) 100%
  );
  content: "";
}

.no-results {
  padding: 0 20px;
}

.feature {
  background-color: var(--mx-lightgray);
  margin-right: -20px;
  margin-left: -20px;
  padding-right: calc(var(--bs-gutter-x) * 1.5);
  padding-left: calc(var(--bs-gutter-x) * 1.5);
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.collection {
  background-color: var(--mx-blue);
}

.news {
  background-color: #ffe966;
}

@media (min-width: 768px) {
  .feature {
    background-color: var(--mx-lightgray);
    margin-right: -20px;
    margin-left: -20px;
    padding-right: calc(var(--bs-gutter-x) * 1.5);
    padding-left: calc(var(--bs-gutter-x) * 1.5);
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
}
</style>
