<template>
  <div
    id="left-bar"
    class="left-bar"
    :class="{ active: showLeftBar && !showVC, noScroll: showVC }"
  >
    <component
      :is="comp"
      @show-login="cycleBar('Login')"
      @show-account="cycleBar('Account')"
      @cancel="resetBar"
      @clicked-shortcut="cycleBar"
      @showVC="
        {
          showVC = true;
        }
      "
    />
  </div>
  <virtual-card v-if="showVC" @hide-virtual="showVC = false" />
  <div class="cover"></div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Account from "@/components/CoreApp/User/Account.vue";
import Login from "@/components/CoreApp/User/Login.vue";
import Forgot from "@/components/CoreApp/User/Forgot.vue";
import PasswordUpdate from "@/components/CoreApp/User/PasswordUpdate.vue";
import Register from "@/components/CoreApp/User/Register.vue";
import SendMx from "@/components/CashApp/SendMx.vue";
import RequestMx from "@/components/CashApp/RequestMx.vue";
import CRAInfo from "@/components/CashApp/CRAInfo.vue";
import CRInfo from "@/components/CashApp/CRInfo.vue";
import AutoLoad from "@/components/CashApp/AutoLoad.vue";
import TNC from "@/components/CashApp/TNC.vue";
import BuyingPowerInfo from "@/components/CashApp/BuyingPowerInfo.vue";
import BalanceInfo from "@/components/CashApp/BalanceInfo.vue";
import CreditLimitInfo from "@/components/CashApp/CreditLimitInfo.vue";
import ChargeCard from "@/components/CashApp/ChargeCard.vue";
import LoadCRA from "@/components/CashApp/LoadCRA.vue";
import Share from "@/components/Directory/Member/Share.vue";
import { shallowRef } from "vue";
import { log } from "@/utils/utils";
import AddCard from "../CashApp/AddCard.vue";
import ContactUpdate from "./User/ContactUpdate.vue";
import ValidateEmail from "./User/ValidateEmail.vue";
import ConfirmInfo from "./User/ConfirmInfo.vue";
import FCInfo from "../CashApp/FCInfo.vue";
import SpendingTargetInfo from "../CashApp/SpendingTargetInfo.vue";
import CommsPreferences from "./User/CommsPreferences.vue";
import CommunityFeeInfo from "../CashApp/CommunityFeeInfo.vue";
import Invite from "./Invite/Invite.vue";
import VirtualCard from "@/components/Account/VirtualCard.vue";

export default {
  name: "LeftBar",
  components: {
    VirtualCard,
  },
  data() {
    return {
      comp: shallowRef(Account),
      showVC: false,
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      leftBarComp: (state) => state.app.all.leftBarComp,
      user: (state) => state.app.all.user,
    }),
  },
  mounted() {},
  watch: {
    leftBarComp(post) {
      console.log(post);
      if (post) {
        this.cycleBar(post.name || post, post.payload);
        this.updateAppStore({ leftBarPayload: post.payload });
      }
      this.updateAppStore({
        leftBarComp: undefined,
      });
    },
  },
  methods: {
    ...mapActions("app", ["updateAppStore"]),
    async setComponent(compName, doNotToggle) {
      let toComponent;
      let message;
      this.updateAppStore({ barsLocked: false });
      if (
        ["SendMx", "ChargeCard", "LoadCRA"].includes(compName) &&
        !this.user
      ) {
        compName = "Login";
      }

      console.log(compName);

      if (compName === "Login") {
        toComponent = Login;
        message = "Visited Login screen.";
      } else if (compName === "Forgot") {
        toComponent = Forgot;
      } else if (compName === "PasswordUpdate") {
        toComponent = PasswordUpdate;
      } else if (compName === "ContactUpdate") {
        toComponent = ContactUpdate;
      } else if (compName === "CommsPreferences") {
        toComponent = CommsPreferences;
      } else if (compName === "ValidateEmail") {
        toComponent = ValidateEmail;
      } else if (compName === "ConfirmInfo") {
        toComponent = ConfirmInfo;
      } else if (compName === "Register") {
        toComponent = Register;
        message = "Visited forgot screen.";
      } else if (compName === "Share") {
        toComponent = Share;
        message = "Visited share screen.";
      } else if (compName === "SendMx") {
        toComponent = SendMx;
        message = "Visited Send Moxey screen.";
      } else if (compName === "RequestMx") {
        toComponent = RequestMx;
        message = "Visited Request Moxey screen.";
      } else if (compName === "CRAInfo") {
        toComponent = CRAInfo;
      } else if (compName === "Invite") {
        toComponent = Invite;
        message = "Visited CRA info screen.";
      } else if (compName === "CRInfo") {
        toComponent = CRInfo;
        message = "Visited CR info screen.";
      } else if (compName === "SpendingTargetInfo") {
        toComponent = SpendingTargetInfo;
        message = "Visited Spending Target info screen.";
      } else if (compName === "CommunityFeeInfo") {
        toComponent = CommunityFeeInfo;
        message = "Visited Community Fee info screen.";
      } else if (compName === "FCInfo") {
        toComponent = FCInfo;
        message = "Visited FC info screen.";
      } else if (compName === "AutoLoad") {
        toComponent = AutoLoad;
        message = "Visited CRA autoload screen.";
      } else if (compName === "AddCard") {
        toComponent = AddCard;
        message = "Visited CRA autoload screen.";
      } else if (compName === "TNC") {
        this.updateAppStore({ barsLocked: true });
        toComponent = TNC;
        message = "Sent to TNC screen.";
      } else if (compName === "BuyingPowerInfo") {
        toComponent = BuyingPowerInfo;
        message = "Visited Buying Power info screen.";
      } else if (compName === "BalanceInfo") {
        toComponent = BalanceInfo;
        message = "Visited Moxey Balance info screen.";
      } else if (compName === "CreditLimitInfo") {
        toComponent = CreditLimitInfo;
        message = "Visited Credit Limit info screen.";
      } else if (compName === "ChargeCard") {
        toComponent = ChargeCard;
        message = "Visited Charge a Card screen.";
      } else if (compName === "LoadCRA") {
        toComponent = LoadCRA;
        message = "Visited Load CRA screen.";
      } else toComponent = Account;

      this.comp = shallowRef(toComponent);

      if (!doNotToggle) {
        this.$nextTick(() => {
          this.updateAppStore({
            showLeftBar: true,
            cyclingBars: false,
          });
        });

        log({
          m: message,
        });
      }
    },
    cycleBar(comp) {
      this.updateAppStore({
        cyclingBars: true,
        showLeftBar: false,
      });
      setTimeout(() => {
        this.setComponent(comp.name || comp);
        document.getElementById("left-bar").scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
      }, 200);
    },
    async resetBar() {
      this.updateAppStore({
        showLeftBar: false,
      });
      setTimeout(() => {
        this.setComponent("Account", true);
      }, 200);
      log({
        m: "Closed account bar. ",
      });
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
.left-bar {
  position: fixed;
  width: 100%;
  max-width: 475px;
  height: 100%;
  max-height: calc(100vh);
  overflow-y: auto;
  top: 0;
  background-color: white;
  z-index: 1051;
  transform: translate(-100%, 0);
  transition: transform 0.2s ease-in;
}

#left-bar.active {
  transform: translate(0, 0);
  transition: transform 0.25s ease-out;
}

.cover {
  z-index: 1052;
  position: fixed;
  height: 100%;
  width: calc(50vw - 720px);
  top: 0;
  left: 0;
  background-color: #03211a;
}
</style>
