<template>
  <div v-if="removed" class="text-center">
    <p class="fs-5 text-center">
      You've Successfully Unenrolled from Auto-Reload
    </p>
    <p class="fw-lighter">
      Feel free to reenroll with a different amount or payment card at any time.
    </p>
    <mx-button
      btnClass="btn btn-success"
      @click="removed = false"
      :height="38"
      :padX="30"
      >Got it!
    </mx-button>
  </div>
  <div v-else-if="!source">
    <div>
      <step-through
        :stepLength="preSelectCard ? 3 : 4"
        :step="step"
        @goTo="(i) => (step = startIndex + i)"
        :noProgress="wallet.length === 0"
      >
        <template #step1>
          <!-- <p class="text-danger text-center fs-5">
            <i class="fa-duotone fa-square-xmark"></i> Not Enrolled
          </p> -->
          <p>
            Avoid denied transactions by enrolling
            {{ preSelectCard ? "this card " : "" }}in Cash Reserve Auto-Reload!
          </p>
          <p class="fw-lighter">
            Once you are enrolled, Cash Reserve Auto-Reload will automatically
            charge your payment card and top up your account once the balance
            goes below the threshold you set.
          </p>
          <div v-if="wallet.length === 0">
            <p class="fw-lighter">
              <em>
                Your wallet is empty. You must first add a payment card to your
                wallet by
                <span
                  class="mx-info-link"
                  @click="
                    updateAppStore({
                      leftBarComp: 'AddCard',
                    })
                  "
                  >clicking here and adding a payment card.</span
                >.
              </em>
            </p>
          </div>
          <div v-else>
            <p class="fw-lighter">To enroll, first select a payment card.</p>

            <select
              id="card"
              name="card"
              v-model="enrollCard"
              class="form-select mb-3"
              placeholder="Auto-Reload Payment Card"
              :disabled="processing"
            >
              <option
                v-for="(c, i) in wallet.reverse()"
                :key="i.customerVaultId"
                :value="c"
              >
                {{ c.cardTypeNice }} ending in
                {{
                  c.ccNumber.substring(c.ccNumber.length - 4, c.ccNumber.length)
                }}
              </option>
            </select>
            <div class="text-center">
              <mx-button
                btnClass="btn btn-success"
                @click="step++"
                :height="48"
                :padX="30"
                :disabled="!enrollCard"
                >Next
              </mx-button>
            </div>
          </div>
        </template>
        <template #step2>
          <p class="text-center">
            <span class="fw-bolder">Enrolling: </span>
            {{ enrollCard.cardTypeNice }} ending in
            {{
              enrollCard.ccNumber.substring(
                enrollCard.ccNumber.length - 4,
                enrollCard.ccNumber.length
              )
            }}
          </p>

          <p class="fw-lighter">
            {{ preSelectCard ? "First" : "Next" }}, select a trigger amount.
            Once your balance goes below this amount, an auto-reload transction
            will process.
          </p>

          <div class="mb-3">
            <div class="input-group">
              <span class="input-group-text"
                ><i class="fas fa-dollar-sign"></i
              ></span>
              <input
                type="number"
                class="form-control"
                inputmode="decimal"
                :class="{ 'is-invalid': invalidFields.trigger }"
                id="trigger"
                step="0.01"
                name="amount"
                v-model="trigger"
                placeholder="Trigger Amount"
                @scroll="
                  (e) => {
                    e.preventDefault();
                  }
                "
                @keydown="
                  (e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                      e.preventDefault();
                  }
                "
                @change="validateTrigger"
                @blur="validateTrigger"
                @focus="
                  () => {
                    invalidFields.trigger = undefined;
                    trigger = undefined;
                  }
                "
                aria-label="CRA Auto-Reload Trigger Amount"
                :disabled="processing"
              />
            </div>
            <div class="text-danger mt-2" v-show="invalidFields.trigger">
              <small>{{ invalidFields.trigger }}</small>
            </div>
          </div>
          <div class="text-center">
            <mx-button
              btnClass="btn btn-success"
              @click="step++"
              :height="48"
              :padX="30"
              :disabled="!trigger || invalidFields.trigger"
              >Next
            </mx-button>
          </div>
        </template>
        <template #step3>
          <p class="text-center">
            <span class="fw-bolder">Enrolling: </span>
            {{ enrollCard.cardTypeNice }} ending in
            {{
              enrollCard.ccNumber.substring(
                enrollCard.ccNumber.length - 4,
                enrollCard.ccNumber.length
              )
            }}
          </p>
          <p class="fw-lighter">
            Then, select a auto-reload amount. This is the amount that will be
            auto-reloaded into your Cash Reserve Account.
          </p>

          <div class="mb-3">
            <div class="input-group">
              <span class="input-group-text"
                ><i class="fas fa-dollar-sign"></i
              ></span>
              <input
                type="number"
                @scroll="
                  (e) => {
                    e.preventDefault();
                  }
                "
                @keydown="
                  (e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                      e.preventDefault();
                  }
                "
                class="form-control"
                inputmode="decimal"
                :class="{ 'is-invalid': invalidFields.amount }"
                id="amount"
                step="0.01"
                name="amount"
                v-model="amount"
                placeholder="Amount"
                @change="validateAmount"
                @blur="validateAmount"
                @focus="
                  () => {
                    invalidFields.amount = undefined;
                    amount = undefined;
                  }
                "
                aria-label="CRA Auto-Reload Amount"
                :disabled="processing"
              />
            </div>
            <div class="text-danger mt-2" v-show="invalidFields.amount">
              <small>{{ invalidFields.amount }}</small>
            </div>
          </div>
          <div class="text-center">
            <mx-button
              btnClass="btn btn-success"
              @click="step++"
              :height="48"
              :padX="30"
              :disabled="!amount || invalidFields.amount"
              >Next
            </mx-button>
          </div>
        </template>
        <template #step4>
          <p class="text-center">
            <span class="fw-bolder">Enrolling: </span>
            {{ enrollCard.cardTypeNice }} ending in
            {{
              enrollCard.ccNumber.substring(
                enrollCard.ccNumber.length - 4,
                enrollCard.ccNumber.length
              )
            }}
          </p>
          <p class="fw-lighter">
            Finally, acknowlege the automatic payment agreement by checking the
            box below and click "Complete Enrollment."
          </p>

          <hr />
          <p>
            <em>
              I authorize Moxey to charge my credit card on file for the
              agreed-upon auto-load. Once my Cash Reserve Account balance falls
              below ${{ trigger }} my card on file will be charged ${{
                amount
              }}. I understand that my billing information will be securely
              saved by the card processor for future transactions.
            </em>
          </p>

          <hr />

          <div class="check-control remember-me my-3 d-flex align-items-center">
            <input
              class="form-check-input me-3"
              type="checkbox"
              id="authorize"
              v-model="authorize"
              :disabled="processing"
            />
            <label class="form-check-label fw-lighter" for="authorize">
              I agree and authorize future Cash Reserve Account auto-reload
              transactions.</label
            >
          </div>
          <div class="text-center">
            <mx-button
              btnClass="btn btn-success"
              @click="authorizeCard"
              :height="48"
              :padX="30"
              :disabled="
                processing || !authorize || !amount || invalidFields.amount
              "
              ><i class="fas fa-square-check"></i> Complete Enrollment
            </mx-button>
            <div class="text-danger mt-2" v-show="error">
              <small>{{ error }}</small>
            </div>
          </div>
        </template>
      </step-through>
    </div>
  </div>
  <div v-else-if="source">
    <p class="text-success text-center fs-5">
      <i class="fa-duotone fa-square-check"></i> Enrolled
    </p>
    <p>
      You are enrolled in Cash Reserve Account auto-reload using your
      {{ source?.cardTypeNice }} card ending in
      {{
        source?.ccNumber?.substring(
          source?.ccNumber?.length - 4,
          source?.ccNumber?.length
        )
      }}. Once your account balance falls below ${{
        source?.autoReloadAmount
      }}
      your card on file will be charged ${{ source?.autoReloadAmount }}.
    </p>
    <div v-if="source.declined">
      <hr />

      <div class="alert alert-warning" role="alert">
        <h6 class="alert-heading">Heads Up!</h6>
        <p>
          The card associated with the auto-reload transaction was declined the
          last time it was charged. Please confirm that the card is still valid
          and re-enroll in auto-reload if needed.
        </p>
      </div>
    </div>
  </div>

  <!-- <button @click="testRun">Test Run</button> -->
</template>

<script>
import currency from "currency.js";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import MxButton from "../Shared/MxButton.vue";
import { api } from "@/utils/utils";
import StepThrough from "../General/StepThrough.vue";

//let test = "hello";

export default {
  name: "AutoReload",
  components: { MxButton, StepThrough },
  data() {
    return {
      enrollCard: this.preSelectCard,
      processing: false,
      amount: currency(50).value,
      trigger: currency(50).value,
      invalidFields: {},
      authorize: false,
      error: undefined,
      removed: false,
      step: this.preSelectCard ? 1 : 0,
      startIndex: this.preSelectCard ? 1 : 0,
    };
  },
  props: {
    preSelectCard: {
      type: Object,
      default: undefined,
    },
  },

  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      testMode: (state) => state.app.all.testMode,
    }),
    wallet() {
      return (
        this.user &&
        Object.keys(this.user.wallet).reduce((col, k) => {
          if (this.user.wallet[k]) {
            const val = JSON.parse(JSON.stringify(this.user.wallet[k]));

            val.expired =
              parseInt(
                `${val.ccExp.substring(2, 4)}${val.ccExp.substring(0, 2)}`
              ) <
              parseInt(
                new Date()
                  .toISOString()
                  .split("T")[0]
                  .replace(/-/g, "")
                  .slice(2, 6)
              );

            col.push(val);
          }
          console.log(col);
          return col;
        }, [])
      );
    },
    source() {
      const s = this.wallet && this.wallet.find((w) => w.autoReloadAmount);
      console.log("updating autoCRA source", s);
      return s;
    },
  },
  watch: {
    // wallet(post) {
    //   if (!post?.find((w) => w.autoReloadAmount)) {
    //     this.enrollCard = undefined;
    //     this.amount = currency(50).value;
    //     this.trigger = currency(50).value;
    //     this.authorize = false;
    //     this.step = 0;
    //   }
    // },
  },
  methods: {
    validateAmount() {
      this.invalidFields.amount = undefined;
      console.log("validation", this.amount);
      if (currency(this.amount).value < 50) {
        this.invalidFields.amount =
          "The minimum Cash Reserve auto-reload amount is $50.";
      } else if (!/^\d*\.?\d*$/.test(this.amount)) {
        this.invalidFields.amount = "Invalid amount.";
        this.amount = "";
      } else {
        this.amount = currency(this.amount).value;
      }
    },
    validateTrigger() {
      this.invalidFields.trigger = undefined;
      console.log("validation", this.trigger);
      if (currency(this.trigger).value < 50) {
        this.invalidFields.trigger =
          "The minimum Cash Reserve auto-reload trigger amount is $50.";
      } else if (!/^\d*\.?\d*$/.test(this.trigger)) {
        this.invalidFields.amount = "Invalid amount.";
        this.trigger = "";
      } else {
        this.trigger = currency(this.trigger).value;
      }
    },
    async authorizeCard() {
      this.processing = true;
      const tempWallet = JSON.parse(JSON.stringify(this.user.wallet));

      for (const w in tempWallet) {
        tempWallet[w].autoReloadAmount =
          w === this.enrollCard.customerVaultId
            ? currency(this.amount).value
            : undefined;
        tempWallet[w].autoReloadTrigger =
          w === this.enrollCard.customerVaultId
            ? currency(this.trigger).value
            : undefined;
      }

      try {
        if (this.testMode)
          throw { error: "This is a test account and will not process Moxey" };
        await api("post", "banking", `/v2/autoReloadSignup/`, {
          body: {
            customerVaultId: this.enrollCard.customerVaultId,
            autoReloadAmount: currency(this.amount).value,
            autoReloadTrigger: currency(this.trigger).value,
          },
        });

        console.log("complete");
        this.$emit("enrollComplete");

        this.updateAppStore({
          user: { ...this.user, wallet: tempWallet },
        });
      } catch (e) {
        this.error = e.message || e;
      }

      this.processing = false;
    },
    async deAuthCard() {
      this.processing = true;
      const tempWallet = JSON.parse(JSON.stringify(this.user.wallet));

      for (const w in tempWallet) {
        tempWallet[w].autoReloadAmount = undefined;
      }

      try {
        if (this.testMode)
          throw { error: "This is a test account and will not process Moxey" };
        await api("post", "banking", `/v2/autoReloadSignup/`, {
          body: {
            customerVaultId: this.source.customerVaultId,
          },
        });

        this.updateAppStore({
          user: { ...this.user, wallet: tempWallet },
        });
      } catch (e) {
        this.error = e.message || e;
      }

      this.enrollCard = undefined;
      this.amount = currency(50).value;
      this.trigger = currency(50).value;
      this.authorize = false;
      this.step = 0;

      this.processing = false;
      this.removed = true;

      setTimeout(() => {
        this.removed = false;
      }, 5000);
    },
    // async testRun() {
    //   await api("post", "banking", `/v2/runAutoReload/`, {});
    // },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.form-check-input {
  padding: 10px;
  height: 16px;
  width: 16px;
  margin: 0;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-black);
}

select,
input {
  font-size: 24px;
}
</style>
