<template v-if="rows">
  <template v-for="(row, r) in rows" :key="row.id">
    <div :class="{ 'mt-3': r > 0 }">
      <result-group
        :desktop="desktop"
        :group="{
          ...row,
          location,
          seed,
        }"
        :trigger="searchTrigger"
        :exclude="{
          storefrontId: storefront?.storefrontId,
        }"
        @needgeo="(callback) => $emit('needgeo', callback)"
        @selectstorefront="
          (s) => {
            $emit('selectstorefront', s);
          }
        "
        @navigateaway="$emit('navigateaway')"
      />
    </div>
  </template>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import { searchStorefronts } from "../search/storefrontGraphql";
import ResultGroup from "../search/ResultGroup";

//let test = "hello";

export default {
  name: "StorefrontExtra",
  components: {
    ResultGroup,
  },
  data() {
    return {
      fixedRows: [
        {
          id: "categories",
          title: `More Like This Member`,
          loadingTitle: "Finding More Members ...",
          moreText: "More",
          moreText2: "Like This",
          predefinedSearchName: "searchStorefrontsByTags",
          resultCount: this.desktop ? 6 : 12,
          searchFunction: searchStorefronts,
          categories: this.storefront.categories,
          geoRequired: true,
          index: "storefronts",
        },
        {
          id: "nearbyMembers",
          title: "Nearby Members",
          loadingTitle: "Finding Nearby Members ...",
          moreText: "More",
          moreText2: "Nearby",
          predefinedSearchName: "searchNearbyStorefronts",
          resultCount: this.desktop ? 6 : 12,
          searchFunction: searchStorefronts,
          geoRequired: true,
          index: "storefronts",
        },
      ],

      rows: undefined,
      searchTrigger: 0,
      selectedStorefront: undefined,
      location: undefined,
    };
  },
  props: {
    storefront: Object,
    trigger: Number,
    desktop: Boolean,
  },
  async mounted() {
    this.resetSearch();
  },
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {
    storefront(post) {
      if (post) {
        this.resetSearch();
      }
    },
  },
  methods: {
    resetSearch() {
      this.fixedRows[0].categories = this.storefront.categories;
      this.location = this.storefront.location;

      if (this.location?.geo?.distance) delete this.location.geo.distance;

      this.rows = this.fixedRows;
      this.$nextTick(() => this.searchTrigger++);
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped></style>
