import { createStore, createLogger } from "vuex";
import user from "./modules/user";
import notifications from "./modules/notifications";
import app from "./modules/app";
import search from "./modules/search";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    user,
    notifications,
    search,
    app,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
