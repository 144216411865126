<template>
  <span class="fa-layers fa-fw">
    <i
      class="fa-thin fa-bolt text-warning"
      data-fa-transform="right-2 grow-4"
    ></i>
    <i class="fak fa-mx-sign-fat" data-fa-transform="shrink-5 right-2"> </i
  ></span>
</template>

<script>
//let test = "hello";

export default {
  name: "FlashCashIcon",
  props: {
    fat: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
