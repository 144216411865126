<template>
  <div
    id="app-backdrop"
    :class="{ active: showLeftBar || showRightBar || cyclingBars }"
    @click="if (!barsLocked) $emit('closeBars');"
  ></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Backdrop",
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      showRightBar: (state) => state.app.all.showRightBar,
      cyclingBars: (state) => state.app.all.cyclingBars,
      barsLocked: (state) => state.app.all.barsLocked,
    }),
  },
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
#app-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 90;
  backdrop-filter: blur(0.5px);
  display: none;
  transition: background-color, display 0.2s ease-in;
}

#app-backdrop.active {
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: background-color, display 0.25s ease-out;
}
</style>
