<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Community Fees</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="text">
        <div>
          Moxey communities are most healthy when Moxey is freely and frequently
          spent between members. Community Fees are a tool that helps make sure
          that Moxey is available for everyone to earn and constantly flows in a
          healthy manner.
        </div>
        <div>
          Community Fees are charged in Moxey and assessed when a member
          consistently spends less than they earn. Members who meet their
          Spending Targets are not charged Community Fees.
        </div>
        <div>
          Your Community Fee is calculated at the beginning of each month and
          takes into account your current Moxey balance, earnings history, and
          spending habits. If you are unable to meet your Spending Target for
          the month, you will be charged your Community Fee at the beginning of
          the next month.
        </div>
        <div>
          You may view the specifics of your Community Fee by visiting
          <span
            class="mx-info-link"
            @click="
              () => {
                $router.push('dashboard');
                $emit('cancel');
              }
            "
            >Account Details > Dashboard > Account Health</span
          >.
        </div>
        <div>
          If you have questions about managing your Community Fees, don't
          hesitate to reach out to your exchange office.
        </div>
        <div>
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'Account')"
          >
            Back to Account Summary
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommunityFeeInfo",
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.text div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.divider {
  width: 100%;
  background-color: var(--mx-white);
  height: 1px;
  margin: 20px 0;
}
</style>
