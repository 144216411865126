const stage =
  process.env.VUE_APP_DEPLOY_CONTEXT === "production" ? "prod" : "dev";

const dev = {
  googleMeasurementId: "G-ETDH5ENJ9X",
  googleMapsKey: "AIzaSyA_8u_4SYmg0w2tZk5nUn-dqvfxRh8Z3sM",
  mapBoxKey:
    "pk.eyJ1IjoiamFja2hlYXRvbiIsImEiOiJja252enF3amMwOHQwMm5tc3Fvem1xdmsyIn0.gKuZ0C8u5EqzaRRXaO5duA",
  mapBoxStyle: "mapbox://styles/jackheaton/ckofu0jyi20l618pnkbraz2sc/draft",
};

const prod = {
  googleMeasurementId: "G-NS6JWYR34G",
  googleMapsKey: "AIzaSyA_8u_4SYmg0w2tZk5nUn-dqvfxRh8Z3sM",
  mapBoxKey:
    "pk.eyJ1IjoiamFja2hlYXRvbiIsImEiOiJja252enF3amMwOHQwMm5tc3Fvem1xdmsyIn0.gKuZ0C8u5EqzaRRXaO5duA",
  mapBoxStyle: "mapbox://styles/jackheaton/ckofu0jyi20l618pnkbraz2sc",
};

// Default to dev if not set
const config = stage === "prod" ? prod : dev;

export default {
  ...config,
};
