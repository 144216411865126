<template>
  <div
    class="position-fixed w-100 bottom-0 controls d-flex justify-content-between border-top"
  >
    <div
      class="dropup-center dropup"
      v-if="
        storefront.phoneNumbers && Object.keys(storefront.phoneNumbers).length
      "
    >
      <div class="text-center" data-bs-toggle="dropdown" aria-expanded="false">
        <div><i class="fa fa-phone"></i></div>
        <div>Call</div>
      </div>
      <ul class="dropdown-menu mb-3">
        <li v-if="storefront.phoneNumbers?.Primary">
          <a
            :href="getPhoneURI(storefront.phoneNumbers.Primary)"
            class="dropdown-item py-2"
            v-if="storefront.phoneNumbers.Primary"
          >
            <i class="fas fa-phone-office" /> Primary
          </a>
        </li>
        <li v-if="storefront.phoneNumbers?.Mobile">
          <a
            :href="getPhoneURI(storefront.phoneNumbers.Mobile)"
            class="dropdown-item py-2"
            v-if="storefront.phoneNumbers.Mobile"
          >
            <i class="fas fa-mobile" /> Mobile
          </a>
        </li>
      </ul>
    </div>

    <div class="text-center" v-if="storefront.emails?.Primary">
      <div><i class="fas fa-envelope"></i></div>
      <div>
        <a
          :href="`mailto:${storefront.emails.Primary}`"
          class="text-decoration-none text-dark"
          >Email</a
        >
      </div>
    </div>

    <div
      class="dropup-center dropup"
      v-if="storefront.weblinks && Object.keys(storefront.weblinks).length"
    >
      <div class="text-center" data-bs-toggle="dropdown" aria-expanded="false">
        <div><i class="fas fa-globe"></i></div>
        <div>Web</div>
      </div>
      <ul class="dropdown-menu mb-3">
        <li>
          <a
            target="_blank"
            :href="storefront.weblinks.Website"
            class="dropdown-item py-2"
            v-if="storefront.weblinks.Website"
          >
            <i class="fas fa-globe" /> Website
          </a>
        </li>
        <li>
          <a
            target="_blank"
            :href="storefront.weblinks.Facebook"
            class="dropdown-item py-2"
            v-if="storefront.weblinks.Facebook"
          >
            <i class="fa-brands fa-square-facebook" /> Facebook
          </a>
        </li>
        <li>
          <a
            target="_blank"
            :href="storefront.weblinks.Instagram"
            class="dropdown-item py-2"
            v-if="storefront.weblinks.Instagram"
          >
            <i class="fa-brands fa-square-instagram" /> Instagram
          </a>
        </li>
        <li>
          <a
            target="_blank"
            :href="storefront.weblinks.Twitter"
            class="dropdown-item py-2"
            v-if="storefront.weblinks.Twitter"
          >
            <i class="fa-brands fa-square-twitter" /> Twitter
          </a>
        </li>
        <li>
          <a
            target="_blank"
            :href="storefront.weblinks.LinkedIn"
            class="dropdown-item py-2"
            v-if="storefront.weblinks.LinkedIn"
          >
            <i class="fa-brands fa-linkedin" /> LinkedIn
          </a>
        </li>
      </ul>
    </div>

    <share-button :storefront="storefront" />
  </div>
</template>

<script>
import { parsePhoneNumber } from "libphonenumber-js";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import ShareButton from "./ShareButton.vue";

//let test = "hello";

export default {
  name: "ControlBar",
  components: {
    ShareButton,
  },
  data() {
    return {
      nativeShare: navigator.share,
      nativeCopy: navigator.clipboard,
      copyLinkText: "Copy Link",
    };
  },
  props: {
    storefront: Object,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    shareUrl() {
      return `${
        window.location.origin
      }/directory/storefronts/${this.storefront?.name.replace(
        /[^A-z]/g,
        "-"
      )}/${this.storefront.storefrontId}`;
    },
    ...mapState({}),
  },
  watch: {},
  methods: {
    getPhoneURI(num) {
      const pn = parsePhoneNumber(num, "US");

      return pn.getURI();
    },

    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.controls {
  z-index: 80;
  padding: 10px 20px 20px 20px;
  background-color: var(--bs-light);
}
</style>
