<template>
  <div @click="$router.push(getRoute())">
    <slot />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

//let test = "hello";

export default {
  name: "ControlButton",
  components: {},
  data() {
    return {};
  },
  props: {
    location: Object,
    search: String,
    sectors: String,
    categories: String,
    seed: Number,
    title: String,
    routeTo: String,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    getRoute() {
      console.log(this.routeTo);

      const params = {
        search: this.search,
        sectors: this.sectors,
        categories: this.categories,
        title: this.title,
      };

      for (const k in params) {
        if (!params[k]) delete params[k];
      }

      const queryString = new URLSearchParams(params).toString();

      const route = [
        "/directory",
        this.routeTo || "home",
        this.location?.city,
        this.location?.geo.lat,
        this.location?.geo.lon,
        this?.seed,
        queryString && `?${queryString}`,
      ]
        .filter((s) => s)
        .join("/");

      console.log(route);

      return route;
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.controls {
  z-index: 80;
  padding: 10px 20px 20px 20px;
  background-color: var(--bs-light);
}
</style>
