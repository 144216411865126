<template>
  <span class="moxey-value" v-if="pretty">
    <span v-if="parseInt(d) < 0">(</span>
    <span class="icon"><usd-icon /></span>
    <span>{{ d }}</span>
    <span class="cents">{{ c }}</span>
    <span v-if="parseInt(d) < 0">)</span>
  </span>
  <span class="moxey-value" v-else>
    <span class="icon"><usd-icon /></span>{{ v }}
  </span>
</template>

<script>
import currency from "currency.js";
import UsdIcon from "./UsdIcon.vue";
//let test = "hello";

export default {
  components: { UsdIcon },
  name: "UsdValue",
  props: {
    fat: {
      type: Boolean,
      default: true,
    },
    pretty: {
      type: Boolean,
      default: false,
    },
    value: Number,
  },
  computed: {
    v() {
      return currency(this.value, {
        symbol: "",
        precision: 2,
      }).format();
    },
    d() {
      return this.v.split(".")[0];
    },
    c() {
      return this.v.split(".")[1];
    },
  },
};
</script>

<style scoped>
.moxey-value {
  line-height: 1em;
  white-space: nowrap;
}

.icon {
  font-size: 0.7em;
  vertical-align: 7%;
}

.cents {
  font-size: 0.7em;
  vertical-align: 20%;
  padding-left: 0.07em;
}
</style>
