<template>
  <div class="d-flex justify-content-start" v-if="user">
    <div class="position-relative">
      <div class="contact-icon d-flex">
        <img
          v-if="!mainImageError"
          :src="avatar"
          @error="mainImageError = true"
          @load="
            () => {
              updateAppStore({ userIcon: avatar });
              mainImageError = false;
            }
          "
        />
        <div v-else class="header-like person-icon">
          {{ user.contact.fName.replace(/[^0-9a-z]/gi, "").substring(0, 1) }}
        </div>
      </div>
      <div class="sub-image" v-if="!noProduct">
        <img
          v-if="subImageError === 0"
          :src="icon_v2"
          @error="subImageError = subImageError + 1"
          @load="
            () => {
              updateAppStore({ memberIcon: icon_v2 });
              subImageError = 0;
            }
          "
        />
        <img
          v-else-if="subImageError === 1"
          :src="icon"
          @error="subImageError = subImageError + 1"
          @load="
            () => {
              updateAppStore({ memberIcon: icon });
              subImageError = 1;
            }
          "
        />
        <div v-else class="header-like biz-icon">
          {{
            user.member.public.listName
              .replace(/[^0-9a-z]/gi, "")
              .substring(0, 1)
          }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-around">
      <div class="name">
        {{
          [user.contact.fName, showLast ? user.contact.lname : undefined].join(
            " "
          )
        }}
      </div>
      <div v-if="!noProduct" class="sub-name d-flex">
        <div>{{ user.member.public.listName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/awsconfig";
import { mapState, mapActions } from "vuex";

export default {
  name: "ProfileImg",
  components: {},
  props: {
    showLast: Boolean,
  },
  data() {
    return {
      mainImageError: false,
      subImageError: 0,
      subImageLoading: true,
    };
  },
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loggingIn: (state) => state.app.all.loggingIn,
    }),
    avatar() {
      return `${config.cloudFrontPrefix}/v2/users/${this.user.contact.private.userid}/avatars/avatar.jpg?ts=${this.user.updateTimestamp}`;
    },
    icon_v2() {
      return `${config.cloudFrontPrefix}/v2/images/products/${this.user.member.memberId}/icon.jpg?ts=${this.user.member.updateTimestamp}`;
    },
    noProduct() {
      return !this.user.member.products;
    },
    icon() {
      const icons =
        this.user.member.products &&
        this.user.member.products.map(
          (p) => `/products/${p.productId}/icon_${p.productId}.${p.iconExt}`
        );
      return icons && icons[0] && `${config.cloudFrontPrefix}${icons[0]}`;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {
    this.subImageError = 0;
  },
};
</script>

<style scoped>
.account {
  width: 100%;
}

.icons {
  position: relative;
}

.biz-icon {
  background-color: var(--mx-dark);
  color: var(--mx-white);
}

.person-icon {
  background-color: var(--mx-blue);
  color: var(--mx-dark);
}

.contact-icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  margin: 0 20px 0 0;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid #a6a6a630;
}

.contact-icon div,
.sub-image div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.contact-icon img,
.sub-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sub-image div {
  font-size: 12px;
  line-height: 30px;
}

.sub-image {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
  margin: 0 10px 0 0;
  overflow: hidden;
  border: 1px solid #a6a6a630;
  position: absolute;
  right: 0;
  top: 30px;
}

.name {
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
}

.sub-name {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin-top: 11px;
}
</style>
