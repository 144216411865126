<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Moxey FlashCash <flash-cash-icon /></div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="text">
        <div>
          Moxey FlashCash is a special type of Moxey currency that is typically
          granted as a reward for a referral or Community Rewards redemption.
        </div>
        <div>
          Flash cash that is given to you is yours to spend, however it has an
          evaporation timer attached to it. Once the evaporation timer is
          expired, any unspent FlashCash balance will be deducted from your
          account.
        </div>
        <div>
          To get the most value out of your FlashCash, be sure to spend it
          before it evaporates!
        </div>
        <div>
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'Account')"
          >
            Back to Account Summary
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlashCashIcon from "../Shared/moxey/FlashCashIcon.vue";
export default {
  name: "FCInfo",
  components: { FlashCashIcon },
  data() {
    return {};
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.text div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}
</style>
