<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Cash Reserve Auto-Reload</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="text">
        <auto-reload :preSelectCard="forwardCard" />
      </div>
      <div class="text text-center">
        <span class="mx-info-link" @click="$emit('clickedShortcut', 'Account')">
          Back to Account Summary
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AutoReload from "../Account/AutoReload.vue";
export default {
  name: "AutoLoad",
  components: { AutoReload },
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapState({
      forwardCard: (state) => state.app.all.forwardCard,
    }),
  },
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.auto-body {
  width: 100%;
  padding: 30px 30px 0;
}

.text div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}
</style>
