<template>
  <result-group
    :desktop="desktop"
    :group="{
      ...topPicks,
      location,
      seed,
      search,
    }"
    :showNoResults="true"
    :isEstablished="isEstablished"
    :user="user"
    :trigger="searchTrigger + mountTrigger"
    @needgeo="(callback) => $emit('needgeo', callback)"
    @remixtags="
      (t) => {
        $emit('remixtags', createTags(t));
      }
    "
    @selectstorefront="
      (s) => {
        selectedStorefront = s;
      }
    "
  />
  <div v-if="featured" class="mt-2">
    <featured-row
      :desktop="desktop"
      :externalFeatured="featured"
      :location="location"
      :square="true"
      :innerText="true"
      :size="80"
      :isEstablished="isEstablished"
      :user="user"
    />
  </div>

  <div :class="{ 'row my-4': desktop, 'mt-3 d-flex by-name': !desktop }">
    <div :class="{ col: desktop }">
      <result-group
        :desktop="desktop"
        :group="{
          ...byBusinessName,
          location,
          seed,
          search,
        }"
        :isEstablished="isEstablished"
        :user="user"
        :trigger="searchTrigger + mountTrigger"
        @needgeo="(callback) => $emit('needgeo', callback)"
        @selectstorefront="
          (s) => {
            selectedStorefront = s;
          }
        "
      />
    </div>
    <div :class="{ col: desktop }">
      <template v-if="user">
        <result-group
          :desktop="desktop"
          :group="{
            ...byPerson,
            location,
            seed,
            search,
          }"
          :isEstablished="isEstablished"
          :user="user"
          :trigger="personTrigger && searchTrigger + mountTrigger"
          @needgeo="(callback) => $emit('needgeo', callback)"
          @selectstorefront="
            (s) => {
              selectedStorefront = s;
            }
          "
        />
      </template>
    </div>
  </div>
  <storefront-modal
    :desktop="desktop"
    :selectedStorefront="selectedStorefront"
    @closemodal="
      () => {
        selectedStorefront = undefined;
      }
    "
    @selectstorefront="
      (s) => {
        selectedStorefront = s;
      }
    "
  />
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import ResultGroup from "./ResultGroup";
import FeaturedRow from "./FeaturedRow";
import { searchStorefronts } from "./storefrontGraphql";
import StorefrontModal from "../storefront/StorefrontModal.vue";
import shuffle from "just-shuffle";
//let test = "hello";

export default {
  name: "TopPicks",
  components: {
    ResultGroup,
    StorefrontModal,
    FeaturedRow,
  },
  data() {
    return {
      topPicks: {
        id: "topPicks",
        title: "Top Picks",
        loadingTitle: "Searching the Directory ...",
        moreText: "See more",
        moreText2: "Like This",
        predefinedSearchName: "searchStorefrontsByTags",
        index: "storefronts",
        search: this.search,
        searchFunction: searchStorefronts,
        resultCount: 12,
      },
      byBusinessName: {
        id: "byBusinessName",
        title: "By Member Name",
        loadingTitle: "Searching ...",
        moreText: "More matches by name ...",
        predefinedSearchName: "searchStorefrontsByName",
        index: "storefronts",
        search: this.search,
        searchFunction: searchStorefronts,
        column: true,
        resultCount: 5,
      },
      byPerson: {
        id: "byPersonName",
        title: "People in Moxey",
        loadingTitle: "Searching ...",
        moreText: "More matches by person ...",
        predefinedSearchName: "searchStorefrontsByPerson",
        index: "storefronts",
        search: this.search,
        searchFunction: searchStorefronts,
        userRequired: true,
        column: true,
        resultCount: 5,
      },

      selectedStorefront: undefined,
      mountTrigger: 0,
      featured: undefined,
      personTrigger: 0,
    };
  },
  props: {
    searchTrigger: Number,
    search: String,
    location: Object,
    seed: Number,
    isEstablished: Boolean,
    user: Object,
    desktop: Boolean,
  },
  async mounted() {
    console.log(this.desktop);
    this.mountTrigger++;

    const int = setInterval(() => {
      if (this.user) {
        this.personTrigger++;
        clearInterval(int);
      }
    }, 500);
  },
  beforeUnmount() {},
  computed: {
    moreTitle() {
      return `"${this.search}"`;
    },
    topPicksTitle() {
      return `Top Picks for ${this.moreTitle}`;
    },
    ...mapState({}),
  },
  watch: {
    user() {
      this.$nextTick(() => {
        this.personTrigger++;
      });
    },
    // isEstablished(post) {
    //   if (post)
    //     this.$nextTick(() => {
    //       this.mountTrigger++;
    //     });
    // },
    searchTrigger(post) {
      console.log(post);
    },
  },
  methods: {
    createTags(t) {
      const base = {
        predefinedSearchName: "searchStorefrontsByTags",
        index: "storefronts",
        searchFunction: searchStorefronts,
      };

      const newTags = {};

      for (const k in t) {
        newTags[k] = t[k].map((i) => {
          return {
            id: `featured:${k.includes("Sectors") ? "sectors" : "categories"}:${
              i.name
            }:${Date.now()}`,
            title: i.name,
            loadingTitle: `Getting ${i.name} ...`,
            moreText: "See more in",
            moreText2: i.name,
            [k.includes("Sectors") ? "sectors" : "categories"]: [i.name],
            type: i.type,
            count: i.count,
            score: i.score,
            ...base,
            resultCount: shuffle([3, 6, 12]).pop(),
          };
        });
      }

      const list = [
        ...newTags.featuredCategories,
        ...newTags.featuredSectors,
      ].sort((a, b) => b.count * b.score - parseFloat(a.count * a.score));

      this.featured = list.slice(0, 8);

      return list;
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.by-name {
  margin: 0 -30px 0 -20px;
  padding: 0 10px 0 20px;
  overflow-x: auto;
}

.by-name div {
  width: 93%;
  min-width: 93%;
  overflow-x: visible;
}

@media (min-width: 768px) {
}
</style>
