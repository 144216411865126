<template>
  <div>
    <div class="hero-bg">
      <div class="is-overlay hero-x-behind"></div>
      <div class="is-overlay hero-x-front"></div>
    </div>
    <div class="app-loader-content">
      <div class="guide-content w-100">
        <div
          class="text-end skip d-flex align-items-center justify-content-end"
        >
          <span class="inline-link" @click="finish">
            <i class="fa-solid fa-times" /> Skip the tour
          </span>
        </div>
        <transition name="fade">
          <div
            class="page d-flex flex-column align-items-start align-items-lg-center justify-content-center"
            v-if="pages[pageIndex] === 'intro'"
          >
            <div class="header-like yuge">Welcome to Moxey!</div>
            <div class="fs-5 mt-3">We're proud to bring you:</div>

            <div class="feature fs-5 mt-3 px-3 d-flex align-items-center">
              <div class="me-3"><i class="fa-solid fa-bolt fa-fw" /></div>
              <div>Lightning fast directory search.</div>
            </div>

            <div class="feature fs-5 mt-3 px-3 d-flex align-items-center">
              <div class="me-3">
                <i class="fa-solid fa-screwdriver-wrench fa-fw" />
              </div>
              <div>Super-charged account management tools.</div>
            </div>

            <div class="feature fs-5 mt-3 px-3 d-flex align-items-center">
              <div class="me-3"><i class="fa-solid fa-link fa-fw" /></div>
              <div>Great new ways to connect with fellow Moxey members.</div>
            </div>

            <div class="feature fs-5 mt-3 px-3 d-flex align-items-center">
              <div class="me-3">
                <i class="fa-solid fa-money-bill-wave fa-fw" />
              </div>
              <div>Even more opportunities to save cash and earn Moxey.</div>
            </div>

            <div class="mt-3 fs-5">
              Click the forward button, and we'll show you around.
            </div>
          </div>
          <div
            class="page d-flex flex-column align-items-start align-items-lg-center justify-content-center"
            v-else-if="pages[pageIndex] === 'directory'"
          >
            <div class="feature fs-5 d-flex align-items-center">
              <div class="me-3 fs-1">
                <i class="fa-solid fa-search fa-fw" />
              </div>
              <div class="ms-3">
                The new directory is powered by cloud based search technology
                that's super fast and easy to use. Just use the search bar at
                the top of the homepage to find what you are looking for.
              </div>
            </div>

            <div class="feature fs-5 mt-5 d-flex align-items-center">
              <div class="me-3 fs-1">
                <i class="fa-duotone fa-map-location-dot fa-fw" />
              </div>
              <div class="ms-3">
                The improved map view now shows all Moxey members in the area
                you search. Use the map to explore and find new Moxey businesses
                near you.
              </div>
            </div>
          </div>
          <div
            class="page d-flex flex-column align-items-start align-items-lg-center justify-content-center"
            v-else-if="pages[pageIndex] === 'login'"
          >
            <div class="feature fs-5 d-flex align-items-center">
              <div class="me-3 fs-1">
                <i class="fa-duotone fa-lock-keyhole fa-fw" />
              </div>
              <div class="ms-3">
                Click <i class="fas fa-bars" /> in the upper right to launch the
                main menu and log in using your usual credentials. Forgot your
                password? No problem! Either use the automated password reset
                link or contact your exchange office for assistance.
              </div>
            </div>

            <div class="feature fs-5 mt-5 d-flex align-items-center">
              <div class="me-3 fs-1">
                <span class="fa-layers fa-fw">
                  <i class="fa-duotone fa-money-bill-wave fa-fw" />
                  <i
                    class="fak fa-mx-sign-fat"
                    data-fa-transform="shrink-11"
                  ></i>
                </span>
              </div>
              <div class="ms-3">
                The main menu keeps all your Moxey transaction tools right at
                your fingertips. Send Moxey, charge cards, and reload your Cash
                Reserve Account quickly from here.
              </div>
            </div>
          </div>
          <div
            class="page d-flex flex-column align-items-start align-items-lg-center justify-content-center"
            v-else-if="pages[pageIndex] === 'accounts'"
          >
            <div class="feature fs-5 d-flex align-items-center">
              <div class="me-3 fs-1">
                <i class="fa-duotone fa-users fa-fw" />
              </div>
              <div class="ms-3">
                Visit the My Account screen to view your profile, add an avatar,
                access your Moxey card information, and set your business up for
                Moxey<strong><em>Pay</em></strong
                >. View your transaction history at-a-glance or in detail by
                using the advanced transaction explorer.
              </div>
            </div>

            <div class="feature fs-5 mt-5 d-flex align-items-center">
              <div class="me-3 fs-1">
                <i class="fa-duotone fa-chart-line-up fa-fw" />
              </div>
              <div class="ms-3">
                View real-time insights into your Moxey utilization, cash
                savings, and sales from the My Dashboard screen.
              </div>
            </div>
          </div>
          <div
            class="page d-flex flex-column align-items-start align-items-lg-center justify-content-center"
            v-else-if="pages[pageIndex] === 'apps' && !isNativeClient"
          >
            <div
              class="fs-6 mt-3 d-flex flex-column d-lg-none align-items-center"
            >
              <div>
                Access all this enhanced Moxey functionality on the go using the
                Moxey Mobile App. Click to download it on the Apple App Store or
                Google Play Store.
              </div>
              <div class="mt-5">
                <a
                  href="https://apps.apple.com/us/app/moxey-mobile-app/id1212992465"
                >
                  <img
                    width="240"
                    src="@/assets/apple-app-store-badge.png"
                    alt="Download on the App Store"
                  />
                </a>
              </div>
              <div class="my-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tradeauthority.quiktrade.mobile"
                >
                  <img
                    width="240"
                    src="@/assets/google-play-badge.png"
                    alt="Get it on Google Play"
                  />
                </a>
              </div>
            </div>

            <div
              class="fs-5 mt-3 flex-column d-none d-lg-flex align-items-center"
            >
              <div>
                Access all this enhanced Moxey functionality on the go using the
                Moxey Mobile App. Select your platform and scan the QR code with
                your mobile device's camera download it on the Apple App Store
                or Google Play Store.
              </div>
              <div class="d-flex mt-3 justify-content-around w-100">
                <div class="text-center">
                  <div
                    class="fs-3 platform"
                    :class="{ 'platform-active': platform === 'apple' }"
                  >
                    <i class="fas fa-caret-down"></i>
                  </div>
                  <img
                    class="platform-badge"
                    width="140"
                    src="@/assets/apple-app-store-badge.png"
                    alt="Download on the App Store"
                    @click="setPlatform('apple')"
                  />
                </div>

                <div class="text-center">
                  <div
                    class="fs-3 platform"
                    :class="{ 'platform-active': platform === 'android' }"
                  >
                    <i class="fas fa-caret-down"></i>
                  </div>
                  <img
                    class="platform-badge"
                    width="140"
                    src="@/assets/google-play-badge.png"
                    alt="Get it on Google Play"
                    @click="setPlatform('android')"
                  />
                </div>
              </div>
              <div class="qr-holder w-100 position-relative">
                <transition name="fade-qr">
                  <div v-if="platform === 'apple'" class="qr w-100">
                    <img
                      width="200"
                      src="@/assets/app-store-qr.png"
                      alt="Download on the App Store"
                    />
                    <div
                      class="qr-caption d-flex align-items-center justify-content-center"
                    >
                      <div>Download on the App Store</div>
                    </div>
                  </div>
                  <div v-else class="qr w-100">
                    <img
                      width="200"
                      src="@/assets/google-play-qr.png"
                      alt="Get it on Google Play"
                    />
                    <div
                      class="qr-caption d-flex align-items-center justify-content-center"
                    >
                      <div>Get it on Google Play</div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div
            class="page d-flex flex-column align-items-start align-items-lg-center justify-content-center"
            v-else-if="pages[pageIndex] === 'apps' && isNativeClient"
          >
            <div class="text-center w-100 d-flex justify-content-center">
              <div class="d-flex fs-3 w-75 justify-content-between">
                <i class="fas fa-star" />
                <i class="fas fa-star" />
                <i class="fas fa-star" />
                <i class="fas fa-star" />
                <i class="fas fa-star" />
              </div>
            </div>

            <div class="mt-3 fw-bold fs-5">
              Thank you for downloading the Moxey Mobile App!
            </div>

            <div class="mt-3">
              Please rate our app in the app store. Your feedback helps us build
              more amazing features for our members.
            </div>
          </div>
          <div
            class="page d-flex flex-column align-items-start align-items-lg-center justify-content-center"
            v-else-if="pages[pageIndex] === 'done'"
          >
            <div>
              <h1>Thank You For Taking the Moxey Tour</h1>
            </div>
            <div>
              <div class="feature fs-5 mt-3">
                We're looking forward to continuing to bring you great Moxey
                features. If you have feedback or any questions, don't hesitate
                to reach out to your Exchange Director.
              </div>
            </div>
            <div class="mt-5 text-center w-100">
              <mx-button
                :height="54"
                :fullWidth="false"
                type="button"
                btnClass="btn-outline-light btn-outline-light-custom"
                @click="finish"
                >Finished!</mx-button
              >
            </div>
          </div>
        </transition>
        <div class="navi d-flex justify-content-center align-items-center fs-2">
          <div
            class="me-3 navi-button"
            :class="{ disabled: pageIndex === 0 }"
            @click="back()"
          >
            <i class="fas fa-chevrons-left" />
          </div>
          {{ pageIndex + 1 }} / 6
          <div
            class="ms-3 navi-button"
            :class="{ disabled: pageIndex >= pages.length - 1 }"
            @click="forward()"
          >
            <i class="fas fa-chevrons-right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import MxButton from "../Shared/MxButton.vue";
import ls from "local-storage";

//let test = "hello";

export default {
  name: "TermsSetter",
  components: {
    MxButton,
  },
  data() {
    return {
      pageIndex: 0,
      pages: ["intro", "directory", "login", "accounts", "apps", "done"],
      platform: "apple",
    };
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({
      isNativeClient: (state) => state.app.all.isNativeClient,
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    forward() {
      this.pageIndex = this.pageIndex < 5 ? this.pageIndex + 1 : this.pageIndex;
    },
    back() {
      this.pageIndex = this.pageIndex =
        this.pageIndex > 0 ? this.pageIndex - 1 : 0;
    },
    setPlatform(platform) {
      this.platform = platform;
    },
    finish() {
      ls.set("showWalkthroughModal", "false");
      this.updateAppStore({
        showWalkthroughModal: false,
      });
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
.app-loader-content {
  height: calc(100vh);
  width: 100%;
  min-width: 100%;
}

.feature.fs-5 {
  font-size: 1rem !important;
}

.guide-content {
  padding: 30px;
  width: 100%;
}

.page {
  min-height: calc(100vh - 165px);
  height: calc(100vh - 165px);
  width: 100%;
  min-width: 100%;
}

.header-like {
  font-size: 30px;
}

.skip {
  height: 30px;
  min-height: 30px;
  margin-bottom: 15px;
}

.navi {
  height: 45px;
  min-height: 25px;
  width: 100%;
  margin: 15px 0 0;
}

.navi-button,
.platform-badge {
  cursor: pointer;
}

.navi-button.disabled {
  cursor: unset;
  opacity: 0;
}

.inline-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-white);
}

.hero-bg {
  position: fixed;
}
.fade-leave-active {
  position: absolute;
  min-width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  width: calc(100% - 60px);
  transition: opacity 0.2s ease-in;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-from {
  opacity: 1;
}

.platform {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.platform.platform-active {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

@media (min-width: 992px) {
  .feature.fs-5 {
    font-size: 1.25rem !important;
  }
  .hero-bg {
    position: absolute;
  }
  .header-like {
    font-size: 48px;
  }
  .app-loader-content {
    height: unset;
    min-height: unset;
  }

  .page {
    height: 540px;
    min-height: 540px;
  }

  .page > div {
    width: 75%;
    min-width: 75%;
  }

  .qr-holder {
    height: 320px;
    min-height: 320px;
  }

  .qr {
    position: absolute;
    top: 0;
    padding: 30px 0;
    text-align: center;
  }

  .qr-caption {
    height: 60px;
    min-height: 60px;
  }

  .fade-qr-leave-active {
    transition: opacity 0.2s ease-in;
  }

  .fade-qr-leave-to {
    opacity: 0;
  }

  .fade-qr-leave-from {
    opacity: 1;
  }
}
</style>
