import { api } from "@/utils/utils";
import haversine from "haversine-distance";
import { log, getProductTypes, getRegions } from "../../utils/utils";
import { protoSearch } from "../../utils/search";

// initial state
const state = () => ({
  all: {
    showTopNav: false,
    showTopHero: false,
    searching: false,
    suggestionsList: [],
    searchResults: [],
    resultCount: undefined,
    searchStats: {},
    searchTerm: "",
    previousSearches: JSON.parse(window.localStorage.previousSearches || "[]"),
    preloading: false,
    picklists: {},
    facets: {},
    searchHints: {
      directoryStats: [],
    },
    selectedMember: {},
    showFilterModal: false,
    useAdvanced: false,
    advancedSearch: {},
    productTypes: [],
    regions: [],
    searchBarText: "",
    altResults: {},

    searchQuery: undefined,
    searchLocation: {},
    autoLocateError: 0,
    showZipModal: false,

    refreshMap: 0,

    localResults: [],

    memberData: {},
  },
});

// getters
const getters = {};

// actions
const actions = {
  async updateAppStore({ commit }, update) {
    commit("update", update);
  },
  async doDirectorySearch({ commit, state, rootState, dispatch }, payload) {
    try {
      let {
        query,
        start,
        filter,
        sort,
        expr,
        distance,
        distanceSource,
        limit,
        altResults,
        altGeo,
        doNotLog,
        type,
        logInfo,
      } = payload;

      if (!altResults) {
        if (!rootState.app.all.geo) {
          dispatch("app/refreshGeo", null, { root: true });
        }

        commit("update", {
          resultCount: undefined,
          searchResults: Object.freeze([]),
          searchStats: Object.freeze({}),
          searching: true,
        });

        dispatch(
          "app/updateAppStore",
          { lastClicked: undefined },
          { root: true }
        );
      }

      const geo =
        distanceSource === "member" && altGeo
          ? { ...altGeo, distance: distance }
          : distanceSource === "map" && rootState.app.all.mapCenter
          ? { ...rootState.app.all.mapCenter, distance: distance }
          : rootState.app.all.geo !== "error" && {
              ...rootState.app.all.geo,
              distance: distance,
            };

      if (!start) {
        expr = { meta: 0 };

        if (geo && geo.lat && geo.lng) {
          expr.distance = `haversin(${geo.lat},${geo.lng},location.latitude,location.longitude)`;
          (expr.distance_bucket = `floor(haversin(${geo.lat},${geo.lng},location.latitude,location.longitude)/5)*5`),
            (expr.distance_sort = `(1-waiting)*(1-standby)*(_score/log10(haversin(${geo.lat},${geo.lng},location.latitude,location.longitude)))`);
          expr.score_bucket = `ceil((1-waiting)*(1-standby)*(_score)/100)*100`;
          expr.demote_standby = `(1-waiting)*(1-standby)`;
          sort = !filter
            ? `score_bucket desc, distance asc`
            : `demote_standby desc, distance asc`;
        }
      }

      const querySize = limit || 5000;

      const queryFilter = !start ? JSON.stringify(filter) : filter;

      const queryExpression = !start ? JSON.stringify(expr) : expr;

      const res = await api(
        "get",
        "search",
        `/search/?query=${encodeURIComponent(query || "")}${
          start ? `&start=${start}` : ""
        }${querySize ? `&size=${querySize}` : ""}${
          filter ? `&filter=${encodeURIComponent(queryFilter)}` : ""
        }&expr=${encodeURIComponent(queryExpression)}
      ${sort ? `&sort=${encodeURIComponent(sort)}` : ""}
      ${doNotLog ? `&doNotLog=1` : ""}
      ${geo ? `&geo=${encodeURIComponent(JSON.stringify(geo))}` : ""}
      ${type ? `&type=${type}` : ""}
      `
      );

      const tempStats = {
        facets: res.facets,
        found: res.hits.found,
        start: res.hits.start,
        query: res.query,
        filter: res.filter,
        size: querySize,
        expr: queryExpression,
        sort: sort,
      };

      log({
        ...logInfo,
        geoSrc: geo.source,
        geoArea: geo.area,
        ...geo.addressParts,
        found: res.hits && res.hits.found,
        timems: res.status && res.status.timems,
      });

      if (res.hits.hit[0]) {
        const firstLocation =
          res.hits.hit[0].location &&
          res.hits.hit[0].fields.location[0].split(",").reverse();

        const firstBand =
          (res.hits.hit[0].fields.range_band &&
            parseInt(res.hits.hit[0].fields.range_band[0])) ||
          2;

        const results = (start > 0 ? state.all.searchResults : []).concat(
          res.hits.hit.map((h) => {
            const location =
              h.fields.location &&
              h.fields.location[0]
                .split(",")
                .reverse()
                .map((l) => parseFloat(l));

            return {
              id: h.id,
              ...h.fields,
              ...h.exprs,
              distance: h.exprs.distance && 0.621 * h.exprs.distance,
              location: location,
              distFromFirst:
                firstLocation &&
                location &&
                0.000621 * haversine(firstLocation, location),
              rangeBand: firstBand,
            };
          })
        );

        const final = Object.freeze(
          distance
            ? results.filter((r) => r.distance && r.distance <= distance)
            : results
        );

        if (altResults) {
          const temp = {};
          temp[altResults] = final;
          temp[`${altResults}Searching`] = false;
          commit("update", { ...temp });
          return;
        }

        commit("update", {
          resultCount: final.length,
          searchResults: final,
          searchStats: Object.freeze(tempStats),
          searching: false,
        });
      } else {
        commit("update", {
          resultCount: 0,
          searchResults: Object.freeze([]),
          searchStats: Object.freeze({}),
          searching: false,
        });
      }
    } catch (e) {
      console.log(e);
      dispatch("notifications/addError", e, { root: true });
      commit("update", {
        searching: false,
      });
    }
  },
  async doAdHocSearch({ commit, state, dispatch }, payload) {
    try {
      const { start, expr, sort, size } = payload;

      const res = await protoSearch(payload);

      commit("update", {
        resultCount: undefined,
        searchResults: Object.freeze([]),
        searchStats: Object.freeze({}),
        searching: true,
      });

      dispatch(
        "app/updateAppStore",
        { lastClicked: undefined },
        { root: true }
      );

      const tempStats = {
        facets: res.facets,
        found: res.hits.found,
        start: res.hits.start,
        query: res.query,
        filter: res.filter,
        size: size,
        expr: expr,
        sort: sort,
      };

      if (res.hits.hit[0]) {
        const firstLocation = res.hits.hit[0].fields.location[0]
          .split(",")
          .reverse();

        const firstBand = 3;

        const final = Object.freeze(
          (start > 0 ? state.all.searchResults : []).concat(
            res.hits.hit.map((h) => {
              const location =
                h.fields.location &&
                h.fields.location[0]
                  .split(",")
                  .reverse()
                  .map((l) => parseFloat(l));

              return {
                id: h.id,
                ...h.fields,
                ...h.exprs,
                location: location,
                distFromFirst:
                  firstLocation &&
                  location &&
                  0.000621 * haversine(firstLocation, location),
                rangeBand: firstBand,
              };
            })
          )
        );
        commit("update", {
          resultCount: final.length,
          searchResults: final,
          searchStats: Object.freeze(tempStats),
          searching: false,
        });
      } else {
        commit("update", {
          resultCount: 0,
          searchResults: Object.freeze([]),
          searchStats: Object.freeze({}),
          searching: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getPicklists({ dispatch }) {
    try {
      const pl = await Promise.all([getProductTypes(), getRegions()]);

      const picklists = {
        productTypes: pl[0],
        regions: pl[1],
      };

      return Object.freeze(picklists);
    } catch (e) {
      dispatch("notifications/addError", e, { root: true });
    }
  },
  async getFacets({ dispatch }) {
    try {
      const facets = await api("get", "search", `/search/facets`);
      return Object.freeze(facets);
    } catch (e) {
      dispatch("notifications/addError", e, { root: true });
    }
  },
  async preloadData({ commit, dispatch }) {
    commit("update", { preloading: true });

    const data = await dispatch("getPicklists");
    //console.log(await dispatch("getFacets"));

    commit("update", {
      preloading: false,
      productTypes: data.productTypes,
      regions: data.regions,
    });
  },
};

// mutations
const mutations = {
  update(state, update) {
    state.all = { ...state.all, ...update };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
