<template>
  <span class="moxey-value" v-if="pretty">
    <span v-if="parseInt(d) < 0">(</span>
    <span class="icon"
      ><moxey-icon v-if="!flashCash" /><flash-cash-icon v-if="flashCash"
    /></span>
    <span>{{ d }}</span>
    <span v-if="!noCents" class="cents">{{ c }}</span>
    <span v-if="parseInt(d) < 0">)</span>
  </span>
  <span class="moxey-value" v-else>
    {{ !value || value >= 0 ? "" : "("
    }}<span class="icon"
      ><moxey-icon v-if="!flashCash" /><flash-cash-icon
        v-if="flashCash" /></span
    >{{ v.replace("-", "") }}{{ !value || value >= 0 ? "" : ")" }}
  </span>
</template>

<script>
import currency from "currency.js";
import MoxeyIcon from "./MoxeyIcon.vue";
import FlashCashIcon from "./FlashCashIcon.vue";
//let test = "hello";

export default {
  components: { MoxeyIcon, FlashCashIcon },
  name: "MoxeyValue",
  props: {
    fat: {
      type: Boolean,
      default: true,
    },
    pretty: {
      type: Boolean,
      default: false,
    },
    noCents: Boolean,
    value: Number,
    flashCash: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    v() {
      return currency(this.noCents ? Math.ceil(this.value) : this.value, {
        symbol: "",
        precision: this.noCents ? 0 : 2,
      }).format();
    },
    d() {
      return this.v.split(".")[0];
    },
    c() {
      return this.v.split(".")[1];
    },
  },
};
</script>

<style scoped>
.moxey-value {
  line-height: 1em;
  white-space: nowrap;
}

.icon {
  font-size: 0.7em;
  vertical-align: 7%;
}

.cents {
  font-size: 0.7em;
  vertical-align: 20%;
  padding-left: 0.07em;
}
</style>
