<template>
  <div class="d-flex cra-outer">
    <div class="cra flex-fill">
      <div class="d-flex justify-content-around align-items-start">
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="cra-amt d-flex justify-content-center balance"
            v-if="flashCash"
          >
            <div><flash-cash-icon />{{ flashCash[0] }}</div>
            <div class="cents">{{ flashCash[1] }}</div>
          </div>
          <div class="mx-3 fc-desc">
            total Moxey FlashCash is currently in your account
          </div>
        </div>
        <div
          class="whats-this h-100"
          @click="
            updateAppStore({
              leftBarComp: 'FCInfo',
            })
          "
        >
          <div><i class="fas fa-circle-question"></i></div>
        </div>
      </div>
      <div
        class="d-flex justify-content-beween fw-light align-items-center mt-3"
        v-if="nextExpire && !sameAmount && mini"
      >
        <div
          class="sm-amt d-flex justify-content-center balance"
          v-if="nextExpire"
        >
          <div>
            <span class="fa-layers fa-fw">
              <i
                class="fa-thin fa-bolt text-warning"
                data-fa-transform="right-2 grow-4"
              ></i>
              <i
                class="fak fa-mx-sign-fat"
                data-fa-transform="shrink-5 right-2"
              ></i> </span
            >{{ nextExpire[0] }}
          </div>
          <div class="cents">{{ nextExpire[1] }}</div>
        </div>
        <div class="ms-3 fc-desc">
          <small>
            of your FlashCash will evaporate {{ expiresIn }}. Be sure to spend
            it before it's gone!
          </small>
        </div>
      </div>
      <div
        class="d-flex justify-content-beween fw-light align-items-center mt-3 fc-desc"
        v-if="nextExpire && sameAmount && mini"
      >
        <small>
          Your FlashCash will evaporate {{ expiresIn }}. Be sure to spend it
          before it's gone!
        </small>
      </div>
    </div>
  </div>
  <div v-if="!mini">
    <hr class="border-light" />
    <div>
      <div class="row justify-content-center" v-if="items.length === 0">
        <div class="col-12 text-center">
          <small
            ><em
              >You have no additional FlashCash waiting to be spent.</em
            ></small
          >
        </div>
      </div>
      <div v-else>
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <small><em>Active FlashCash Balances</em></small>
          </div>
          <div
            v-for="(f, i) in items"
            :key="i"
            class="col-12 col-xl-4 fc-bucket"
          >
            <div class="box justify-content-center border border-light p-4">
              <div class="d-flex justify-content-center">
                <div class="dollars">
                  <flash-cash-icon />{{ f.currentBalanceDisplay.split(".")[0] }}
                </div>
                <div class="cents">
                  {{ f.currentBalanceDisplay.split(".")[1] }}
                </div>
              </div>
              <div class="time-left mt-2 text-center">
                Evaporates {{ f.daysLeft }}.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { api } from "@/utils/utils";
import { mapActions, mapState } from "vuex";
import currency from "currency.js";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import FlashCashIcon from "../Shared/moxey/FlashCashIcon.vue";

dayjs.extend(relativeTime);

//let test = "hello";

export default {
  name: "FlashCash",
  components: { FlashCashIcon },

  data() {
    return {
      selectedWalletItem: undefined,
      selectedCardType: undefined,
      selectedCardTypeNice: undefined,
      items: [],
    };
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    if (this.user) this.refreshFlashCash();
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      testMode: (state) => state.app.all.testMode,
    }),
    flashCash() {
      return currency(this.user?.account?.flashCashBalance, { symbol: "" })
        .format()
        .split(".");
    },
    nextExpire() {
      return currency(this.user?.account?.flashNextExpireBalance, {
        symbol: "",
      })
        .format()
        .split(".");
    },
    sameAmount() {
      return (
        this.user?.account?.flashCashBalance ===
        this.user?.account?.flashNextExpireBalance
      );
    },
    expiresIn() {
      if (!this.user?.account?.flashNextExpireDate) return "";
      return dayjs(this.user?.account?.flashNextExpireDate).fromNow();
    },
  },
  watch: {
    user(post) {
      if (post) this.refreshFlashCash();
    },
  },

  methods: {
    async refreshFlashCash() {
      this.initialLoad = true;
      try {
        let res = this.testMode
          ? this.user.flashCash
          : await api(
              "get",
              "banking",
              `/flashcash?exchangeId=${
                this.user.member.auth[0].auId
              }&memberId=${this.user.member.memberId}&active=1&ts=${Date.now()}`
            );

        res = res.map((fc) => {
          return {
            ...fc,
            daysLeft: dayjs(fc.endDate).fromNow(),
            startingBalanceDisplay: currency(fc.startingBalance, {
              symbol: "",
            }).format(),
            currentBalanceDisplay: currency(fc.currentBalance, {
              symbol: "",
            }).format(),
          };
        });

        this.items = res;
      } catch (e) {
        this.initialError = e.error;
      } finally {
        this.initialLoad = false;
      }
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.whats-this {
  font-size: 18px;
  cursor: pointer;
}

.balance {
  white-space: nowrap;
}

.cra-outer {
  position: relative;
  z-index: 1;
  margin: 15px 0;
}

.cra {
  font-size: 18px;
  font-weight: 400;
}

.cra-amt {
  margin: 5px 0 10px;
  font-size: 30px;
  line-height: 30px;
}

.cra-amt .cents {
  font-size: 18px;
  line-height: 22px;
  padding: 0 0 0 4px;
}

.sm-amt {
  margin: 5px 0 10px;
  font-size: 24px;
  line-height: 26px;
}

.sm-amt .cents {
  font-size: 14px;
  line-height: 16px;
  padding: 0 0 0 4px;
}

.fc-bucket {
  padding: 15px;
  font-size: 20px;
}

.fc-bucket .cents {
  font-size: 0.6em;
  line-height: 2em;
}

.fc-bucket .box {
  border-radius: 25px;
}

.fc-bucket .time-left {
  font-size: 0.7em;
}

.fc-desc {
  font-size: 14px;
  line-height: 18px;
}
</style>
