<template>
  <div
    class="video-container d-flex justify-content-center align-items-center"
    v-if="selectedVideo"
  >
    <div
      id="video-wrapper"
      class="d-flex justify-content-center position-relative"
    >
      <div class="video">
        <div class="stop-button" @click="killVideo">
          <i class="fa-duotone fa-times-circle"></i>
        </div>
        <div class="video-frame">
          <iframe
            :src="`https://www.youtube.com/embed/${selectedVideo}?autoplay=1`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  components: {},
  data() {
    return {
      itemsToShow: 1.2,
      selectedVideo: undefined,
      width: window.innerWidth - 20,
      browsed: false,
      page: 0,
      scrollList: [],
      clickScroll: false,
      slidePerPage: 1,
    };
  },
  props: {
    id: String,
  },
  watch: {
    async id(post) {
      if (post) {
        await this.selectVideo(post);
      }
    },
  },
  computed: {
    pageCount() {
      return this.videos.length / this.slidePerPage;
    },
  },
  mounted() {},
  methods: {
    async selectVideo(id) {
      this.selectedVideo = id;
      this.$nextTick(() => {
        const el = document.getElementById("video-wrapper");
        el.style.width = el && `${el.clientWidth}px`;
        el.style.height = el && `${el.clientWidth / (16 / 9)}px`;
        this.$emit("openedvideo");
      });
    },
    killVideo() {
      this.selectedVideo = undefined;
    },
  },
};
</script>

<style scoped>
.video-container {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.377);
}

#video-wrapper {
  width: 95%;
  max-width: 700px;
  max-height: 393px;
}

.video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.video > .video-frame {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.video > .video-frame > iframe {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.stop-button {
  z-index: 302 !important;
  position: absolute;
  color: var(--mx-white);
  font-size: 2rem;
  right: -20px;
  top: -25px;
  cursor: pointer;
}

.icon {
  z-index: 8;
}

.dt-display {
  display: none;
}

@media (min-width: 426px) {
  #header {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
  }
  .carousel__slide.active .slide-container {
    transform: scale(1.1);
  }
}

@media (min-width: 930px) {
  .mobile-display {
    display: none;
  }

  .slide-contents {
    position: relative;
  }

  .dt-display {
    position: relative;
    display: block;
    justify-content: flex-start;
    margin: 20px 0px;
    overflow-x: scroll;
  }

  .slide-holder {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }

  .slide-container {
    width: calc(100% - 200px);
    padding: 0 25px;
    display: inline-block;
  }

  .slide-container:first-child {
    margin: 0 0 0 100px;
  }

  .slide-container:last-child {
    margin: 0 100px 0 0;
  }

  .left-button,
  .right-button {
    z-index: 9;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    width: 125px;
  }

  .right-button {
    right: 0;
  }

  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .page-pip {
    cursor: pointer;
    margin: 20px 10px;
    height: 36px;
    width: 36px;
    background-color: var(--mx-grey);
    border-radius: 100%;
    transform: scale(0.8);
    transition: background-color, transform 0.25s ease-out;
  }
  .page-pip.active {
    background-color: var(--mx-green);
    transform: scale(1);
    transition: background-color, transform 0.2s ease-in;
  }
}

@media (min-width: 1200px) {
  .slide-container {
    width: calc(50% - 100px);
  }
}
</style>
