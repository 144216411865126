<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Cash Reserve Account</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="text">
        <div>
          Your Cash Reserve Account is used to fund tips and the cash portion of
          your transactions.
        </div>
        <div>
          If your Cash Reserve Account balance is insufficient to cover the cash
          portion of a payment it will be declined.
        </div>
        <div>
          Your Cash Reserve Account can be easily reloaded using any credit or
          debit card by visiting
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'LoadCRA')"
            >Load Cash Reserve Account</span
          >.
        </div>

        <div>
          Once you've loaded your Cash Reserve Account and saved a payment card,
          you may enroll in
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'AutoLoad')"
            >Cash Reserve Auto-Reload</span
          >
          which will automatically top up your account.
        </div>
        <div>
          <span
            class="mx-info-link"
            @click="$emit('clickedShortcut', 'Account')"
          >
            Back to Account Summary
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CRAInfo",
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {},
  beforeMount() {},
};
</script>

<style scoped>
#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.text div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}
</style>
