<template>
  <div class="position-absolute banner">
    <div>
      <div v-if="item.rewardPoints" class="position-relative">
        <div :class="['d-flex', 'flag-shadow']">
          <div class="banner-body">
            <span style="visibility: hidden"
              >Earn {{ item.rewardPoints }}x <reward-icon
            /></span>
          </div>
          <div class="tail"></div>
        </div>
        <div :class="['wrap', { gold: item.rewardPoints > 1 }]"></div>
        <div :class="['d-flex', 'flag', { gold: item.rewardPoints > 1 }]">
          <div class="banner-body">
            Earn {{ item.rewardPoints }}x <reward-icon />
          </div>
          <div class="tail"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import RewardIcon from "../../../components/Shared/rewards/RewardIcon.vue";

//let test = "hello";

export default {
  name: "ResultBanner",
  components: { RewardIcon },
  data() {
    return {};
  },
  props: {
    item: Object,
    i: Number,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.banner {
  width: calc(100% + 20px);
  top: -5px;
  left: -10px;
}

.banner-body {
  padding: 0.25em 0 0.25em 1em;
  font-size: 0.7em;
}

.flag div {
  z-index: 3;
  background-color: lightgray;
}

.flag.gold div {
  z-index: 3;
  background-color: gold;
}

.tail {
  position: relative;
  left: -1px;
  width: 1em;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    50% 50%,
    100% 100%,
    100% 100%,
    0% 100%,
    0% 0%
  );
}

.flag-shadow {
  z-index: 2;
  position: absolute;
  left: 2px;
  top: 2px;
}

.flag-shadow div {
  background-color: rgba(0, 0, 0, 0.25);
}

.wrap {
  z-index: 1;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 1.4em;
  background-color: darkgray;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}

.wrap.gold {
  background-color: goldenrod;
}

.new-badge {
  z-index: 3;
  position: absolute;
  font-size: 1.75em;
  line-height: 0;
  top: -5px;
  right: -10px;
}

.new-badge-text {
  z-index: 3;
  font-size: 0.3em;
  transform: rotate(20deg);
}

.badge-shadow {
  z-index: 1;
  top: -2px;
  left: -2px;
  color: rgba(0, 0, 0, 0.25);
}
</style>
