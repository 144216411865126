<template>
  <div
    v-if="user.spendingTarget?.percentStagnant < 25 && spendingTarget.value > 0"
  >
    <div v-if="!isPartial">
      <div class="d-flex bp-outer">
        <div class="cra flex-fill">
          <div class="d-flex justify-content-between align-items-center">
            <div class="bp-title fw-lighter">
              {{ monthName }} Spending Target
            </div>
            <div
              class="whats-this ms-2"
              @click="$emit('clickedShortcut', 'SpendingTargetInfo')"
            >
              <i class="fas fa-circle-question"></i>
            </div>
          </div>
          <div
            class="alert-text fw-lighter d-flex justify-content-start my-3 ms-0"
          >
            <div v-if="percentSpent < 100">
              You've spent
              <strong><moxey-value :value="spent" :noCents="false" /></strong>
              of your
              <strong
                ><moxey-value :value="spendingTarget" :noCents="false"
              /></strong>
              spending target.
            </div>
            <div v-else>
              You've met your
              <strong
                ><moxey-value :value="spendingTarget" :noCents="false"
              /></strong>
              spending target.
            </div>
          </div>
          <div class="progress mb-2">
            <div
              class="progress-bar progress-bar-striped"
              role="progressbar"
              aria-label="Default striped example"
              :style="`width: ${percentSpent}%; background-color: ${color}`"
              :aria-valuenow="percentSpent"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="percentSpent < 50" class="alert-text ms-0 mt-3">
      <span class="fw-bold">It's time to spend!</span>
      To get the most value out of your Moxey you should spend
      {{ spent.value === 0 ? "" : "an additional" }}
      <strong><moxey-value :value="remain.value" /></strong> before
      {{ nextMonthName }} 1st!
    </div>
    <div v-else-if="percentSpent < 75" class="alert-text ms-0 mt-3">
      <span class="fw-bold">Keep it up!</span>
      To get the most value out of your Moxey you should spend
      {{ spent.value === 0 ? "" : "an additional" }}
      <strong><moxey-value :value="remain.value" /></strong> before
      {{ nextMonthName }} 1st!
    </div>
    <div v-else-if="percentSpent < 100" class="alert-text ms-0 mt-3">
      <span class="fw-bold">Almost there!</span>
      To get the most value out of your Moxey you should spend
      {{ spent.value === 0 ? "" : "an additional" }}
      <strong><moxey-value :value="remain.value" /></strong> before
      {{ nextMonthName }} 1st!
    </div>
    <div
      v-else-if="
        percentSpent >= 100 &&
        user.account.tradeBalance >= 0 &&
        user.memberMeta.percentStagnant > 25
      "
      class="alert-text ms-0 mt-3"
    >
      <span class="fw-bold">Great work!</span>
      Any additional expenditures you make this month will help reduce your
      Moxey balance.
    </div>

    <div v-if="user.exchangeFlags?.showCommunityFee">
      <div class="d-flex justify-content-between align-items-center mt-3">
        <div class="alert-text ms-0 fs-6">{{ monthName }} Community Fee</div>
        <div
          class="whats-this ms-2"
          @click="$emit('clickedShortcut', 'CommunityFeeInfo')"
        >
          <i class="fas fa-circle-question"></i>
        </div>
      </div>
      <div v-if="communityFee.value === 0" class="alert-text ms-0 mt-0">
        Congratulations! You have no
        {{ monthName }} Community Fee.
      </div>
      <div v-else-if="percentSpent < 100" class="alert-text ms-0 mt-0">
        If you meet your spending target, your community fee will be waived.
        Otherwise, on {{ nextMonthName }} 1st, you will pay:
      </div>
      <div v-else-if="percentSpent >= 100" class="alert-text ms-0 mt-0">
        Since you've met your spending target, your {{ monthName }} community
        fee has been waived.
      </div>
      <div v-if="communityFee.value > 0">
        <div class="d-flex justify-content-center align-items-center mt-3">
          <div
            v-if="communityFee.value > discountedFee.value"
            class="waived d-flex align-items-center me-3"
          >
            <div class="fee-text fw-lighter text-end">Regular<br />Fee</div>
            <div class="d-flex position-relative">
              <div class="strike position-absolute" />
              <div class="p-1 d-flex align-items-center">
                <moxey-value :value="communityFee.value" :pretty="true" />
              </div>
            </div>
          </div>

          <div v-if="percentSpent >= 100" class="fee d-flex align-items-center">
            <div class="d-flex position-relative">
              <div class="p-1 d-flex align-items-center">
                <moxey-value :value="0.0" :pretty="true" />
              </div>
            </div>
            <div class="fee-text fw-lighter">Spending<br />Target Met</div>
          </div>

          <div v-else class="fee d-flex align-items-center">
            <div class="d-flex position-relative">
              <div class="p-1 d-flex align-items-center">
                <moxey-value :value="discountedFee.value" :pretty="true" />
              </div>
            </div>
            <div class="fee-text fw-lighter">Discounted<br />Fee*</div>
          </div>
        </div>

        <div
          v-if="percentSpent < 100 && communityFee.value > discountedFee.value"
        >
          <div v-if="gracePeriodEnd" class="alert-text ms-0 mt-3">
            <em
              >*Moxey Community Fee is currently being phased in and a discount
              has been applied. This grace period will end on
              {{ gracePeriodEnd }}.</em
            >
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
  </div>
</template>

<script>
import currency from "currency.js";
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import MoxeyValue from "../../../Shared/moxey/MoxeyValue.vue";
import chroma from "chroma-js";

export default {
  name: "SpendingTarget",
  components: {
    MoxeyValue,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      mainImageError: false,
      subImageError: 0,
      subImageLoading: true,
    };
  },
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      loggingIn: (state) => state.app.all.loggingIn,
      refreshing: (state) => state.app.all.refreshing,
    }),
    monthName() {
      return dayjs().format("MMMM");
    },
    nextMonthName() {
      return dayjs().startOf("month").add(1, "month").format("MMMM");
    },
    st() {
      return this.user.spendingTarget;

      // return currency(346.87, {
      //   symbol: "",
      // });
    },
    spendingTarget() {
      return currency(this.st.finalSpendingTarget, {
        symbol: "",
      });
    },
    spent() {
      return currency(this.st.purchasesThisMonth, {
        symbol: "",
      });

      // return currency(247.45, {
      //   symbol: "",
      // });
    },
    remain() {
      return this.spendingTarget.subtract(this.spent);
    },
    percentSpent() {
      return Math.max(
        Math.min(1, this.spent.value / this.spendingTarget.value) * 100,
        1
      ).toFixed();
    },
    color() {
      const scale = chroma
        .scale([
          "rgba(220, 53, 69, 1)",
          "rgba(220, 53, 69, 1)",
          "rgba(255, 193, 7, 1)",
          "rgba(0, 175, 108, 1)",
        ])
        .domain([0, 0.5, 0.99, 1]);

      return `rgba(${scale(this.percentSpent / 100)
        .rgba()
        .join(",")})`;
    },
    communityFee() {
      return currency(this.st.calculatedFee, {
        symbol: "",
      });
    },
    discountedFee() {
      // const cf = this.user.communityFee;

      // return currency(cf.discountedFee, {
      //   symbol: "",
      // });

      return currency(this.st.finalFee, {
        symbol: "",
      });
    },
    gracePeriodEnd() {
      // const cf = this.user.communityFee;

      // return dayjs(cf.gracePeriodEnd).format("MMMM D, YYYY");
      if (dayjs().isAfter(dayjs(this.st.maturityDate))) return undefined;

      return dayjs(this.st.maturityDate).format("MMMM D, YYYY");
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    ...mapActions("app", ["refreshUser"]),
  },
  beforeMount() {
    this.subImageError = 0;
  },
};
</script>

<style scoped>
.account {
  width: 100%;
}

.icons {
  position: relative;
}

.biz-icon {
  background-color: var(--mx-dark);
  color: var(--mx-white);
}

.person-icon {
  background-color: var(--mx-blue);
  color: var(--mx-dark);
}

.contact-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin: 0 20px 0 0;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid #a6a6a630;
}

.contact-icon div,
.sub-image div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.contact-icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sub-image div {
  font-size: 12px;
  line-height: 30px;
}

.contact-icon img,
.sub-image img {
  height: 100%;
}

.sub-image {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 15px;
  margin: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #a6a6a630;
  position: absolute;
  right: 0;
  top: 40px;
}

.name {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.sub-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 11px;
}

.close-btn div {
  cursor: pointer;
}

.divider {
  width: 100%;
  background-color: var(--mx-white);
  height: 1px;
  margin: 20px 0;
}

.bp-title {
  font-size: 20px;
}

.bp-amt {
  margin: 5px 0 10px;
  font-size: 42px;
  line-height: 42px;
}

.bp-amt .cents {
  font-size: 0.6em;
  line-height: 22px;
  padding: 0 0 0 8px;
}

.whats-this {
  font-size: 18px;
  cursor: pointer;
}

.cra-outer {
}

.cra-title {
  font-size: 14px;
}

.cra {
  font-size: 18px;
  font-weight: 400;
}

.cra-amt {
  margin: 5px 0 10px;
  font-size: 20px;
  line-height: 20px;
}

.cra-amt .cents {
  font-size: 14px;
  line-height: 16px;
  padding: 0 0 0 4px;
}

.mx-alert:not(:first-child) {
  margin: 15px 0 0;
}

.alert-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-text {
  font-size: 17px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-icon {
  font-size: 50px;
}

.mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.refresh-button {
  margin: 15px 0 0;
  text-align: center;
}

.refresh-button .btn {
  font-size: 14px;
  line-height: 17px;
  padding: 10px 20px;
  width: initial;
  border-radius: 20px;
}

.gem .letters {
  bottom: 0px;
  font-size: 0.95rem;
  color: darkgoldenrod;
}

.goals {
  font-size: 0.75em;
}

.progress {
  background-color: #e9ecef40;
}

.waived {
  font-size: 1em;
  color: var(--mx-grey);
}

.fee {
  font-size: 1em;
}

.fee-text {
  font-size: 0.35em;
  line-height: 1.1em;
}

.strike {
  width: 105%;
  top: 50%;
  border-bottom: 2px solid;
  transform: rotate(-15deg);
}
</style>
