<template>
  <span class="fa-layers fa-fw">
    <i
      class="fak fa-gem-background"
      style="color: forestgreen"
      data-fa-transform="grow-1"
    ></i>
    <i class="fa-thin fa-gem" style="color: white"></i>
    <i class="fak fa-gem-overlay" style="color: #71ffa6"></i>
  </span>
</template>

<script>
//let test = "hello";

export default {
  name: "RewardIcon",
};
</script>

<style scoped></style>
