<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Terms and Conditions</div>
        </div>
      </div>
      <div class="text">
        <div>
          Before using Moxey, you must read and agree to the current Moxey Terms
          and Conditions.
        </div>
        <div>
          <div class="terms-wrapper">
            <div id="tnc" v-html="content"></div>
          </div>

          <div class="mt-3">
            <span class="mx-info-link" @click="loadTNC">
              <small
                ><i class="fa-thin fa-print"></i> View Printer Friendly.</small
              >
            </span>
          </div>

          <div class="d-flex mt-3">
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              id="agree-to-tnc"
              v-model="agree"
            />
            <label class="form-check-label" for="agree-to-tnc">
              I have read and agreed to the Moxey Terms and Conditions
            </label>
          </div>
        </div>
        <div class="text-center">
          <button
            class="btn btn-success"
            :disabled="!agree && !saving"
            @click="if (agree) agreeTNC();"
          >
            Continue
          </button>
        </div>

        <div class="text-center">
          <span
            class="mx-info-link"
            @click="
              logout({
                showLogin: true,
              })
            "
            ><small>Sign Out</small></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { api } from "@/utils/utils";
export default {
  name: "TNC",
  components: {},
  data() {
    return {
      content: "",
      loaded: false,
      agree: false,
      saving: false,
    };
  },
  props: {},
  computed: {
    ...mapState({
      appConfig: (state) => state.app.all.appConfig,
    }),
  },
  async mounted() {
    this.content = (await api("get", "core", `/util/tnc`)).text || "";
    this.loaded = true;
  },
  methods: {
    loadTNC() {
      const routeData = this.$router.resolve({ name: "TermsAndConditions" });
      console.log(routeData);
      window.open(routeData.href, "_blank");
    },
    async agreeTNC() {
      try {
        this.saving = true;

        const user = await api("post", "core", `/v2/user/`, {
          body: {
            attributes: {
              tncVersion: this.appConfig && this.appConfig.tncVersion,
            },
          },
        });

        this.updateAppStore({
          user: user,
        });

        this.saving = false;
        this.$emit("clickedShortcut", "Account");
      } catch (e) {
        console.log(e);
      }
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
      logout: "app/logout",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 30px 30px 0;
}

.text > div:not(:first-child) {
  margin: 30px 0 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.terms-wrapper {
  padding: 5px;
  height: calc(100vh - 430px);
  max-height: calc(100vh - 430px);
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

.btn-success {
  border-color: var(--mx-green);
  background-color: var(--mx-green);
  font-size: 18px;
  line-height: 36px;
  padding: 0 20px;
  border-radius: 12px;
}
</style>
