import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import VueGtag from "vue-gtag-next";

//import VueVirtualScroller from "vue-virtual-scroller";

import config from "./config/config";

axios.interceptors.request.use(
  (config) => {
    config.timeout = 30000;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    property: {
      id: config.googleMeasurementId,
      params: {
        send_page_view: false,
      },
    },
  })
  //.use(VueVirtualScroller)
  .mount("#app");
