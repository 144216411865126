<template>
  <div v-if="summary">
    <div :class="['content', 'dark', 'for-menu', !lg ? 'tight' : '']">
      <div class="row">
        <div class="mx-alert d-flex align-items-center">
          <div class="chart-holder position-relative" v-show="!gettingStats">
            <canvas :id="chartId"></canvas>
            <div
              class="score position-absolute d-flex align-items-center justify-content-center"
            >
              <div>{{ Math.ceil(stats.totalScore) }}</div>
            </div>
          </div>
          <div
            class="chart-holder position-relative skeleton"
            v-show="gettingStats"
          ></div>
          <div class="flex-fill">
            <div class="alert-text">
              <img src="@/assets/moxey-score-dark.svg" height="12" />
            </div>
            <div class="alert-info-text" v-show="!gettingStats">
              Your Moxey Score is currently
              <span class="score-text">{{ scoreText }}</span
              >.
              <span
                class="mx-alert-link"
                @click="
                  $router.push('/dashboard');
                  closeLeftBar();
                "
                >Learn how to get the most out of your Moxey membership.</span
              >
            </div>
            <div class="alert-info-text skeleton" v-show="gettingStats"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="title">
      <div class="header">
        <img src="@/assets/moxey-score-dark.svg" height="30" />
      </div>
    </div>
    <div class="d-block d-md-flex align-items-center">
      <div class="col-12 col-md-5 col-lg-4 score-graph">
        <div class="sub">
          <div class="chart-holder position-relative" v-show="!gettingStats">
            <canvas :id="chartId"></canvas>
            <div
              class="score position-absolute d-flex align-items-center justify-content-center"
            >
              <div>
                <div class="main-score">
                  {{ Math.ceil(stats.totalScore) }}
                </div>
                <div class="sub-score">out of {{ 850 }}</div>
              </div>
            </div>
          </div>

          <div
            class="chart-holder position-relative skeleton"
            v-show="gettingStats"
          ></div>
        </div>
      </div>

      <div class="col-12 col-md-7 col-lg-8 score-info" v-show="!gettingStats">
        <div>
          Your Moxey<strong><em>Score</em></strong>
          {{ scoreText === "Needs Improvement" ? "currently" : "is currently" }}
          <span class="score-text">{{ scoreText }}</span
          >.
        </div>
        <div class="score-sub-text">
          Your Moxey<strong><em>Score</em></strong> is a representation of how
          well you are making use of your Moxey membership. A high score means
          you’re taking full advantage of your membership. A lower score
          indicates that there is more value you can achieve from your
          membership.
        </div>

        <div v-if="stats.age < 360">
          As a new member, your score will become establised as you use Moxey.
        </div>
        <div v-if="helping.length > 0">Here's what's helping your score:</div>

        <div
          v-for="(h, i) in helping"
          class="d-flex align-items-center"
          :key="i"
        >
          <div class="pip">
            <i class="fa-duotone fa-circle-check text-mx-green"></i>
          </div>
          <div class="pip-text">
            {{ h }}
          </div>
        </div>

        <div v-if="advice.length > 0">
          Here's steps you can take to improve your score:
        </div>

        <div
          v-for="(a, i) in advice"
          class="d-flex align-items-center"
          :key="i"
        >
          <div class="pip">
            <i class="fa-duotone fa-circle-exclamation text-warning"></i>
          </div>
          <div class="pip-text">
            {{ a }}
          </div>
        </div>
      </div>

      <div class="col-12 col-md-7 col-lg-8 score-info" v-show="gettingStats">
        <div class="skeleton"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { api } from "@/utils/utils";
import {
  ArcElement,
  Chart,
  DoughnutController,
  PieController,
  Tooltip,
} from "chart.js";
import chroma from "chroma-js";

Chart.register(DoughnutController, ArcElement, PieController, Tooltip);

export default {
  name: "MxScore",
  components: {},
  data() {
    return {
      updating: false,
      stats: {},
      ringChart: undefined,
      miniChart: undefined,
      gettingStats: true,
    };
  },
  props: {
    memberId: Number,
    summary: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    chartId: {
      type: String,
      default: "mx-score",
    },
  },
  async mounted() {
    await this.getStats();

    if (this.stats.grade && this.stats.grade !== "cannot grade") {
      const ringScale = chroma
        .scale([
          "rgba(220, 53, 69, 1)",
          "rgba(220, 53, 69, 1)",
          "rgba(255, 193, 7, 1)",
          "rgba(0, 175, 108, 1)",
          "rgba(0, 175, 108, 1)",
        ])
        .domain([0, 0.1, 0.5, 0.9, 1]);

      const color = `rgba(${ringScale(this.stats.totalScore / 850)
        .rgba()
        .join(",")})`;

      this.$nextTick(() => {
        if (!this.summary) {
          this.ringChart = new Chart(document.getElementById(this.chartId), {
            type: "doughnut",
            data: {
              datasets: [
                {
                  label: "Mx Score",
                  data: [100],
                  backgroundColor: [color],
                  radius: "100%",
                  hoverOffset: 0,
                  hoverBorderWidth: 0,
                  borderWidth: 0,
                  circumference: 360 * (this.stats.totalScore / 850),
                  borderRadius: 500,
                  cutout: "80%",
                },
                {
                  label: "Background",
                  data: [100],
                  backgroundColor: ["rgba(0, 0, 0, 0.1)"],
                  radius: "103%",
                  hoverOffset: 0,
                  hoverBorderWidth: 0,
                  borderWidth: 0,
                  cutout: "90%",
                },
              ],
            },
            options: {
              plugins: {
                tooltip: {
                  enabled: false,
                },
              },
            },
          });
        } else {
          this.miniChart = new Chart(document.getElementById(this.chartId), {
            type: "doughnut",
            data: {
              datasets: [
                {
                  label: "Mx Score",
                  data: [100],
                  backgroundColor: [color],
                  radius: "100%",
                  hoverOffset: 0,
                  hoverBorderWidth: 0,
                  borderWidth: 0,
                  circumference: 360 * (this.stats.totalScore / 850),
                  borderRadius: 500,
                  cutout: "60%",
                },
                {
                  label: "Background",
                  data: [100],
                  backgroundColor: ["rgba(0, 0, 0, 0.1)"],
                  radius: "103%",
                  hoverOffset: 0,
                  hoverBorderWidth: 0,
                  borderWidth: 0,
                  cutout: "90%",
                },
              ],
            },
            options: {
              plugins: {
                tooltip: {
                  enabled: false,
                },
              },
            },
          });
        }
      });
    }
  },
  activated() {
    if (!this.updating) {
      this.getStats();
    }
  },
  computed: {
    helping() {
      const h = [];
      if (
        this.stats.shortTermWobble > 25 &&
        this.unhealthyPenalty > 0 &&
        this.type === "positive"
      ) {
        h.push(
          `Recently you've been making more purchases and spending down your positive Moxey balance.`
        );
      } else if (
        this.stats.shortTermWobble >= 25 &&
        this.unhealthyPenalty > 0 &&
        this.type === "negative"
      ) {
        h.push(`Recently you've been making more sales and addressing your
                    negative Moxey balance.`);
      } else if (
        this.stats.salesBoost >= 25 &&
        this.stats.purchasesBoost > 25
      ) {
        h.push(
          `You've consistently bought from and sold to other Moxey members.`
        );
      } else if (this.stats.salesBoost >= 25) {
        h.push(
          `You’ve consistently sold goods or services to other Moxey members.`
        );
      } else if (this.stats.purchasesBoost >= 25) {
        h.push(
          `You’ve consistently bought goods or services from other Moxey members.`
        );
      }

      if (this.stats.unhealthyPenalty === 0 && this.stats.age > 360) {
        h.push(
          `Historically you've done a great job of keeping your Moxey
                    sales and purchases balanced.`
        );
      }

      if (this.stats.craReloadBoost === 50 && this.stats.buyingPower > 0) {
        h.push(
          `You've kept your Cash Reserve Account topped up, allowing
                    maximum use of your Moxey Buying Power.`
        );
      }

      if (this.stats.recurCount === 1) {
        h.push(`You have a recurring purchase set up.`);
      }

      if (this.stats.recurCount > 1) {
        h.push(`You have multiple recurring purchases set up.`);
      }

      return h;
    },
    advice() {
      const h = [];

      if (this.stats.age < 360 && this.stats.sales < 25) {
        h.push(`Make your first sale to another Moxey member.`);
      }

      if (this.stats.age < 360 && this.stats.purchases < 25) {
        h.push(`Make your first purchase from another Moxey member.`);
      }

      if (
        (this.stats.unhealthyPenalty !== 0 || this.stats.purchasesBoost < 25) &&
        this.stats.shortTermWobble <= 0 &&
        this.stats.type === "positive" &&
        this.stats.balance > 0
      ) {
        h.push(
          `Spend down your Moxey balance by making more purchases from
                    other Moxey members.`
        );
      } else if (
        (this.stats.unhealthyPenalty !== 0 || this.stats.salesBoost < 25) &&
        this.stats.shortTermWobble <= 0 &&
        this.stats.type === "negative" &&
        this.stats.balance > 0
      ) {
        h.push(
          `Address your Moxey deficit by making more sales to
                    other Moxey members.`
        );
      } else if (
        (this.stats.unhealthyPenalty !== 0 || this.stats.purchasesBoost < 25) &&
        this.stats.shortTermWobble > 0 &&
        this.stats.type === "positive" &&
        this.stats.balance > 0
      ) {
        h.push(
          `Continue to spend down your Moxey balance by making
                    purchases from other Moxey members.`
        );
      } else if (
        (this.stats.unhealthyPenalty !== 0 || this.stats.salesBoost < 25) &&
        this.stats.shortTermWobble > 0 &&
        this.stats.type === "negative" &&
        this.stats.balance > 0
      ) {
        h.push(
          `Continue to address your Moxey deficit by selling to other
                    Moxey members.`
        );
      }

      if (this.stats.craReloadBoost < 50 && this.stats.buyingPower > 0) {
        h.push(
          `Add additional funds to your Cash Reserve Account so that
                    you're able to make full use of your Moxey Buying Power.`
        );
      }

      if (this.stats.recurCount === 0) {
        h.push(`Set up a recurring purchase with another member.`);
      } else if (this.stats.recurCount < 5) {
        h.push(`Set up additional recurring purchases with other members.`);
      }

      return h;
    },
    scoreText() {
      const s = this.stats.totalScore;

      return s >= 740
        ? "Exceptional"
        : s >= 630
        ? "Very Good"
        : s >= 520
        ? "Good"
        : s >= 410
        ? "Fair"
        : "Needs Improvement";
    },
    ...mapState({
      account: (state) => state.app.all.user.account,
      testMode: (state) => state.app.all.testMode,
      user: (state) => state.app.all.user,
    }),
  },
  watch: {},
  methods: {
    async getStats() {
      try {
        this.gettingStats = true;
        this.updating = true;

        this.stats = this.testMode
          ? this.user.mxScore
          : await api(
              "get",
              "banking",
              `/v2/mxscore${this.memberId ? `?memberId=${this.memberId}` : ""}`
            );

        this.stats.unhealthy =
          this.stats.unhealthy91_180 +
          this.stats.unhealthy181_270 +
          this.stats.unhealthy271_360;
      } catch (e) {
        console.log(e);
      }

      this.gettingStats = false;
    },
    async apiTest() {
      console.log("test");
      const res = await api("get", "core", `/v2/cognito`);

      console.log(res);
    },
    closeLeftBar() {
      this.updateAppStore({
        showLeftBar: false,
      });
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
  deactivated() {
    if (this.ringChart) this.ringChart.destroy();
    if (this.segmentChart) this.segmentChart.destroy();
    if (this.threeRings) this.threeRings.destroy();
  },
  beforeUnmount() {
    if (this.ringChart) this.ringChart.destroy();
    if (this.segmentChart) this.segmentChart.destroy();
    if (this.threeRings) this.threeRings.destroy();
  },
};
</script>

<style scoped>
.page-title {
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0 0;
}

.main-score {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 10px;
}

.sub-score {
  font-size: 36px;
  font-weight: 300;
}

/*sub item*/
.content.dark,
.content.black {
  color: var(--mx-white);
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 30px;
}

.sub {
  margin: 15px 0;
  font-size: 14px;
  line-height: 20px;
}

.sub .alert-icon {
  font-size: 60px;
  margin: 0 15px 0 0;
}

.sub .mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-white);
}

.sub .bar {
  width: 100%;
  height: 30px;
  border-radius: 10px;
}

.sub .pointer {
  width: calc(100% - 20px);
}

.sub .needle {
  font-size: 30px;
  width: 20px;
}

.sub .score {
  text-align: center;
  margin: 15px 0;
  font-size: 48px;
  line-height: 48px;
  font-weight: 500;
}

.chart-holder {
  margin: 15px 0;
}

.chart-holder .score {
  height: 100%;
  width: 100%;
  top: 0;
  margin: 0;
}

.sub .bottom-text {
  margin: 15px 0 0;
}

#chart {
  max-width: 100%;
}

.small-score {
  font-size: 24px;
  line-height: 30px;
}

.score-text {
  font-weight: 600;
}

.score-info > div:not(:last-of-type) {
  margin: 0 0 15px;
}

.score-sub-text {
  font-weight: 300;
}

.score-info {
  padding: 30px 0 0 0;
}

.pip,
.pip-text {
  margin: 0 0 0 15px;
}

.pip-text {
  font-weight: 300;
}

.chart-holder.skeleton {
  padding: 50%;
  border-radius: 100%;
}

.score-info {
  height: 100%;
}

.score-info .skeleton {
  height: 100%;
}

@media (min-width: 768px) {
  .page-title {
    font-size: 24px;
    font-weight: 500;
    padding: 0;
  }

  .score-info {
    padding: 0 0 0 30px;
  }
}

@media (min-width: 992) {
}

.tight {
  max-width: 450px;
}

.for-menu > div {
  border-radius: 0 !important;
}

.for-menu .alert-text {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 0 0 30px;
}

.for-menu .alert-info-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin: 0 0 0 30px;
  min-height: 55px;
}

.for-menu .mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.for-menu canvas {
  max-width: 70px;
}

.for-menu .chart-holder {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
}

.for-menu .chart-holder.skeleton {
  padding: 0;
  min-height: 80px;
  min-width: 80px;
  border-radius: 100%;
}
</style>
