<template>
  <div
    :class="[
      'position-relative cursor-pointer',
      { row, 'flex-fill': row, 'mb-3': row },
    ]"
    style="margin-top: 5px"
  >
    <template v-if="!item?.name">
      <div :class="{ 'col-4': row }">
        <result-image
          :skeleton="true"
          :style="{ 'border-radius': row ? '0.5em' : '1em' }"
        />
      </div>
      <div :class="{ col: row }">
        <div
          class="skeleton-box result-text fw-bolder"
          :class="{ 'mt-1': !row }"
        >
          SPOOKEY
        </div>

        <div class="skeleton-box result-text" :class="{ 'mt-1': !row }">
          SPOOKEY
        </div>
        <div
          v-if="!row"
          class="skeleton-box result-text"
          :class="{ 'mt-1': !row }"
        >
          SPOOKEY
        </div>
      </div>
    </template>
    <template v-else>
      <result-banner
        v-if="!row && !item.noGiftCards && !item.standby"
        :item="item"
      />

      <div :class="{ 'col-4': row }">
        <div class="position-relative">
          <div
            class="notice position-absolute h-100 w-100 d-flex flex-column justify-content-end border"
            :style="[
              { 'border-radius': row ? '0.5em' : '1em', overflow: 'hidden' },
            ]"
          >
            <div v-if="item.standby && !row" class="bg-warning px-3">
              <small><i class="fas fa-fw fa-pause"></i> On standby</small>
            </div>
            <div v-if="item.noGiftCards && !row" class="bg-warning px-3">
              <small
                ><i class="fak fa-fw fa-solid-gift-card-slash"></i> No gift
                cards</small
              >
            </div>
          </div>
          <result-image
            class="border"
            :img="item.hero"
            :style="{ 'border-radius': row ? '0.5em' : '1em' }"
          />
        </div>
      </div>
      <div :class="{ col: row }">
        <div
          class="d-flex align-items-center"
          :class="{
            'justify-content-between': !row,
            'justify-content-start': row,
          }"
        >
          <div :class="['result-text fw-bolder clamp-2', { 'mt-1': !row }]">
            {{ item.name }}
          </div>
          <div
            class="ms-3 h-100 d-flex justify-content-center align-items-center"
            style="margin: -0.5em 0"
            v-if="item.isNew"
          >
            <div style="color: gold; font-size: 1.9em">
              <i class="fas fa-certificate" style="color: gold"></i>
            </div>
            <div
              class="position-absolute fw-bold"
              style="
                font-size: 0.65em;
                line-height: 0;
                transform: rotate(15deg);
              "
            >
              NEW!
            </div>
          </div>
        </div>
        <div
          v-if="item.short"
          :class="['result-text sub', { 'mt-1 clamp-2': !row, 'clamp-1': row }]"
        >
          {{ item.short }}
        </div>
        <div
          :class="[
            'result-text sub',
            {
              'mt-1 clamp-2': !row,
              'clamp-1': row,
              'text-proper-case': !item.subtitle,
            },
          ]"
        >
          {{
            item.subtitle ||
            [
              item?.location?.geo?.distance
                ? `${(item?.location?.geo?.distance * 0.00062).toFixed(1)}mi - `
                : "",
              [item?.location?.city?.toLowerCase(), item?.location?.state].join(
                ", "
              ),
            ]
              .filter((s) => s)
              .join(" ")
          }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import ResultImage from "./ResultImage.vue";
import ResultBanner from "./ResultBanner.vue";

//let test = "hello";

export default {
  name: "ResultItem",
  components: { ResultImage, ResultBanner },
  data() {
    return {
      hits: undefined,
      loading: true,
    };
  },
  props: {
    item: Object,
    i: Number,
    row: Boolean,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.result-text {
  font-size: 0.75em;
}

.sub {
  font-size: 0.65em;
}

.clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.new-badge {
  z-index: 3;
  font-size: 1.75em;
  line-height: 0;
  top: -5px;
  right: -10px;
}

.new-badge-text {
  z-index: 3;
  font-size: 0.3em;
  transform: rotate(20deg);
}
</style>
