<template>
  <span v-if="fat">
    <i class="fak fa-mx-sign-fat"></i>
  </span>
  <span v-else>
    <i class="fak fa-mx-sign"></i>
  </span>
</template>

<script>
//let test = "hello";

export default {
  name: "MoxeyIcon",
  props: {
    fat: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
