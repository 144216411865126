<template>
  <!-- <div class="splash active">
    <div class="splash-icon"></div>
  </div> -->
  <div>
    <div tabindex="0" />
    <div class="main" style="overflow-x: hidden">
      <transition name="fade">
        <div
          id="app-loader"
          class="d-flex flex-column align-items-center justify-content-center"
          v-if="$route.meta.checkLoggedIn && !user"
        >
          <div class="app-loader-title header-like text-center">
            <i class="fas fa-sync fa-spin"></i> Loading ...
          </div>
        </div>
        <main class="content" v-else-if="$route">
          <div id="main-content" class="container-fluid px-0">
            <div class="row mx-0">
              <div class="mx-0 px-0">
                <router-view v-slot="{ Component }">
                  <component :is="Component" />
                </router-view>
              </div>
            </div>
          </div>
        </main>
      </transition>
    </div>
    <Backdrop @close-bars="resetBars" />
    <LeftBar ref="leftBar" v-if="!$route?.meta?.noLeftBar" />
    <modal
      :showModal="showUpgradeModal"
      :modalOptions="upgradeModalOptions"
      :modalBSOptions="upgradeModalBSOptions"
      @modalClosed="() => {}"
    >
      <div
        id="app-loader"
        class="d-flex flex-column align-items-center justify-content-center"
        v-if="
          (promptReload || ($route.meta.checkLoggedIn && !user)) &&
          !declineModals
        "
      >
        <div class="hero-bg">
          <div class="is-overlay hero-x-behind"></div>
          <div class="is-overlay hero-x-front"></div>
        </div>
        <div class="app-loader-content">
          <div class="mx-logo">
            <img src="@/assets/moxey_dark_bg.svg" />
          </div>
          <div v-if="promptReload">
            <div class="app-loader-title header-like text-center">
              We've made a few changes around here!
            </div>
            <div class="app-loader-text text-center">
              Please click here to upgrade.
            </div>
            <div class="app-loader-btn text-center">
              <button
                type="button"
                class="btn btn-outline-light"
                @click="hardReload"
              >
                Upgrade
              </button>
            </div>
          </div>

          <div v-else>
            <div class="app-loader-title header-like text-center">
              <i class="fas fa-sync fa-spin"></i> Loading ...
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      :showModal="!showUpgradeModal && showWtModal && !declineModals"
      :modalOptions="walkthroughModalOptions"
      :modalBSOptions="walkthroughModalBSOptions"
      @modalClosed="() => {}"
    >
      <guide />
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import { hideAppOverflow } from "@/utils/utils.js";
import Backdrop from "@/components/CoreApp/Backdrop.vue";
import LeftBar from "@/components/CoreApp/LeftBar.vue";
import Modal from "./components/Modal/Modal.vue";
import Guide from "./components/FrontPage/Guide.vue";

//let test = "hello";

export default {
  name: "App",
  components: {
    LeftBar,
    Backdrop,
    Modal,
    Guide,
  },
  data() {
    return {
      contentCentered: true,
      count: 0,
      isSwipingCard: false,
      swipeCardNumber: [],
      showUpgradeModal: false,
      showWtModal: false,
      declineModals: false,
      upgradeModalOptions: {
        fullscreen: true,
      },
      upgradeModalBSOptions: {
        backdrop: "static",
        keyboard: false,
      },
      walkthroughModalOptions: {
        dialogClass: "modal-lg modal-dialog-centered modal-fullscreen-lg-down",
      },
      walkthroughModalBSOptions: {},
    };
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);

    window.addEventListener("keydown", (e) => {
      const el = document.activeElement;
      if (
        ["%"].includes(e.key) &&
        (!el || el.id === "" || el.id === "contactSearchId")
      ) {
        const tmp = document.createElement("input");
        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
        this.isSwipingCard = true;
        this.swipeCardNumber = [];
      } else if (this.isSwipingCard && ["?"].includes(e.key)) {
        this.isSwipingCard = false;
        this.updateAppStore({
          swipedCard: this.swipeCardNumber.join(""),
          leftBarComp: "ChargeCard",
        });
        this.swipeCardNumber = [];
      } else if (this.isSwipingCard && /[0-9]/g.test(e.key)) {
        this.swipeCardNumber.push(e.key);
      }
    });

    this.onResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
      showLeftBar: (state) => state.app.all.showLeftBar,
      showRightBar: (state) => state.app.all.showRightBar,
      appConfig: (state) => state.app.all.appConfig,
      promptReload: (state) => state.app.all.promptReload,
      showWalkthroughModal: (state) => state.app.all.showWalkthroughModal,
    }),
  },
  watch: {
    user(post) {
      if (!post) {
        this.$router.push(this.$route);
      }
    },
    showLeftBar() {
      this.toggleBodyScroll();
    },
    showRightBar() {
      this.toggleBodyScroll();
    },
    promptReload(post) {
      if (post) {
        this.updateAppStore({
          showWalkthroughModal: false,
        });
        this.$nextTick(() => {
          this.showUpgradeModal = true;
        });
      } else {
        this.showUpgradeModal = false;
      }
    },
    showWalkthroughModal(post) {
      const noTour =
        new URLSearchParams(window.location.search).get("noTour") !== null;
      if (post && !this.promptReload && !noTour) {
        this.showWtModal = true;
      } else {
        this.showWtModal = false;
      }
    },
  },
  methods: {
    resetBars() {
      this.$refs.leftBar.resetBar();
    },
    toggleBodyScroll() {
      if (!this.showRightBar && !this.showLeftBar) {
        document.body.style.overflowY = "auto";
      } else {
        document.body.style.overflowY = "hidden";
      }
    },
    getTransition() {
      return this.app.mainTransition;
    },
    hideAppOverflow,
    onResize() {
      const viewParams = {
        width: document.body.clientWidth,
      };

      const breakpoints = [
        ["4xl", 2400, "fade"],
        ["xxxl", 1800, "fade"],
        ["xxl", 1400, "fade"],
        ["xl", 1200, "fade"],
        ["lg", 992, "fade"],
        ["md", 768, "slide"],
        ["sm", 576, "slide"],
        ["xs", 0, "slide"],
      ];

      const bp = breakpoints.find((b) => viewParams.width >= b[1]);

      viewParams.breakpoint = bp[0];
      viewParams.bpCutoff = bp[1];
      viewParams.mainTransition = bp[2];

      this.updateAppStore({ viewParams: viewParams });
    },
    hardReload() {
      location.reload();
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
      preloadSearch: "search/preloadData",
      updateStateOnLoad: "app/updateStateOnLoad",
    }),
  },
  beforeMount() {},
};
</script>

<style>
html {
  background-color: #03211a;
}

:root {
  --mx-black: #03211a;
  --mx-dark: #0b4034;
  --mx-green: #00af6c;
  --mx-blue: #c6e4ea;
  --mx-olive: #647064;
  --mx-grey: #a6a6a6;
  --mx-lightgray: #ededed;
  --mx-white: #fefff9;
}

.text-mx-black {
  color: var(--mx-black);
}

.text-mx-dark {
  color: var(--mx-dark);
}

.text-mx-green {
  color: #009059;
}

.text-mx-blue {
  color: var(--mx-blue);
}

.text-mx-white {
  color: var(--mx-white);
}

.row {
  --bs-gutter-x: 15px;
}

body {
  margin: auto;
  max-width: 1440px;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: white;
}

main {
  width: 100%;
  position: relative;
  /* overflow-x: hidden; */
}

h1,
h2,
h3,
h4,
h5,
h6,
.header-like {
  font-family: Bitter, serif;
  color: inherit;
}

body,
input,
select,
textarea {
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.mono {
  font-family: "Cutive Mono", monospace;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.notification-box {
  z-index: 200;
}

*::-webkit-scrollbar {
  display: none;
}
.disable-select,
.disable-select * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.vue3-resize-observer {
  height: 0;
}

.vue3-resize-observer > object {
  height: 0;
}

#app {
  position: relative;
  /* overflow: hidden; */
  background-color: white;
}

.password-like {
  font-family: text-security-disc;
  /* Use -webkit-text-security if the browser supports it */
  -webkit-text-security: disc;
}

.password-like::placeholder {
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

#app-loader {
  position: fixed;
  background-color: var(--mx-black);
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  opacity: 1;
  transition: opacity 20s ease-in;
}

#app-loader.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

#app-loader.fade-leave-to {
  opacity: 0;
}

#app-loader.fade-leave-from {
  opacity: 1;
}

.app-loader-content {
  position: relative;
  color: var(--mx-white);
  width: calc(100% - 60px);
  max-width: 415px;
}

.mx-logo img {
  width: 100%;
  margin: 0 0 30px 0;
}

.app-loader-title {
  font-size: 36px;
  line-height: 48px;
  margin: 0 0 30px 0;
}

.app-loader-text {
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 30px 0;
}

.btn-outline-light-custom {
  color: var(--mx-white);
  border-color: var(--mx-white);
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 200px;
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active,
.btn-outline-light:hover {
  color: var(--mx-dark);
  background-color: var(--mx-white);
}

.hero-bg {
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--mx-black);
  background-image: url("./assets/xo.svg");
}

.hero-x-behind {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-clip-path: polygon(40% 0, 60% 0, 100% 100%, 80% 100%);
  clip-path: polygon(40% 0, 60% 0, 100% 100%, 80% 100%);
  -webkit-animation-name: heroXBehind;
  animation-name: heroXBehind;
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  background-image: url("./assets/xo-behind.svg");
}

.hero-x-front {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-clip-path: polygon(100% 0, 80% 0, 40% 100%, 60% 100%);
  clip-path: polygon(100% 0, 80% 0, 40% 100%, 60% 100%);
  -webkit-animation-name: heroXFront;
  animation-name: heroXFront;
  -webkit-animation-duration: 12s;
  animation-duration: 12s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  background-image: url("./assets/xo-front.svg");
}

.is-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes heroXBehind {
  0% {
    -webkit-clip-path: polygon(40% 0, 60% 0, 100% 100%, 80% 100%);
    clip-path: polygon(40% 0, 60% 0, 100% 100%, 80% 100%);
  }
  22% {
    -webkit-clip-path: polygon(34% 0, 54% 0, 106% 100%, 86% 100%);
    clip-path: polygon(34% 0, 54% 0, 106% 100%, 86% 100%);
  }
  54% {
    -webkit-clip-path: polygon(45% 0, 65% 0, 95% 100%, 75% 100%);
    clip-path: polygon(45% 0, 65% 0, 95% 100%, 75% 100%);
  }
  70% {
    -webkit-clip-path: polygon(35% 0, 55% 0, 105% 100%, 85% 100%);
    clip-path: polygon(35% 0, 55% 0, 105% 100%, 85% 100%);
  }
  100% {
    -webkit-clip-path: polygon(40% 0, 60% 0, 100% 100%, 80% 100%);
    clip-path: polygon(40% 0, 60% 0, 100% 100%, 80% 100%);
  }
}

@keyframes heroXFront {
  0% {
    -webkit-clip-path: polygon(100% 0, 80% 0, 40% 100%, 60% 100%);
    clip-path: polygon(100% 0, 80% 0, 40% 100%, 60% 100%);
  }
  28% {
    -webkit-clip-path: polygon(105% 0, 85% 0, 35% 100%, 55% 100%);
    clip-path: polygon(105% 0, 85% 0, 35% 100%, 55% 100%);
  }
  48% {
    -webkit-clip-path: polygon(96% 0, 76% 0, 46% 100%, 66% 100%);
    clip-path: polygon(96% 0, 76% 0, 46% 100%, 66% 100%);
  }
  76% {
    -webkit-clip-path: polygon(106% 0, 86% 0, 34% 100%, 54% 100%);
    clip-path: polygon(106% 0, 86% 0, 34% 100%, 54% 100%);
  }
  100% {
    -webkit-clip-path: polygon(100% 0, 80% 0, 40% 100%, 60% 100%);
    clip-path: polygon(100% 0, 80% 0, 40% 100%, 60% 100%);
  }
}

.skeleton {
  background-color: var(--mx-grey);
  opacity: 0.5;
  animation-duration: 0.5s;
  animation-name: strobe;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes strobe {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 0.2;
  }
}

#video-spinner > .carousel__viewport {
  padding: 0 0;
}

#event-spinner > .carousel__viewport {
  padding: 40px 0 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  color: transparent;
}

.skeleton-box::after {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(190, 190, 190, 0.25) 33%,
    rgba(201, 201, 201, 0.25) 66%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 0.75s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.text-proper-case {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
