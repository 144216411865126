<template>
  <div id="login">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Update Information</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>

      <div class="message">
        Please check and update the below information so that we can keep in
        touch with you about your account.
      </div>
      <form @submit.prevent="doValidate">
        <label for="phone">Mobile Phone Number</label>
        <input
          id="phone"
          name="phone"
          type="tel"
          class="form-control"
          :class="{ 'is-invalid': invalidFields.mobile }"
          v-model="phone"
          placeholder="Mobile Phone"
          :disabled="reseting"
          autocomplete="phone"
          @focus="invalidFields.mobile = undefined"
          @blur="() => doValidate(false)"
        />
        <div v-if="invalidFields.phone" class="error inner text-danger">
          <small>{{ invalidFields.phone }}</small>
        </div>
        <label for="phone" class="mt-3">Email Address</label>
        <input
          id="email"
          name="email"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': invalidFields.email }"
          v-model="email"
          placeholder="Email"
          :disabled="reseting"
          autocapitalize="none"
          autocomplete="email"
          @focus="invalidFields.email = undefined"
          @blur="() => doValidate(false)"
        />
        <div v-if="invalidFields.email" class="error inner text-danger">
          <small>{{ invalidFields.email }}</small>
        </div>
        <div class="button-holder text-center">
          <button
            v-if="!reseting"
            type="submit"
            class="btn btn-success w-100"
            :disabled="
              Object.keys(invalidFields).length > 0 ||
              phone.length === 0 ||
              email.length === 0
            "
          >
            Update Contact Info
          </button>
          <button v-else type="button" class="btn btn-success" disabled>
            <i class="fas fa-sync fa-spin"></i> Updating Contact Info
          </button>
        </div>
      </form>
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="reseting"
        @click="
          () => {
            if (!reseting) $emit('cancel');
          }
        "
      >
        Cancel
      </button>
      <div v-if="loginError" class="error text-danger">
        {{ loginError }}
      </div>
      <div class="message text-success" v-if="contactUpdated">
        Contact information updated.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { api } from "@/utils/utils";

export default {
  name: "ContactUpdate",
  components: {},
  data() {
    return {
      originalEmail: "",
      phone: "",
      email: "",
      loginError: undefined,
      reseting: false,
      step: 1,
      invalidFields: {},
      passwordUpdated: false,
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loginMessage: (state) => state.app.all.loginMessage,
      doNotVerify: (state) => state.app.all.doNotVerify,
    }),
  },
  mounted() {
    console.log(this.user.contact.public.email);
    this.phone = this.user.contact.public.phone_cell;
    this.email = this.user.contact.public.email;
    this.originalEmail = this.user.contact.public.email;
  },
  methods: {
    async doRequest() {
      this.loginError = undefined;
      this.invalidFields = {};

      this.reseting = true;

      try {
        await api("post", "core", `/forgot`, {
          body: {
            username: this.username,
          },
        });

        this.step = 2;
      } catch (e) {
        console.log(e);
        //Handle errors from proxy
        this.loginError = e.error || e;
      } finally {
        this.reseting = false;
      }
    },
    sanitizePhone() {
      if (this.phone) {
        this.phone = this.phone.replace(/[^0-9]/g, "");

        console.log(this.phone);

        this.phone = `(${this.phone.substring(0, 3)}) ${this.phone.substring(
          3,
          6
        )}-${this.phone.substring(6, 10)}`;
      }
    },

    doValidate(doSubmit) {
      this.invalidFields = {};

      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        ) ||
        this.email.length === 0
      )
        this.invalidFields.email = "Invalid email address.";

      this.sanitizePhone();

      console.log(
        !/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(this.phone)
      );

      if (!/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(this.phone))
        this.invalidFields.phone = "Invalid phone number.";

      if (Object.keys(this.invalidFields).length === 0 && doSubmit) {
        this.doReset();
      }
    },
    async doReset() {
      this.loginError = undefined;
      this.invalidFields = {};

      this.reseting = true;

      try {
        await api("post", "core", `/contact`, {
          body: {
            email: this.email,
            phone: this.phone,
            verify: true,
          },
        });

        this.contactUpdated = true;

        this.updateAppStore({
          user: {
            ...this.user,
            contact: {
              ...this.user.contact,
              public: {
                ...this.user.contact.public,
                email: this.email,
                phone_cell: this.phone,
                emailValidated:
                  this.email !== this.originalEmail
                    ? 0
                    : this.user.contact.public.emailValidated,
              },
            },
          },
        });

        if (
          this.email !== this.originalEmail ||
          !this.user.contact.public.emailValidated
        ) {
          await api("post", "core", `/verify`, {
            body: {
              email: this.email,
            },
          });

          this.updateAppStore({
            leftBarComp: "ValidateEmail",
            doNotVerify: true,
          });
        }
      } catch (e) {
        console.log(e);

        this.loginError = e.error || e;
      } finally {
        this.reseting = false;
      }
    },
    ...mapActions("app", ["updateAppStore"]),
  },
  beforeMount() {},
};
</script>

<style scoped>
#login {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 30px;
  line-height: 30px;
}
.sidebar-content {
  padding: 30px 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.btn-success {
  margin: 35px 0 15px;
  border-color: var(--mx-green);
  background-color: var(--mx-green);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 30px 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: calc(100% - 60px);
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
  text-align: start;
  width: calc(100% - 60px);
  margin: 0 30px;
}

.error.inner {
  margin: 0;
  width: 100%;
}

.whats-this {
  cursor: pointer;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.message {
  font-size: 20px;
  line-height: 28px;
  padding: 45px 30px 0;
}

form .message {
  padding: 45px 0 0;
}

label {
  font-size: 20px;
  line-height: 24px;
}
</style>
