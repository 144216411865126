<template>
  <div id="share">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between sidebar-item">
        <div class="d-flex">
          <div class="header-like">Share Listing</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="sidebar-item">Share {{ payload.memberName }} everywhere!</div>
      <a
        class="share"
        target="_blank"
        rel="noopener"
        :href="payload.facebook"
        @click="clickedLink('Clicked Facebook share link')"
      >
        <div class="sidebar-item">
          <img src="../../../assets/social/facebook.svg" />&nbsp;&nbsp;Facebook
        </div>
      </a>

      <a
        class="share"
        target="_blank"
        rel="noopener"
        :href="payload.twitter"
        @click="clickedLink('Clicked Twitter share link')"
      >
        <div class="sidebar-item">
          <img src="../../../assets/social/twitter.svg" />&nbsp;&nbsp;Twitter
        </div>
      </a>

      <a
        class="share"
        target="_blank"
        rel="noopener"
        :href="payload.pinterest"
        @click="clickedLink('Clicked Pinterest share link')"
      >
        <div class="sidebar-item">
          <img
            src="../../../assets/social/pinterest.svg"
          />&nbsp;&nbsp;Pinterest
        </div>
      </a>
      <a
        class="share"
        target="_blank"
        rel="noopener"
        :href="payload.linkedin"
        @click="clickedLink('Clicked LinkedIn share link')"
      >
        <div class="sidebar-item">
          <img src="../../../assets/social/linkedin.svg" />&nbsp;&nbsp;LinkedIn
        </div>
      </a>
      <a
        class="share"
        target="_blank"
        rel="noopener"
        :href="payload.email"
        @click="clickedLink('Clicked email share link')"
      >
        <div class="sidebar-item">
          <div class="d-flex align-items-center">
            <div class="icon"><i class="fas fa-square-envelope"></i></div>
            <div>&nbsp;&nbsp;Email</div>
          </div>
        </div>
      </a>
      <div
        class="share"
        target="_blank"
        rel="noopener"
        @click="copyToClipboard"
      >
        <div class="sidebar-item">
          <div class="d-flex align-items-center">
            <div class="icon position-relative">
              <i class="fa-solid fa-square" data-fa-transform=""></i>
              <div class="over position-absolute">
                <i class="fa-solid fa-link text-white" data-fa-transform=""></i>
              </div>
            </div>
            <div>&nbsp;&nbsp;{{ copyLinkText }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { log } from "@/utils/utils";

export default {
  name: "Share",
  components: {},
  data() {
    return {
      copyLinkText: "Copy Link",
    };
  },
  props: {},
  computed: {
    ...mapState({
      payload: (state) => state.app.all.leftBarPayload,
    }),
  },
  mounted() {},
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.payload.url);
        this.copyLinkText = "Link Copied!";
        this.clickedLink("Clicked copy share link");
      } catch {
        this.copyLinkText = "Unable to Copy Link!";
      }
    },
    async clickedLink(m, link, i) {
      log({ m: m, p: link, i: i || false });
    },
    ...mapActions("app", ["updateAppStore"]),
  },
  beforeMount() {},
};
</script>

<style scoped>
#share {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 30px;
  line-height: 30px;
}
.sidebar-content {
  padding: 30px 0;
}

.sidebar-item {
  padding: 15px 30px;
  width: 100%;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.btn-success {
  width: 100%;
  border-color: var(--mx-dark);
  background-color: var(--mx-dark);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 30px 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: calc(100% - 60px);
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
  text-align: start;
  width: calc(100% - 60px);
  margin: 0 30px;
}

.error.inner {
  margin: 0;
  width: 100%;
}

.whats-this {
  cursor: pointer;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
}

.close-btn {
  cursor: pointer;
}

.message {
  font-size: 20px;
  line-height: 28px;
  padding: 45px 30px 0;
}

form .message {
  padding: 45px 0 0;
}

.share {
  padding: 0 30px;
  width: 100%;
  text-decoration: none;
  color: var(--mx-dark);
  cursor: pointer;
  font-size: 24px;
}

.share img,
.share div.icon {
  height: 60px;
  max-width: 60px;
  font-size: 65px;
}

.over {
  top: 18px;
  left: 9px;
  font-size: 30px;
}
</style>
