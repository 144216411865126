<template>
  <div :class="{ 'dropup-center dropup': !inline, dropdown: inline }">
    <div
      :class="{ 'text-center': !inline }"
      :data-bs-toggle="!nativeShare && `dropdown`"
      aria-expanded="false"
      @click="
        () => {
          if (nativeShare) doShare();
        }
      "
    >
      <template v-if="inline">
        <div class="pointer">
          <i class="fa fa-arrow-up-from-square"></i> Share Listing
        </div>
      </template>
      <template v-else>
        <div><i class="fa fa-arrow-up-from-square"></i></div>
        <div>Share</div>
      </template>
    </div>
    <ul class="dropdown-menu mb-3">
      <li>
        <a
          target="_blank"
          :href="`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`"
          class="dropdown-item py-2"
        >
          <i class="fa-brands fa-facebook" /> Facebook
        </a>
      </li>
      <li>
        <a
          target="_blank"
          :href="`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`"
          class="dropdown-item py-2"
        >
          <i class="fa-brands fa-linkedin" /> LinkedIn
        </a>
      </li>
      <li>
        <a
          target="_blank"
          :href="`https://twitter.com/intent/tweet?url=${shareUrl}&text=`"
          class="dropdown-item py-2"
        >
          <i class="fa-brands fa-twitter" /> Twitter
        </a>
      </li>
      <li>
        <a
          target="_blank"
          :href="`https://pinterest.com/pin/create/button/?description=&media=&url=${shareUrl}`"
          class="dropdown-item py-2"
        >
          <i class="fa-brands fa-square-pinterest" /> Pinterest
        </a>
      </li>
      <li>
        <a
          :href="`mailto:?to=&subject=Check%20out%20this%20business%20on%20Moxey&body=${shareUrl}`"
          class="dropdown-item py-2"
        >
          <i class="fas fa-envelope" /> Email
        </a>
      </li>
      <li
        v-if="nativeCopy"
        class="dropdown-item py-2"
        @click="() => copyToClipboard()"
      >
        <i class="fas fa-link" /> {{ copyLinkText }}
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";

//let test = "hello";

export default {
  name: "ShareButton",
  components: {},
  data() {
    return {
      nativeShare: navigator.share && !this.desktop,
      nativeCopy: navigator.clipboard,
      copyLinkText: "Copy Link",
    };
  },
  props: {
    storefront: Object,
    inline: Boolean,
    desktop: Boolean,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    shareUrl() {
      return encodeURI(
        `${
          window.location.origin
        }/directory/storefronts/${this.storefront?.name.replace(
          /[^A-z]/g,
          "-"
        )}/${this.storefront.storefrontId}`
      );
    },
    ...mapState({}),
  },
  watch: {},
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.shareUrl);
        this.copyLinkText = "Link Copied";
      } catch {
        this.copyLinkText = "Unable to Copy Link";
      }
      setTimeout(() => {
        this.copyLinkText = "Copy Link";
      }, 2500);
    },

    async doShare() {
      await navigator.share({
        title: this.storefront?.name,
        url: this.shareUrl,
      });
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.controls {
  z-index: 80;
  padding: 10px 20px 20px 20px;
  background-color: var(--bs-light);
}

.pointer {
  cursor: pointer;
}
</style>
