<template>
  <div
    class="w-100 bottom-0 controls d-flex justify-content-between border-top d-lg-none"
  >
    <div class="text-center" style="width: 33.3%" @click="$emit('openaccount')">
      <div class="fs-2"><i class="fak fa-mx-sign"></i></div>
      <div>My Moxey</div>
    </div>

    <control-button
      class="text-center"
      style="width: 33.3%"
      :location="location"
      :search="search"
      :seed="seed"
      :sectors="sectors"
      :categories="categories"
      :title="title"
      routeTo="home"
    >
      <div class="fs-2"><i class="fas fa-house"></i></div>
      <div>Home</div>
    </control-button>

    <control-button
      v-if="location"
      class="text-center"
      style="width: 33.3%"
      :location="location"
      :search="search"
      :seed="seed"
      :sectors="sectors"
      :categories="categories"
      :title="title"
      routeTo="map"
    >
      <div class="fs-2"><i class="fas fa-map-location-dot"></i></div>
      <div>Map</div>
    </control-button>
    <div
      v-else
      class="text-center"
      style="width: 33.3%"
      @click="$emit('promptLocation')"
    >
      <div class="fs-2"><i class="fas fa-map-location-dot"></i></div>
      <div>Map</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ControlButton from "./ControlButton.vue";

//let test = "hello";

export default {
  name: "ControlBar",
  components: {
    ControlButton,
  },
  data() {
    return {};
  },
  props: {
    location: Object,
    search: String,
    sectors: String,
    categories: String,
    seed: Number,
    title: String,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    getRoute(type) {
      const params = {
        search: this.search,
        sectors: this.sectors,
        categories: this.categories,
        title: this.title,
      };

      for (const k in params) {
        if (!params[k]) delete params[k];
      }

      const queryString = new URLSearchParams(params).toString();

      const route = [
        "/directory",
        type || "home",
        this.location?.city,
        this.location?.geo.lat,
        this.location?.geo.lon,
        this?.seed,
        queryString && `?${queryString}`,
      ]
        .filter((s) => s)
        .join("/");

      console.log(route);

      return route;
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.controls {
  z-index: 80;
  padding: 10px 20px 20px 20px;
  background-color: var(--bs-light);
}
</style>
