<template>
  <div>
    <div v-if="!wallet || wallet.length === 0">
      <p class="text-center text-muted"><em> No saved payment cards </em></p>
    </div>
    <div
      v-else
      v-for="(w, i) in wallet"
      :key="i"
      class="wallet-item"
      :class="{
        'is-invalid': w.expired,
        selected: selectedWalletItem === w.customerVaultId,
      }"
      @click="
        () => {
          if (!w.expired && !processing) {
            selectedWalletItem = w.customerVaultId;
            selectedCardType = w.cardType;
            selectedCardTypeNice = w.cardTypeNice;
            $emit('selectedCard', {
              card: w,
              selectedWalletItem,
              selectedCardType,
              selectedCardTypeNice,
            });
          }
        }
      "
    >
      <div class="d-flex justify-content-between align-items-center">
        <div v-if="!noSelect" :class="wallet - select">
          <input
            class="form-check-input"
            type="radio"
            :name="w.customerVaultId"
            :id="w.customerVaultId"
            :value="w.customerVaultId"
            :disabled="w.expired || processing"
            v-model="selectedWalletItem"
          />
        </div>
        <div>
          <CCWidget :cardType="w.cardType" class="card-widget" />
        </div>
        <div>
          {{ w.cardTypeNice }}
          ending in
          {{ [...w.ccNumber].reverse().slice(0, 4).reverse().join("") }}
        </div>

        <div>
          <button
            class="btn btn-danger"
            @click.stop="deletePaymentMethod(w.customerVaultId)"
            :disabled="processing || deletingPaymentMethod"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
      <div
        v-if="w.autoReloadAmount && isEnrolled"
        class="wallet-warn fw-lighter mt-3"
      >
        <small>
          This payment method is enrolled in Cash Reserve Auto-Reload. Once your
          Cash Reserve balance goes below ${{ w.autoReloadTrigger }} this card
          will be charged ${{ w.autoReloadAmount }}.
          <span class="mx-alert-link" @click="deAuthCard"
            ><i class="far fa-times" /> Unenroll
          </span>
        </small>
      </div>
      <div v-else class="text-center fw-lighter mt-3">
        <small>
          <span
            class="mx-alert-link"
            @click="updateAppStore({ leftBarComp: 'AutoLoad', forwardCard: w })"
            >Enroll in Cash Reserve Auto-Reload.
          </span>
        </small>
      </div>
      <div class="text-danger wallet-warn" v-show="w.expired">
        This payment method is expired.
      </div>
      <div class="text-danger wallet-warn" v-show="w.declined">
        This payment method was previously declined.
      </div>
    </div>
  </div>
  <div class="mt-4 text-center">
    <mx-button
      btnClass="btn btn-success"
      @click="
        updateAppStore({
          leftBarComp: 'AddCard',
        })
      "
      :height="38"
      :padX="30"
      ><i class="fas fa-circle-plus" /> Add New Payment Card
    </mx-button>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { api } from "@/utils/utils";
import { mapActions, mapState } from "vuex";
import CCWidget from "../CashApp/Shared/CCWidget.vue";
import MxButton from "../Shared/MxButton.vue";
import currency from "currency.js";

//let test = "hello";

export default {
  name: "Wallet",
  components: {
    CCWidget,
    MxButton,
  },

  data() {
    return {
      selectedWalletItem: undefined,
      selectedCardType: undefined,
      selectedCardTypeNice: undefined,
    };
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    noSelect: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({
      user: (state) => state.app.all.user,
    }),
    wallet() {
      const w =
        this.user &&
        Object.keys(this.user.wallet).reduce((col, k) => {
          if (this.user.wallet[k]) {
            const val = JSON.parse(JSON.stringify(this.user.wallet[k]));

            val.expired =
              parseInt(
                `${val.ccExp.substring(2, 4)}${val.ccExp.substring(0, 2)}`
              ) <
              parseInt(
                new Date()
                  .toISOString()
                  .split("T")[0]
                  .replace(/-/g, "")
                  .slice(2, 6)
              );
            val.autoReloadAmount =
              val.autoReloadAmount && currency(val.autoReloadAmount).value;
            val.autoReloadTrigger =
              val.autoReloadTrigger && currency(val.autoReloadTrigger).value;

            col.push(val);
          }
          return col;
        }, []);

      return w;
    },
    isEnrolled() {
      for (const w of this.wallet) {
        if (w.autoReloadAmount > 0) return true;
      }
      return false;
    },
  },
  watch: {},
  methods: {
    async deletePaymentMethod(id) {
      this.deletingPaymentMethod = true;
      this.processingError = undefined;

      try {
        await api("post", "banking", `/vault/delete/`, {
          body: {
            vaultId: id,
          },
        });

        const tempUser = JSON.parse(JSON.stringify(this.user));
        tempUser.wallet[id] = undefined;
        this.updateAppStore({ user: tempUser });

        if (this.wallet.length === 0) {
          this.$emit("resetPaymentType");
        }

        if (id === this.selectedWalletItem) {
          this.selectedWalletItem = undefined;
          this.selectedCardType = undefined;
          this.selectedCardTypeNice = undefined;
          this.$emit("selectedCard", {
            selectedWalletItem: undefined,
            selectedCardType: undefined,
            selectedCardTypeNice: undefined,
          });
        }
      } catch (e) {
        if (e.error) {
          this.processingError = e.error;
        } else {
          this.processingError =
            "An error occurred. Unable to delete payment method. Please try again later.";
          throw e;
        }
      } finally {
        this.deletingPaymentMethod = false;
      }
    },
    async deAuthCard() {
      console.log("e");
      const tempWallet = JSON.parse(JSON.stringify(this.user.wallet));

      let customerVaultId;

      for (const w in tempWallet) {
        if (tempWallet[w].autoReloadAmount)
          customerVaultId = tempWallet[w].customerVaultId;
        tempWallet[w].autoReloadAmount = undefined;
      }

      try {
        await api("post", "banking", `/v2/autoReloadSignup/`, {
          body: {
            customerVaultId,
          },
        });

        this.updateAppStore({
          user: { ...this.user, wallet: tempWallet },
        });
      } catch (e) {
        this.error = e.message || e;
      }
    },

    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
.card-widget {
  height: 36px;
  font-size: 36px;
  width: 50px;
}

.form-check-input {
  padding: 12px;
  height: 24px;
  width: 24px;
  margin: 0;
}

.wallet-item {
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin: 15px 0;
  cursor: pointer;
}

.wallet-item.is-invalid {
  background-color: #f8d7da;
  cursor: default;
}

.wallet-item:not(.is-invalid):hover,
.wallet-item.selected {
  background-color: #00603c42;
}

.wallet-item-inner > div {
  line-height: 36px;
}

.wallet-select {
}

.wallet-warn {
  margin: 10px 0 0 0;
}

.mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted black;
}
</style>
