<template>
  <modal
    :showModal="selectedStorefront"
    :modalOptions="{
      showHeader: false,
      size: 'modal-xl',
      source: 'zip modal',
    }"
    :additionalModalClasses="[{ 'modal-fullscreen-lg-down': true }]"
    :modalBSOptions="{}"
    :externalId="externalId"
    @modalClosed="() => {}"
  >
    <template v-slot
      ><storefront-content
        :desktop="desktop"
        :storefront="selectedStorefront"
        :holdLoad="holdLoad"
        :isModal="true"
        @closemodal="
          () => {
            $emit(`closemodal`);
          }
        "
        @selectstorefront="
          (s) => {
            scrollTop();
            $emit('selectstorefront', s);
          }
        "
      />
    </template>
  </modal>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import StorefrontContent from "./StorefrontContent.vue";
import Modal from "../../../components/Modal/Modal.vue";
import shortUUID from "short-uuid";
//let test = "hello";

export default {
  name: "Member",
  components: {
    StorefrontContent,
    Modal,
  },
  data() {
    return {
      backToUrl: this.$router.options.history.state.back,
      externalId: shortUUID.generate(),
      holdLoad: true,
      modal: undefined,
    };
  },
  props: {
    selectedStorefront: Object,
    user: Object,
    isEstablished: Boolean,
    desktop: Boolean,
  },
  async mounted() {
    this.modal = document.getElementById(this.externalId);

    this.modal.addEventListener("show.bs.modal", () => {
      this.releaseHold(this.modal);
    });

    this.modal.addEventListener("hidden.bs.modal", () => {
      this.$emit("closemodal");
      this.holdLoad = true;
    });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {},
  methods: {
    scrollTop() {
      this.modal.scrollTop = 0;
    },
    releaseHold(modal) {
      setTimeout(() => {
        if (modal.getBoundingClientRect().width) {
          this.holdLoad = false;
        } else {
          this.releaseHold(modal);
        }
      }, 20);
    },

    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  async beforeMount() {},
};
</script>

<style scoped></style>
