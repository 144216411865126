<template>
  <div id="login">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="header-like">Welcome!</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div v-if="step === 1">
        <div class="message">
          We can't wait for you to get started with Moxey! First we need to
          activate your account.
        </div>
        <div class="message">
          Please enter the username you were provided when you joined (Hint:
          it's probably your email).
        </div>
        <form @submit.prevent="doRequest" autocomplete="off">
          <input
            type="text"
            name="username"
            class="form-control"
            v-model="username"
            placeholder="Username"
            :disabled="reseting"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="username"
          />
          <div class="button-holder text-center">
            <button
              v-if="!reseting"
              type="submit"
              class="btn btn-success"
              :disabled="username === ''"
            >
              Register Account
            </button>
            <button v-else type="button" class="btn btn-success" disabled>
              <i class="fas fa-sync fa-spin"></i> Registering Account
            </button>
          </div>
        </form>
      </div>

      <div v-if="step === 2">
        <div class="message">
          A registration confirmation code was sent to the email address
          associated with your user account. Enter the code here along with a
          new, strong password.
        </div>
        <form @submit.prevent="doValidate">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': invalidFields.code }"
            name="code"
            v-model="code"
            placeholder="Confirmation Code"
            :disabled="reseting"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="off"
            @focus="invalidFields.code = undefined"
          />
          <div v-if="invalidFields.code" class="error inner text-danger">
            <small>{{ invalidFields.code }}</small>
          </div>
          <input
            type="text"
            class="form-control password-like"
            :class="{ 'is-invalid': invalidFields.password }"
            v-model="password"
            placeholder="New Password"
            :disabled="reseting"
            autocomplete="new-password"
            @focus="invalidFields.password = undefined"
          />
          <div v-if="invalidFields.password" class="error inner text-danger">
            <small>{{ invalidFields.password }}</small>
          </div>
          <div class="button-holder text-center">
            <button
              v-if="!reseting"
              type="submit"
              class="btn btn-success"
              :disabled="code === '' || password === ''"
            >
              Complete Registration
            </button>
            <button v-else type="button" class="btn btn-success" disabled>
              <i class="fas fa-sync fa-spin"></i> Registering Account
            </button>
          </div>
        </form>
      </div>
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="reseting"
        @click="
          () => {
            if (!reseting) $emit('clickedShortcut', 'Account');
          }
        "
      >
        Cancel
      </button>
      <div v-if="loginError" class="error text-danger">
        {{ loginError }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { api } from "@/utils/utils";

export default {
  name: "Register",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      confirm: "",
      code: "",
      loginError: undefined,
      reseting: false,
      step: 1,
      invalidFields: {},
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loginMessage: (state) => state.app.all.loginMessage,
    }),
  },
  mounted() {},
  methods: {
    cancelLogin() {
      this.step = 1;
      this.$emit("cancelLogin");
    },
    async doRequest() {
      this.loginError = undefined;
      this.invalidFields = {};

      this.reseting = true;

      try {
        await api("post", "core", `/forgot`, {
          body: {
            username: this.username,
            register: true,
          },
        });

        this.step = 2;
      } catch (e) {
        console.log(e);
        //Handle errors from proxy
        this.loginError = e.error || e;
      } finally {
        this.reseting = false;
      }
    },
    doValidate() {
      this.invalidFields = {};

      if (this.code.length !== 8)
        this.invalidFields.code = "Invalid confirmation code.";
      if (this.password.length < 6)
        this.invalidFields.password =
          "Password must be at least 6 characters long.";

      if (Object.keys(this.invalidFields).length === 0) {
        this.doReset();
      }
    },
    async doReset() {
      this.loginError = undefined;
      this.invalidFields = {};

      this.reseting = true;

      try {
        await api("post", "core", `/reset`, {
          body: {
            username: this.username,
            code: this.code,
            password: this.password,
          },
        });
        this.updateAppStore({
          loginMessage: "Registration Complete. Please log in.",
        });
        this.$emit("clickedShortcut", "Login");
      } catch (e) {
        console.log(e);
        //Handle errors from proxy

        if (e.error === "Invalid confirmation code.") {
          this.invalidFields.code = "Invalid confirmation code.";
        }

        this.loginError = e.error || e;
      } finally {
        this.reseting = false;
      }
    },
    ...mapActions("app", ["updateAppStore"]),
  },
  beforeMount() {},
};
</script>

<style scoped>
#login {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 30px;
  line-height: 30px;
}
.sidebar-content {
  padding: 30px 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.btn-success {
  margin: 35px 0 15px;
  border-color: var(--mx-dark);
  background-color: var(--mx-dark);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 30px 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: calc(100% - 60px);
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
  text-align: start;
  width: calc(100% - 60px);
  margin: 0 30px;
}

.error.inner {
  margin: 0;
  width: 100%;
}

.whats-this {
  cursor: pointer;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.message {
  font-size: 20px;
  line-height: 28px;
  padding: 45px 30px 0;
}

form .message {
  padding: 45px 0 0;
}
</style>
